import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
// import {useNavigate} from 'react-router-dom';
// import StarRating from 'react-svg-star-rating';
// import {numberWithCommas, readableDateString} from '../../_helpers';
import {AddTag} from '../../_components';

// import logoImg from '../../_assets/images/LTHLogo.png';
import {ReactComponent as DeleteIcon} from '../../_assets/icons/deleteBucket.svg';
import {ReactComponent as EditIcon} from '../../_assets/icons/editPen.svg';
import Dialog from '../../_components/Modal/Group/Dialog';
import {tagActions} from '../../_actions';

const TagRow = ({data, _id, name, description, comments, price}) => {
   const dispatch = useDispatch();
   // const navigate = useNavigate();
   // const [starRating, setStarRating] = useState(stars);
   // const [deleteItem, setDeleteItem] = useState(false);
   const [deleteTag, setDeleteTag] = useState(false);
   const [tagData, setTagData] = useState({});

   const toggleDelete = (row) => {
      setTagData(row);
      setDeleteTag(!deleteTag);
   };

   const handleDelete = () => {
      const _id = tagData?._id;

      if (_id) {
         dispatch(tagActions.deleteTag(_id));
      }
      setDeleteTag(false);
   };

   // const handleStarClick = (rating) => {
   //    setStarRating(rating);
   // };
   // const handleViewMeal = (item) => {
   //    navigate(`/Tag/${item.name}`, {state: {mealDetails: item}});
   // };

   const [tags, setTags] = useState(false);
   const [editTag, setEditTag] = useState(null);

   const handleEditCategory = (item) => {
      setTags(true);
      setEditTag(data);
   };

   return (
      <tr key={`recentOrderList-${_id}`} className="d-table-row">
         <th scope="col" className="d-flex align-items-center pe-0">
            <p className="mealName">{name}</p>
         </th>
         <td className="text-nowrap align-middle">
            <div className="d-flex align-items-center">
               <DeleteIcon
                  onClick={() => toggleDelete(data)}
                  className="me-3"
                  style={{transform: 'scale(.7)', cursor: 'pointer'}}
               />
               <Dialog modalOpen={deleteTag} title="Tag" handleDelete={handleDelete} setModalOpen={setDeleteTag} />
               <EditIcon
                  onClick={() => handleEditCategory(data)}
                  className="ms-3"
                  style={{transform: 'scale(.7)', cursor: 'pointer'}}
               />
            </div>
            <AddTag modalOpen={tags} type="edit" setModalOpen={setTags} tagDetails={editTag} />
         </td>
      </tr>
   );
};

export default TagRow;
