import {call, put, takeLatest, all} from 'redux-saga/effects';
import {dashboardConstants, userConstants} from '../../_constants';
import {getObjectFromStorage, checkStatus, clearObjectFromStorage, createRequestWithToken} from '../../_helpers';

function* getDashboard() {
   yield put({type: dashboardConstants.REQUEST_DASHBOARD_METRICS});
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);
      const dashboardUri = `${dashboardConstants.GET_DASHBOARD_METRICS_URI}`;
      const req = createRequestWithToken(dashboardUri, {method: 'GET'})(user.accessToken);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);

      if (response.status === 401) {
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: dashboardConstants.REQUEST_DASHBOARD_METRICS_SUCCESS,
         dashboard: jsonResponse.data,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({type: dashboardConstants.REQUEST_DASHBOARD_METRICS_ERROR});
   }
}

function* getDashboardWatcher() {
   yield takeLatest(dashboardConstants.GET_DASHBOARD_METRICS, getDashboard);
}

export default function* rootSaga() {
   yield all([getDashboardWatcher()]);
}
