import {appConstants} from './app.constant';

const login = 'auth/signin';
const verify = 'auth/confirm_email';
const user = 'user';
const admin = 'user/admin';
const userPassword = 'reset_admin_user_password';
const password = 'change_password';

export const userConstants = {
   LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
   LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
   LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

   LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
   LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
   LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',

   GET_REQUEST: 'USER_GET_REQUEST',
   GET_SUCCESS: 'USER_GET_SUCCESS',
   GET_FAILURE: 'USER_GET_FAILURE',

   REQUEST_VERIFY_EMAIL: 'REQUEST_VERIFY_EMAIL',
   VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
   VERIFY_EMAIL_ERROR: 'VERIFY_EMAIL_ERROR',

   REQUEST_SET_PASSWORD: 'REQUEST_SET_PASSWORD',
   SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
   SET_PASSWORD_ERROR: 'SET_PASSWORD_ERROR',

   REQUEST_RESEND_VERIFICATION_LINK: 'REQUEST_RESEND_VERIFICATION_LINK',
   RESEND_VERIFICATION_LINK_SUCCESS: 'RESEND_VERIFICATION_LINK_SUCCESS',
   RESEND_VERIFICATION_LINK_ERROR: 'RESEND_VERIFICATION_LINK_ERROR',

   REQUEST_CHANGE_PASSWORD: 'REQUEST_CHANGE_PASSWORD',
   CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
   CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

   REQUEST_ALL_USERS: 'REQUEST_ALL_USERS',
   REQUEST_ALL_USERS_SUCCESS: 'REQUEST_ALL_USERS_SUCCESS',
   REQUEST_ALL_USERS_ERROR: 'REQUEST_ALL_USERS_ERROR',

   REQUEST_EXPORT_ALL_USERS: 'REQUEST_EXPORT_ALL_USERS',
   REQUEST_EXPORT_ALL_USERS_SUCCESS: 'REQUEST_EXPORT_ALL_USERS_SUCCESS',
   REQUEST_EXPORT_ALL_USERS_ERROR: 'REQUEST_EXPORT_ALL_USERS_ERROR',

   REQUEST_USERS: 'REQUEST_USERS',
   REQUEST_USERS_SUCCESS: 'REQUEST_USERS_SUCCESS',
   REQUEST_USERS_ERROR: 'REQUEST_USERS_ERROR',

   REQUEST_REGISTER_USER: 'REQUEST_REGISTER_USER',
   REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
   REGISTER_USER_ERROR: 'REGISTER_USER_ERROR',

   REQUEST_UPDATE_USER: 'REQUEST_UPDATE_USER',
   UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
   UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',

   REQUEST_UPDATE_USER_STATUS: 'REQUEST_UPDATE_USER_STATUS',
   UPDATE_USER_STATUS_SUCCESS: 'UPDATE_USER_STATUS_SUCCESS',
   UPDATE_USER_STATUS_ERROR: 'UPDATE_USER_STATUS_ERROR',

   REQUEST_DELETE_USER: 'REQUEST_DELETE_USER',
   DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
   DELETE_USER_ERROR: 'DELETE_USER_ERROR',

   REQUEST_SEARCH_USER: 'REQUEST_SEARCH_USER',
   SEARCH_USER_SUCCESS: 'SEARCH_USER_SUCCESS',
   SEARCH_USER_ERROR: 'SEARCH_USER_ERROR',
   SEARCH_USER_SUCCESS_WITHOUT_DATA: 'SEARCH_USER_SUCCESS_WITHOUT_DATA',

   GET_USER: 'GET_USER',
   LOGIN: 'LOGIN',
   LOGOUT: 'LOGOUT',

   GET_USERS: 'GET_USERS',
   GET_ALL_USERS: 'GET_ALL__USERS',
   FETCH_ALL_USERS: 'FETCH_ALL_USERS',

   CHANGE_PASSWORD: 'CHANGE_PASSWORD',
   REGISTER_USER: 'REGISTER_USER',
   VERIFY_EMAIL: 'VERIFY_EMAIL',

   RESEND_VERIFICATION_LINK: 'RESEND_VERIFICATION_LINK',
   SET_PASSWORD: 'SET_PASSWORD',
   UPDATE_USER: 'UPDATE_USER',

   UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',
   DELETE_USER: 'DELETE_USER',
   EXPORT_USERS: 'EXPORT_USERS',

   SEARCH_USER: 'SEARCH_USER',
   ALL_USER_KEY: 'all_user',

   ADMIN_USER_KEY: 'admin_user',
   USER_ROLE_ID: 3,
   ADMIN: admin,
   TOKEN_HAS_EXPIRED: 'TOKEN_HAS_EXPIRED',

   REGISTER_URI: `${appConstants.BASE_URI}${admin}`,
   VERIFY_EMAIL_URI: `${appConstants.BASE_URI}${verify}/`,
   LOGIN_URI: `${appConstants.BASE_URI}${login}`,
   GET_USER_URI: `${appConstants.BASE_URI}adminusers`,
   USER_URI: `${appConstants.BASE_URI}${user}`,
   DELETE_USER_URI: `${appConstants.BASE_URI}${admin}`,
   SET_USER_PASSWORD_URI: `${appConstants.BASE_URI}${userPassword}`,
   CHANGE_PASSWORD_URI: `${appConstants.BASE_URI}${password}`,
};
