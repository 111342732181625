import {blogConstants} from '../_constants';

const getAllBlogs = (model) => ({
   type: blogConstants.GET_ALL_BLOGS,
   data: model,
});

const getABlog = (model) => ({
   type: blogConstants.GET_A_BLOG,
   data: model,
});

const searchBlog = (model) => ({
   type: blogConstants.SEARCH_BLOG,
   data: model,
});

const createBlog = (model) => ({
   type: blogConstants.CREATE_BLOG,
   data: model,
});

const updateBlog = (model) => ({
   type: blogConstants.UPDATE_BLOG,
   data: model,
});

const deleteBlog = (_id) => ({
   type: blogConstants.DELETE_BLOG,
   _id,
});

const deleteBlogComment = (model) => ({
   type: blogConstants.DELETE_BLOG_COMMENT,
   data: model,
});

export const blogActions = {
   getAllBlogs,
   getABlog,
   searchBlog,
   createBlog,
   updateBlog,
   deleteBlog,
   deleteBlogComment,
};
