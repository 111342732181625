import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import Formsy from 'formsy-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AuthWrapper, TextInput} from '../../_components';
import {capitalizeFirstLetter} from '../../_helpers';
import {FiEye, FiEyeOff} from 'react-icons/fi';
import './index.scss';

import {ReactComponent as NutsIcon} from '../../_assets/icons/nuts.svg';
import {userActions} from '../../_actions';

const Login = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const {IsRequestingLogin, loginSuccess, IsRequestingLogout, errorMessage} = useSelector((s) => s.auth);
   const {isRequestingUser, userDetails} = useSelector((s) => s.user);

   const [canSubmit, setCanSubmit] = useState(false);
   const [showPass, setShowPass] = useState();

   const handleSubmit = (data) => {
      dispatch(userActions.login(data));
   };

   if (isRequestingUser) {
      return null;
   }

   if (IsRequestingLogout) {
      return null;
   }

   if (userDetails) {
      if (loginSuccess) {
         navigate('/dashboard');
      } else if (window.history?.state?.idx > 0) {
         navigate(-1);
      } else {
         navigate('/dashboard');
      }
   }

   return (
      <AuthWrapper>
         <Formsy
            className="position-relative p-md-5 p-3"
            onValidSubmit={handleSubmit}
            onValid={() => setCanSubmit(true)}
            onInvalid={() => setCanSubmit(false)}>
            <NutsIcon className="nutIcon position-absolute" />
            <p className="signIn py-2">Sign In</p>
            {errorMessage !== null && (
               <div className="server-error mb-2">
                  <p className="">{capitalizeFirstLetter(errorMessage?.message)}!</p>
               </div>
            )}
            <p className="small pb-4">Please input your details below.</p>
            <TextInput
               type="text"
               id="email"
               name="email"
               label="Email Address"
               className="mb-3"
               inputClassName="dateTime"
               placeholder="toyinanifowoshe@gmail.com"
               value=""
            />
            <TextInput
               type={showPass ? 'text' : 'password'}
               id="password"
               name="password"
               label="Password"
               className="mb-3 position-relative"
               inputClassName="dateTime"
               placeholder="********"
               value=""
               rightIcon={
                  <div onClick={() => setShowPass((t) => !t)} className="__icon position-absolute">
                     {showPass ? <FiEye /> : <FiEyeOff />}
                  </div>
               }
            />
            <p className="forgot my-3">
               <Link to="/forgotPassword">Forgot Password?</Link>
            </p>
            <button type="submit" className="sign mt-4 w-100" disabled={!canSubmit}>
               {IsRequestingLogin ? <CircularProgress color="inherit" thickness={2} /> : 'Sign In'}
            </button>
         </Formsy>
      </AuthWrapper>
   );
};

export default Login;
