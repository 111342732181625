import {appConstants} from './app.constant';

const menu = 'meal';

export const menuConstants = {
   REQUEST_CREATE_MENU: 'REQUEST_CREATE_MENU',
   CREATE_MENU_SUCCESS: 'CREATE_MENU_SUCCESS',
   CREATE_MENU_ERROR: 'CREATE_MENU_ERROR',

   REQUEST_ALL_MENUS: 'REQUEST_ALL_MENUS',
   REQUEST_ALL_MENUS_SUCCESS: 'REQUEST_ALL_MENUS_SUCCESS',
   REQUEST_ALL_MENUS_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_MENUS_SUCCESS_WITHOUT_DATA',
   REQUEST_ALL_MENUS_ERROR: 'REQUEST_ALL_MENUS_ERROR',

   REQUEST_ALL_MENUS_WITHOUT_PAGINATION: 'REQUEST_ALL_MENUS_WITHOUT_PAGINATION',
   REQUEST_ALL_MENUS_WITHOUT_PAGINATION_SUCCESS: 'REQUEST_ALL_MENUS_WITHOUT_PAGINATION_SUCCESS',
   REQUEST_ALL_MENUS_WITHOUT_PAGINATION_ERROR: 'REQUEST_ALL_MENUS_WITHOUT_PAGINATION_ERROR',

   REQUEST_CATEGORY_MENUS: 'REQUEST_CATEGORY_MENUS',
   REQUEST_CATEGORY_MENUS_SUCCESS: 'REQUEST_CATEGORY_MENUS_SUCCESS',
   REQUEST_CATEGORY_MENUS_SUCCESS_WITHOUT_DATA: 'REQUEST_CATEGORY_MENUS_SUCCESS_WITHOUT_DATA',
   REQUEST_CATEGORY_MENUS_ERROR: 'REQUEST_CATEGORY_MENUS_ERROR',

   REQUEST_A_MENU: 'REQUEST_A_MENU',
   REQUEST_A_MENU_SUCCESS: 'REQUEST_A_MENU_SUCCESS',
   REQUEST_A_MENU_ERROR: 'REQUEST_A_MENU_ERROR',

   REQUEST_SEARCH_MENU: 'REQUEST_SEARCH_MENU',
   SEARCH_MENU_SUCCESS: 'SEARCH_MENU_SUCCESS',
   SEARCH_MENU_ERROR: 'SEARCH_MENU_ERROR',
   SEARCH_MENU_SUCCESS_WITHOUT_DATA: 'SEARCH_MENU_SUCCESS_WITHOUT_DATA',

   REQUEST_DELETE_MENU: 'REQUEST_DELETE_MENU',
   DELETE_MENU_SUCCESS: 'DELETE_MENU_SUCCESS',
   DELETE_MENU_ERROR: 'DELETE_MENU_ERROR',

   REQUEST_UPDATE_MENU: 'REQUEST_UPDATE_MENU',
   UPDATE_MENU_SUCCESS: 'UPDATE_MENU_SUCCESS',
   UPDATE_MENU_ERROR: 'UPDATE_MENU_ERROR',

   REQUEST_DELETE_MENU_COMMENT: 'REQUEST_DELETE_MENU_COMMENT',
   DELETE_MENU_COMMENT_SUCCESS: 'DELETE_MENU_COMMENT_SUCCESS',
   DELETE_MENU_COMMENT_ERROR: 'DELETE_MENU_COMMENT_ERROR',

   GET_ALL_MENUS: 'GET_ALL_MENUS',
   GET_ALL_MENUS_WITHOUT_PAGINATION: 'GET_ALL_MENUS_WITHOUT_PAGINATION',
   GET_CATEGORY_MENUS: 'GET_CATEGORY_MENUS',
   CREATE_MENU: 'CREATE_MENU',
   SEARCH_MENU: 'SEARCH_MENU',

   DELETE_MENU: 'DELETE_MENU',
   UPDATE_MENU: 'UPDATE_MENU',
   GET_A_MENU: 'GET_A_MENU',
   DELETE_MENU_COMMENT: 'DELETE_MENU_COMMENT',

   MENU_URI: `${appConstants.BASE_URI}${menu}`,
};
