import React from 'react';
import FullscreenModal from '../..';
import './index.scss';

import {ReactComponent as CloseIcon} from '../../../../_assets/icons/close.svg';

const Dialog = ({modalOpen, setModalOpen, title, handleDelete}) => {
   return (
      <FullscreenModal open={modalOpen} onClickAway={() => setModalOpen(false)}>
         <div className="addMenu dialog px-md-5 px-3 py-md-4 py-3 position-relative">
            <div className="position-absolute" style={{top: '1rem', right: '1.5rem'}}>
               <CloseIcon onClick={() => setModalOpen(false)} style={{transform: 'scale(.7)', cursor: 'pointer'}} />
            </div>
            <div className="deleteDialog">
               <h3 className="">Delete {title}?</h3>
               <div className="mt-4 d-flex align-items-center justify-content-between">
                  <button onClick={() => handleDelete()} className="delete me-5">
                     Delete
                  </button>
                  <button onClick={() => setModalOpen(false)} className="cancel">
                     Cancel
                  </button>
               </div>
            </div>
         </div>
      </FullscreenModal>
   );
};

export default Dialog;
