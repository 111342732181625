import {categoryConstants} from '../_constants';

const getAllCategories = (model) => ({
   type: categoryConstants.GET_ALL_CATEGORIES,
   data: model,
});

const searchCategory = (model) => ({
   type: categoryConstants.SEARCH_CATEGORY,
   data: model,
});

const createCategory = (model) => ({
   type: categoryConstants.CREATE_CATEGORY,
   data: model,
});

const updateCategory = (model) => ({
   type: categoryConstants.UPDATE_CATEGORY,
   data: model,
});

const deleteCategory = (_id) => ({
   type: categoryConstants.DELETE_CATEGORY,
   _id,
});

export const categoryActions = {
   getAllCategories,
   searchCategory,
   createCategory,
   updateCategory,
   deleteCategory,
};
