import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import PaymentRow from './PaymentRow';
import Formsy from 'formsy-react';
import Loader from '../../_helpers/loader';
import {Pagination, TextInput} from '../../_components';
import './index.scss';

import {ReactComponent as SearchIcon} from '../../_assets/icons/search.svg';
import {paymentActions} from '../../_actions';

const Payments = () => {
   const dispatch = useDispatch();

   const {IsRequestingPayments, paymentsList, pagination} = useSelector((s) => s.payment);

   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   useEffect(() => {
      dispatch(paymentActions.getAllPayments());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(paymentActions.getAllPayments(model));
   };

   const searchPayment = (data) => {
      if (data.searchInput === '') {
         dispatch(paymentActions.getAllReservations());
      } else {
         dispatch(paymentActions.searchPayment(data));
      }
   };

   return (
      <div className="p-4 payments">
         <div className="menu">
            <div className="search p-4">
               <Formsy onValidSubmit={searchPayment}>
                  <TextInput
                     id="search"
                     type="text"
                     name="searchInput"
                     placeholder="Search"
                     className=""
                     leftIcon={
                        <div className="icon__Wrapper position-absolute">
                           <SearchIcon className="w-100 h-100" />
                        </div>
                     }
                  />
               </Formsy>
            </div>
            {IsRequestingPayments ? (
               <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                  <div />
               </Loader>
            ) : (
               <MDBTable responsive>
                  <MDBTableHead>
                     <tr>
                        {/* <th>Meal</th> */}
                        <th>Amount</th>
                        <th className="text-nowrap">Payment Reference</th>
                        <th className="text-nowrap">Payment ID</th>
                        <th className="text-nowrap">Payment Date</th>
                        {/* <th>Status</th>
                     <th></th> */}
                     </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                     {paymentsList?.length > 0 ? (
                        paymentsList.map((item, i) => {
                           return <PaymentRow key={i} {...item} data={item} />;
                        })
                     ) : (
                        <td align="center" className="no-content" colSpan={5}>
                           No payment has been made on this platform
                        </td>
                     )}
                  </MDBTableBody>
               </MDBTable>
            )}
         </div>
         {!IsRequestingPayments && totalPages > 1 && (
            <Pagination
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </div>
   );
};

export default Payments;
