import React from 'react';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import CommentRow from './CommentRow';
import {menuComments} from '../../_constants';

const MenuComments = ({comments, mealId}) => {
   return (
      <div className="col-md-8 col-12 py-3">
         <div className="comments overflow-hidden pb-5" style={{height: '781.906px'}}>
            <h5 className="p-4">Comments</h5>
            <div className="tableBody h-100 overflow-y-scroll pb-5">
               <MDBTable responsive>
                  <MDBTableHead>
                     <tr>
                        <th>Customer</th>
                        <th>Comments</th>
                        <th>Ratings</th>
                        <th></th>
                     </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                     {comments.map((item) => (
                        <CommentRow data={item} mealId={mealId} {...item} />
                     ))}
                  </MDBTableBody>
               </MDBTable>
            </div>
         </div>
      </div>
   );
};

export default MenuComments;
