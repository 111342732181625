import {appConstants, userConstants} from '../../_constants';
import {combineReducers} from 'redux';
import {updateObject} from '../../_helpers';

const IsRequestingLogin = (state = false, action) => {
   switch (action.type) {
      case userConstants.LOGIN_REQUEST:
         return true;
      case userConstants.LOGIN_FAILURE:
         return false;
      case userConstants.LOGIN_SUCCESS:
         return false;
      default:
         return state;
   }
};

const loginSuccess = (state = false, action) => {
   switch (action.type) {
      case userConstants.LOGIN_REQUEST:
         return false;
      case userConstants.LOGIN_FAILURE:
         return false;
      case userConstants.LOGIN_SUCCESS:
         return true;
      default:
         return state;
   }
};

const IsRequestingLogout = (state = false, action) => {
   switch (action.type) {
      case userConstants.LOGOUT_REQUEST:
         return true;
      case userConstants.LOGOUT_FAILURE:
         return false;
      case userConstants.LOGOUT_SUCCESS:
         return false;
      default:
         return state;
   }
};

const tokenHasExpired = (state = false, action) => {
   switch (action.type) {
      case userConstants.TOKEN_HAS_EXPIRED:
         return true;
      case userConstants.LOGIN_SUCCESS:
         return false;
      case userConstants.LOGOUT_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsSettingPassword = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_SET_PASSWORD:
         return true;
      case userConstants.SET_PASSWORD_SUCCESS:
         return false;
      case userConstants.SET_PASSWORD_ERROR:
         return false;
      default:
         return state;
   }
};

const IsChangingPassword = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_CHANGE_PASSWORD:
         return true;
      case userConstants.CHANGE_PASSWORD_SUCCESS:
         return false;
      case userConstants.CHANGE_PASSWORD_ERROR:
         return false;
      default:
         return state;
   }
};

const IsVerifyingEmail = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_VERIFY_EMAIL:
         return true;
      case userConstants.VERIFY_EMAIL_SUCCESS:
         return false;
      case userConstants.VERIFY_EMAIL_ERROR:
         return false;
      default:
         return state;
   }
};

const IsResendingVerificationLink = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_RESEND_VERIFICATION_LINK:
         return true;
      case userConstants.RESEND_VERIFICATION_LINK_ERROR:
         return false;
      case userConstants.RESEND_VERIFICATION_LINK_SUCCESS:
         return false;
      default:
         return state;
   }
};

const emailVerificationSuccessful = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_VERIFY_EMAIL:
         return false;
      case userConstants.VERIFY_EMAIL_SUCCESS:
         return true;
      case userConstants.VERIFY_EMAIL_ERROR:
         return false;
      default:
         return state;
   }
};

const emailVerificationFailed = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_VERIFY_EMAIL:
         return false;
      case userConstants.VERIFY_EMAIL_ERROR:
         return true;
      case userConstants.VERIFY_EMAIL_SUCCESS:
         return false;
      default:
         return state;
   }
};

// const emailVerifiedUser = (state = null, action) => {
//    switch (action.type) {
//       case userConstants.VERIFY_EMAIL_SUCCESS:
//          return updateObject(state, action.verifiedUser);
//       default:
//          return state;
//    }
// };

const passwordIsSetSuccessful = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_SET_PASSWORD:
         return false;
      case userConstants.SET_PASSWORD_SUCCESS:
         return true;
      case userConstants.SET_PASSWORD_ERROR:
         return false;
      default:
         return state;
   }
};

const passwordIsChangedSuccessful = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_CHANGE_PASSWORD:
         return false;
      case userConstants.CHANGE_PASSWORD_SUCCESS:
         return true;
      case userConstants.CHANGE_PASSWORD_ERROR:
         return false;
      default:
         return state;
   }
};

const statesList = (state = [], action) => {
   switch (action.type) {
      case appConstants.REQUEST_ALL_STATES_SUCCESS: {
         return action.states.states;
      }
      default:
         return state;
   }
};

const error = (state = false, {type}) => {
   switch (type) {
      case userConstants.LOGIN_REQUEST:
         return false;
      case userConstants.LOGIN_FAILURE:
         return true;
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case userConstants.LOGIN_FAILURE:
         return updateObject(state, error);
      case userConstants.LOGIN_REQUEST:
         return null;
      case userConstants.CHANGE_PASSWORD_ERROR:
         return updateObject(state, error);
      case userConstants.REQUEST_CHANGE_PASSWORD:
         return null;
      case userConstants.VERIFY_EMAIL_ERROR:
         return updateObject(state, error);
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingLogin,
   loginSuccess,
   IsRequestingLogout,
   tokenHasExpired,
   IsVerifyingEmail,
   IsResendingVerificationLink,
   emailVerificationSuccessful,
   emailVerificationFailed,
   // emailVerifiedUser,
   IsSettingPassword,
   passwordIsSetSuccessful,
   IsChangingPassword,
   passwordIsChangedSuccessful,
   statesList,
   error,
   errorMessage,
});

export default rootReducer;
