import {call, put, takeLatest, all} from 'redux-saga/effects';
import {paymentConstants, userConstants} from '../../_constants';
import {getObjectFromStorage, checkStatus, createRequestWithToken, clearObjectFromStorage} from '../../_helpers';

function* getPayments({data}) {
   yield put({type: paymentConstants.REQUEST_ALL_PAYMENTS});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);
      let paymentsUri = `${paymentConstants.PAYMENT_URI}`;

      if (data?.page) {
         paymentsUri = `${paymentsUri}?page=${data.page + 1}`;
      }

      const paymentsReq = createRequestWithToken(paymentsUri, {
         method: 'GET',
      })(user?.accessToken);

      const response = yield call(fetch, paymentsReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: paymentConstants.REQUEST_ALL_PAYMENTS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: paymentConstants.REQUEST_ALL_PAYMENTS_SUCCESS,
         payments: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({type: paymentConstants.REQUEST_ALL_PAYMENTS_ERROR});
   }
}

function* searchPayment({data}) {
   yield put({type: paymentConstants.REQUEST_SEARCH_PAYMENT});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const paymentUri = `${paymentConstants.PAYMENT_URI}/search?query=${data.searchInput}`;

      const paymentReq = createRequestWithToken(paymentUri, {method: 'GET'})(user?.accessToken);

      const response = yield call(fetch, paymentReq);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: paymentConstants.SEARCH_PAYMENT_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: paymentConstants.SEARCH_PAYMENT_SUCCESS,
         payment: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({
         type: paymentConstants.SEARCH_PAYMENT_ERROR,
         error: errorMessage,
      });
   }
}
function* getPaymentsWatcher() {
   yield takeLatest(paymentConstants.GET_ALL_PAYMENTS, getPayments);
}

function* searchPaymentWatcher() {
   yield takeLatest(paymentConstants.SEARCH_PAYMENT, searchPayment);
}

export default function* rootSaga() {
   yield all([getPaymentsWatcher(), searchPaymentWatcher()]);
}
