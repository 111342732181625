import React from 'react';
import classNames from 'classnames';

import {ReactComponent as WithdrawIcon} from '../../../_assets/icons/withdraw.svg';
import {ReactComponent as GreenIcon} from '../../../_assets/icons/greenMark.svg';
// import {ReactComponent as DotsIcon} from '../../../_assets/icons/dots.svg';

const Payments = ({tab}) => {
   return (
      <div className={classNames('profile payments px-4 flex-column', {'d-flex': tab === 3, 'd-none': tab !== 3})}>
         <div className="d-flex align-items-center justify-content-between">
            <p className="info">Payment Method</p>
            <div className="d-flex align-items-center">
               <small className="">Wallet Balance</small>
               <p className="balance h4 mx-3 mb-0">&#8358; 157,490.00</p>
               <button className="withdraw d-flex align-items-center">
                  <WithdrawIcon className="me-2" style={{transform: 'scale(.7)'}} />
                  Withdraw
               </button>
            </div>
         </div>
         <div className="method p-4 my-4 mb-5 d-flex align-items-center justify-content-between">
            <div className="">
               <h6 className="">Bank Name</h6>
               <p className="">GT Bank Plc</p>
            </div>
            <div className="">
               <h6 className="">Account Name</h6>
               <p className="">Lagos Tea House</p>
            </div>
            <div className="">
               <h6 className="">Account Number</h6>
               <p className="">1200000000</p>
            </div>
            <div className="d-flex align-items-center">
               <GreenIcon className="" style={{transform: 'scale(.75)'}} />
               {/* <DotsIcon className="ms-4" style={{transform: 'scale(.9)', cursor: 'pointer'}} /> */}
            </div>
         </div>
         <div className="">
            <button className="submit">Add New Payment</button>
         </div>
      </div>
   );
};

export default Payments;
