import {appConstants} from './app.constant';

const reservation = 'reservation';

export const reservationConstants = {
   REQUEST_ALL_RESERVATIONS: 'REQUEST_ALL_RESERVATIONS',
   REQUEST_ALL_RESERVATIONS_SUCCESS: 'REQUEST_ALL_RESERVATIONS_SUCCESS',
   REQUEST_ALL_RESERVATIONS_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_RESERVATIONS_SUCCESS_WITHOUT_DATA',
   REQUEST_ALL_RESERVATIONS_ERROR: 'REQUEST_ALL_RESERVATIONS_ERROR',

   REQUEST_FILTER_RESERVATIONS: 'REQUEST_FILTER_RESERVATIONS',
   REQUEST_FILTER_RESERVATIONS_SUCCESS: 'REQUEST_FILTER_RESERVATIONS_SUCCESS',
   REQUEST_FILTER_RESERVATIONS_SUCCESS_WITHOUT_DATA: 'REQUEST_FILTER_RESERVATIONS_SUCCESS_WITHOUT_DATA',
   REQUEST_FILTER_RESERVATIONS_ERROR: 'REQUEST_FILTER_RESERVATIONS_ERROR',

   REQUEST_A_RESERVATION: 'REQUEST_A_RESERVATION',
   REQUEST_A_RESERVATION_SUCCESS: 'REQUEST_A_RESERVATION_SUCCESS',
   REQUEST_A_RESERVATION_ERROR: 'REQUEST_A_RESERVATION_ERROR',

   REQUEST_SEARCH_RESERVATION: 'REQUEST_SEARCH_RESERVATION',
   SEARCH_RESERVATION_SUCCESS: 'SEARCH_RESERVATION_SUCCESS',
   SEARCH_RESERVATION_ERROR: 'SEARCH_RESERVATION_ERROR',
   SEARCH_RESERVATION_SUCCESS_WITHOUT_DATA: 'SEARCH_RESERVATION_SUCCESS_WITHOUT_DATA',

   REQUEST_DELETE_RESERVATION: 'REQUEST_DELETE_RESERVATION',
   DELETE_RESERVATION_SUCCESS: 'DELETE_RESERVATION_SUCCESS',
   DELETE_RESERVATION_ERROR: 'DELETE_RESERVATION_ERROR',

   REQUEST_UPDATE_RESERVATION: 'REQUEST_UPDATE_RESERVATION',
   UPDATE_RESERVATION_SUCCESS: 'UPDATE_RESERVATION_SUCCESS',
   UPDATE_RESERVATION_ERROR: 'UPDATE_RESERVATION_ERROR',

   GET_ALL_RESERVATIONS: 'GET_ALL_RESERVATIONS',
   FILTER_RESERVATIONS: 'FILTER_RESERVATIONS',
   GET_A_RESERVATION: 'GET_A_RESERVATION',
   SEARCH_RESERVATION: 'SEARCH_RESERVATION',

   DELETE_RESERVATION: 'DELETE_RESERVATION',
   UPDATE_RESERVATION: 'UPDATE_RESERVATION',

   RESERVATION_URI: `${appConstants.BASE_URI}${reservation}`,
};
