import {appConstants} from './app.constant';

const blog = 'post';

export const blogConstants = {
   REQUEST_CREATE_BLOG: 'REQUEST_CREATE_BLOG',
   CREATE_BLOG_SUCCESS: 'CREATE_BLOG_SUCCESS',
   CREATE_BLOG_ERROR: 'CREATE_BLOG_ERROR',

   REQUEST_ALL_BLOGS: 'REQUEST_ALL_BLOGS',
   REQUEST_ALL_BLOGS_SUCCESS: 'REQUEST_ALL_BLOGS_SUCCESS',
   REQUEST_ALL_BLOGS_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_BLOGS_SUCCESS_WITHOUT_DATA',
   REQUEST_ALL_BLOGS_ERROR: 'REQUEST_ALL_BLOGS_ERROR',

   REQUEST_A_BLOG: 'REQUEST_A_BLOG',
   REQUEST_A_BLOG_SUCCESS: 'REQUEST_A_BLOG_SUCCESS',
   REQUEST_A_BLOG_ERROR: 'REQUEST_A_BLOG_ERROR',

   REQUEST_SEARCH_BLOG: 'REQUEST_SEARCH_BLOG',
   SEARCH_BLOG_SUCCESS: 'SEARCH_BLOG_SUCCESS',
   SEARCH_BLOG_ERROR: 'SEARCH_BLOG_ERROR',
   SEARCH_BLOG_SUCCESS_WITHOUT_DATA: 'SEARCH_BLOG_SUCCESS_WITHOUT_DATA',

   REQUEST_DELETE_BLOG: 'REQUEST_DELETE_BLOG',
   DELETE_BLOG_SUCCESS: 'DELETE_BLOG_SUCCESS',
   DELETE_BLOG_ERROR: 'DELETE_BLOG_ERROR',

   REQUEST_UPDATE_BLOG: 'REQUEST_UPDATE_BLOG',
   UPDATE_BLOG_SUCCESS: 'UPDATE_BLOG_SUCCESS',
   UPDATE_BLOG_ERROR: 'UPDATE_BLOG_ERROR',

   REQUEST_DELETE_BLOG_COMMENT: 'REQUEST_DELETE_BLOG_COMMENT',
   DELETE_BLOG_COMMENT_SUCCESS: 'DELETE_BLOG_COMMENT_SUCCESS',
   DELETE_BLOG_COMMENT_ERROR: 'DELETE_BLOG_COMMENT_ERROR',

   GET_ALL_BLOGS: 'GET_ALL_BLOGS',
   CREATE_BLOG: 'CREATE_BLOG',
   SEARCH_BLOG: 'SEARCH_BLOG',

   DELETE_BLOG: 'DELETE_BLOG',
   UPDATE_BLOG: 'UPDATE_BLOG',
   GET_A_BLOG: 'GET_A_BLOG',
   DELETE_BLOG_COMMENT: 'DELETE_BLOG_COMMENT',

   BLOG_URI: `${appConstants.BASE_URI}${blog}`,
};
