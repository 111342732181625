import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import Formsy from 'formsy-react';
import {FiEye, FiEyeOff} from 'react-icons/fi';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AuthWrapper, TextInput} from '../../_components';
import {capitalizeFirstLetter} from '../../_helpers';
import './index.scss';
import {appActions, forgotPasswordActions} from '../../_actions';

import {ReactComponent as NutsIcon} from '../../_assets/icons/nuts.svg';

const ResetPassword = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   let {token} = useParams();
   const {isResettingPassword, successMessage, errorMessage} = useSelector((s) => s.forgotPassword);

   const [showPass, setShowPass] = useState();
   const [showConfirmPass, setShowConfirmPass] = useState();
   const [canSubmit, setCanSubmit] = useState(false);
   const [confirmPasswordError, setConfirmPasswordError] = useState('');

   const handleSubmit = (data) => {
      // const {token} = match.params;
      data['token'] = token;
      if (data.password !== data.confirm_password) {
         setConfirmPasswordError('Passwords do not match');
      } else {
         delete data['confirm_password'];
         dispatch(forgotPasswordActions.resetPassword(data));
      }
      // navigate('/dashboard');
   };

   useEffect(() => {
      const timer = setTimeout(() => {
         dispatch(appActions.clearMessages());
         if (successMessage) {
            navigate('/login');
         }
      }, 2000);
      return () => clearTimeout(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [successMessage]);

   return (
      <AuthWrapper>
         <Formsy
            className="position-relative p-md-5 p-3"
            onValidSubmit={handleSubmit}
            onValid={() => setCanSubmit(true)}
            onInvalid={() => setCanSubmit(false)}>
            <NutsIcon className="nutIcon position-absolute" />
            <p className="signIn py-2">Reset Password</p>
            {errorMessage !== null && (
               <div className="server-error mb-2">
                  <p className="">{capitalizeFirstLetter(errorMessage?.message?.[0])}!</p>
               </div>
            )}
            <p className="small pb-4">Please input your new password.</p>
            <TextInput
               type={showPass ? 'text' : 'password'}
               id="new_password"
               name="password"
               label="New Password"
               className="mb-3 position-relative"
               inputClassName="dateTime"
               placeholder="********"
               value=""
               rightIcon={
                  <div onClick={() => setShowPass((t) => !t)} className="__icon position-absolute">
                     {showPass ? <FiEye /> : <FiEyeOff />}
                  </div>
               }
            />
            <TextInput
               type={showConfirmPass ? 'text' : 'password'}
               id="confirm_password"
               name="confirm_password"
               label="Confirm Password"
               className="mb-3 position-relative"
               inputClassName="dateTime"
               placeholder="********"
               value=""
               rightIcon={
                  <div onClick={() => setShowConfirmPass((t) => !t)} className="__icon position-absolute">
                     {showConfirmPass ? <FiEye /> : <FiEyeOff />}
                  </div>
               }
            />
            <p className="error mb-3">{confirmPasswordError}</p>

            <button disabled={!canSubmit} type="submit" className="sign mt-4 w-100">
               {isResettingPassword ? <CircularProgress color="inherit" thickness={2} /> : 'Reset'}
            </button>
         </Formsy>
      </AuthWrapper>
   );
};

export default ResetPassword;
