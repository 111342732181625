import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import StarRating from 'react-svg-star-rating';
import {numberWithCommas, readableDateString} from '../../_helpers';
import {menuActions} from '../../_actions';
import Dialog from '../../_components/Modal/Group/Dialog';

import logoImg from '../../_assets/images/LTHLogo.png';
import {ReactComponent as DeleteIcon} from '../../_assets/icons/deleteBucket.svg';
import {ReactComponent as EditIcon} from '../../_assets/icons/editPen.svg';

const MenuRow = ({data, _id, img, img_url, updatedAt, name, description, comments, price, stars}) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [starRating, setStarRating] = useState(stars);
   const [deleteMenu, setDeleteMenu] = useState(false);
   const [menuData, setMenuData] = useState({});

   const toggleDelete = (row) => {
      setMenuData(row);
      setDeleteMenu(!deleteMenu);
   };

   const handleDelete = () => {
      const _id = menuData?._id;

      if (_id) {
         dispatch(menuActions.deleteMenu(_id));
      }
      setDeleteMenu(false);
   };
   // const handleStarClick = (rating) => {
   //    setStarRating(rating);
   // };

   // const toggleDelete = (row) => {
   //    setMenuData(row);
   //    setDeleteMenu(!deleteMenu);
   // };

   const handleViewMeal = (item) => {
      dispatch(menuActions.getAMenu({_id: item?._id}));
      navigate(`/menu/${item.name}`, {state: {mealDetails: item}});
   };

   return (
      <tr key={`recentOrderList-${_id}`} className="d-table-row" style={{cursor: 'auto'}}>
         <th scope="col" className="d-flex align-items-center pe-0">
            <div className="img me-2 d-flex align-items-center justify-content-center">
               {img_url ? (
                  <img src={img_url} alt="" className="h-100 w-100" />
               ) : (
                  <img src={logoImg} alt="" style={{transform: 'scale(.5)'}} />
               )}
            </div>
            <p className="mealName">{name + ' ' + description}</p>
         </th>
         <td className="text-nowrap align-middle">&#8358; {numberWithCommas(price)}</td>
         <td className="text-nowrap align-middle">{comments.length}</td>
         <td className="text-nowrap align-middle">
            <p className="d-flex align-items-center">
               <span className="me-1">{starRating}</span>
               <StarRating
                  unit="half"
                  size={15}
                  count={5}
                  initialRating={starRating}
                  activeColor="#FFC403"
                  hoverColor="#FFC403"
                  emptyColor="rgba(0, 0, 0, .3)"
                  roundedCorner={true}
                  // handleOnClick={handleStarClick}
                  isReadOnly={true}
                  innerRadius={20}
                  outerRadius={45}
                  starClassName="each-star"
                  containerClassName=""
               />
            </p>
         </td>
         <td className="text-nowrap align-middle">{readableDateString(updatedAt)}</td>
         <td className="text-nowrap align-middle">
            <span className="available">Available</span>
         </td>
         <td className="text-nowrap align-middle">
            <div className="d-flex align-items-center">
               <DeleteIcon
                  onClick={() => toggleDelete(data)}
                  // onClick={handleDelete}
                  className="me-3"
                  style={{transform: 'scale(.7)', cursor: 'pointer'}}
               />
               <Dialog modalOpen={deleteMenu} title="Menu" handleDelete={handleDelete} setModalOpen={setDeleteMenu} />
               <EditIcon
                  onClick={() => handleViewMeal(data)}
                  className="ms-3"
                  style={{transform: 'scale(.7)', cursor: 'pointer'}}
               />
            </div>
         </td>
      </tr>
   );
};

export default MenuRow;
