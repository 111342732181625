import React from 'react';
import {useNavigate} from 'react-router-dom';
import {capitalizeFirstLetter} from '../../_helpers';

const ReservationRow = ({data, id, fullname, email, phone_no, ticket_no, date, time, status_name}) => {
   const navigate = useNavigate();

   const handleViewOrder = (item) => {
      navigate(`/reservations/${item.fullname}`, {state: {reservationDetails: item}});
   };

   return (
      <tr key={`recentOrderList-${id}`} onClick={() => handleViewOrder(data)} className="d-table-row">
         <th scope="col" className="text-nowrap d-flex align-items-center">
            {fullname}
         </th>
         <td className="text-nowrap align-middle">{ticket_no}</td>
         <td className="text-nowrap align-middle">{email}</td>
         <td className="text-nowrap align-middle">{phone_no}</td>
         {/* <td className="text-nowrap align-middle">{guests}</td>
         <td className="text-nowrap align-middle">{facility}</td> */}
         <td className="text-nowrap align-middle">
            {date} {time}
         </td>
         <td>
            <span className="delivered text-nowrap align-middle">{capitalizeFirstLetter(status_name)}</span>
            {/* <span className="onDelivery text-nowrap">On Delivery</span>
            <span className="canceled text-nowrap">Canceled</span> */}
         </td>
      </tr>
   );
};

export default ReservationRow;
