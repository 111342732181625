import {orderConstants} from '../_constants';

const getAllOrders = (model) => ({
   type: orderConstants.GET_ALL_ORDERS,
   data: model,
});

const getRecentOrders = () => ({
   type: orderConstants.GET_RECENT_ORDERS,
});

const getAnOrder = (model) => ({
   type: orderConstants.GET_AN_ORDER,
   data: model,
});

const searchOrder = (model) => ({
   type: orderConstants.SEARCH_ORDER,
   data: model,
});

const updateOrder = (model) => ({
   type: orderConstants.UPDATE_ORDER,
   data: model,
});

const deleteOrder = (model) => ({
   type: orderConstants.DELETE_ORDER,
   data: model,
});

export const orderActions = {
   getAllOrders,
   getRecentOrders,
   getAnOrder,
   searchOrder,
   updateOrder,
   deleteOrder,
};
