import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {tagConstants, userConstants} from '../../_constants';
import {getObjectFromStorage, checkStatus, createRequestWithToken, clearObjectFromStorage} from '../../_helpers';

function* getTags({data}) {
   yield put({type: tagConstants.REQUEST_ALL_TAGS});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);
      let tagsUri = `${tagConstants.TAG_URI}`;

      if (data?.page) {
         tagsUri = `${tagsUri}?page=${data.page + 1}`;
      }
      if (data?.pagination) {
         tagsUri = `${tagConstants.TAG_URI}get_all`;
      }

      const tagsReq = createRequestWithToken(tagsUri, {
         method: 'GET',
      })(user?.accessToken);

      const response = yield call(fetch, tagsReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: tagConstants.REQUEST_ALL_TAGS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: tagConstants.REQUEST_ALL_TAGS_SUCCESS,
         tags: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({type: tagConstants.REQUEST_ALL_TAGS_ERROR});
   }
}

function* createTagSaga({data}) {
   try {
      yield put({type: tagConstants.REQUEST_CREATE_TAG});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${tagConstants.TAG_URI}`;
      const req = createRequestWithToken(url, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: tagConstants.CREATE_TAG_SUCCESS,
         tag: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Tag successfully posted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({
         type: tagConstants.CREATE_TAG_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* updateTagSaga({data}) {
   try {
      yield put({type: tagConstants.REQUEST_UPDATE_TAG});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${tagConstants.TAG_URI}/${data._id}`;
      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.accessToken);
      // const curriedReq = yield call(createMultiPartRequestWithToken, url, {
      //    method: 'POST',
      //    body: data.fields.fData,
      // });
      // const req = yield call(curriedReq, user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: tagConstants.UPDATE_TAG_SUCCESS,
         tag: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Tag successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({
         type: tagConstants.UPDATE_TAG_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* deleteTagSaga({_id}) {
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      yield put({type: tagConstants.REQUEST_DELETE_TAG});

      const url = `${tagConstants.TAG_URI}/${_id}`;
      const req = createRequestWithToken(url, {
         method: 'DELETE',
      })(user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: tagConstants.DELETE_TAG_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Tag successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({
         type: tagConstants.DELETE_TAG_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* searchTagSaga({data}) {
   try {
      yield put({type: tagConstants.REQUEST_SEARCH_TAG});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const tagUri = `${tagConstants.TAG_URI}/search?query=${data.searchInput}`;

      const tagReq = createRequestWithToken(tagUri, {method: 'GET'})(user?.accessToken);

      const response = yield call(fetch, tagReq);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: tagConstants.SEARCH_TAG_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: tagConstants.SEARCH_TAG_SUCCESS,
         tag: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({
         type: tagConstants.SEARCH_TAG_ERROR,
         error: errorMessage,
      });
   }
}
function* getTagsWatcher() {
   yield takeLatest(tagConstants.GET_ALL_TAGS, getTags);
}

function* createTagWatcher() {
   yield takeLatest(tagConstants.CREATE_TAG, createTagSaga);
}

function* updateTagWatcher() {
   yield takeLatest(tagConstants.UPDATE_TAG, updateTagSaga);
}

function* deleteTagWatcher() {
   yield takeLatest(tagConstants.DELETE_TAG, deleteTagSaga);
}

function* searchTagWatcher() {
   yield takeLatest(tagConstants.SEARCH_TAG, searchTagSaga);
}

export default function* rootSaga() {
   yield all([getTagsWatcher(), createTagWatcher(), updateTagWatcher(), deleteTagWatcher(), searchTagWatcher()]);
}
