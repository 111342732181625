import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import Select from 'react-dropdown-select';
import {CSVLink} from 'react-csv';
import {AddMenu} from '../../_components';
import MenuRow from './MenuRow';
import Loader from '../../_helpers/loader';
import {Pagination} from '../../_components';
import './index.scss';
import {categoryActions, tagActions, menuActions} from '../../_actions';

const Menu = () => {
   const dispatch = useDispatch();

   const {IsRequestingAllMenus, menuList, allMenuList, pagination} = useSelector((s) => s.menu);
   const {categoriesList} = useSelector((s) => s.category);

   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   const [category, setCategory] = useState();
   const [addMenuModal, setAddMenuModal] = useState(false);
   useEffect(() => {
      dispatch(categoryActions.getAllCategories());
      dispatch(tagActions.getAllTags());
      dispatch(menuActions.getAllMenus());
      // dispatch(menuActions.getAllMenusWithoutPagination());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const all = {
      value: '1',
      label: 'All',
   };
   const newArr = categoriesList?.map((v) => ({
      ...v,
      label: v.name,
      value: v._id,
   }));
   newArr.unshift(all);

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(menuActions.getAllMenus(model));
   };

   const handleAddMenu = () => {
      setAddMenuModal(true);
   };

   useEffect(() => {
      if (category && category !== '1') {
         const model = {
            _id: category,
         };

         dispatch(menuActions.getCategoryMenus(model));
      } else {
         dispatch(menuActions.getAllMenus());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [category]);

   return (
      <div className="p-4">
         <div className="menu">
            <div className="p-4 d-flex flex-wrap align-items-center justify-content-between">
               <Select
                  className="text-capitalize"
                  options={newArr}
                  values={newArr.find((opt) => opt.value === String(newArr?.[0]?.label))}
                  onChange={(values) => setCategory(values[0]?.value)}
               />
               <div className="d-flex mt-md-0 mt-4 align-items-center">
                  <button className="csv me-4">
                     <CSVLink
                        data={allMenuList}
                        style={{
                           color: '#F53B00',
                        }}>
                        Export CSV
                     </CSVLink>
                  </button>

                  <button onClick={handleAddMenu} className="addMenu">
                     Add Menu
                  </button>
               </div>
               <AddMenu modalOpen={addMenuModal} setModalOpen={setAddMenuModal} />
            </div>
            {IsRequestingAllMenus ? (
               <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                  <div />
               </Loader>
            ) : (
               <MDBTable responsive>
                  <MDBTableHead>
                     <tr>
                        <th>Meal</th>
                        <th>Amount</th>
                        <th>Comments</th>
                        <th>Ratings</th>
                        <th className="text-nowrap">Last Updated</th>
                        <th>Status</th>
                        <th></th>
                     </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                     {menuList?.length > 0 ? (
                        menuList.map((item, i) => {
                           return <MenuRow key={i} {...item} data={item} />;
                        })
                     ) : (
                        <td align="center" className="no-content" colSpan={5}>
                           No menu has been uploaded on this platform
                        </td>
                     )}
                  </MDBTableBody>
               </MDBTable>
            )}
         </div>
         {!IsRequestingAllMenus && totalPages > 1 && (
            <Pagination
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
         {/* <Pagination /> */}
      </div>
   );
};

export default Menu;
