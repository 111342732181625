import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Overview from './Overview';
import './index.scss';
import RecentOrders from './Recent';
// import Revenue from './Revenue';
import {dashboardActions, orderActions} from '../../_actions';

const Dashboard = () => {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(dashboardActions.getDashboardMetrics());
      dispatch(orderActions.getRecentOrders());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="dashboard">
         <Overview />
         <RecentOrders />
         {/* <Revenue /> */}
      </div>
   );
};

export default Dashboard;
