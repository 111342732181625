import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import {AdminDetails} from '../../../_components';
import Dialog from '../../../_components/Modal/Group/Dialog';
import {readableDateString} from '../../../_helpers';

import {ReactComponent as DeleteIcon} from '../../../_assets/icons/deleteBucket.svg';
import {ReactComponent as EditIcon} from '../../../_assets/icons/editPen.svg';
import {userActions} from '../../../_actions';

const AdminRow = ({data, id, _id, firstname, lastname, email, createdAt, is_active}) => {
   const dispatch = useDispatch();

   const [editDetails, setEditDetails] = useState(null);
   const [adminModal, setAdminModal] = useState(false);
   const [deleteAdmin, setDeleteAdmin] = useState(false);
   const [adminDetails, setAdminDetails] = useState({});

   const handleEditModal = (itm) => {
      setAdminModal(true);
      setEditDetails(itm);
   };

   const toggleDelete = (row) => {
      setAdminDetails(row);
      setDeleteAdmin(true);
   };

   const handleDelete = () => {
      // const _id = adminDetails?._id;

      if (_id) {
         dispatch(userActions.deleteUser(_id));
      }
      setDeleteAdmin(false);
   };

   return (
      <>
         <tr key={`recentOrderList-${id}`} className="d-table-row">
            <th scope="col" className="d-flex align-items-center">
               {firstname}
            </th>
            <td className="text-nowrap align-middle">{lastname}</td>
            <td className="text-nowrap align-middle">{email}</td>
            <td>
               <span
                  // className="text-nowrap delivered align-middle"
                  className={classNames('text-nowrap align-middle', is_active ? 'delivered' : 'canceled')}>
                  {is_active ? 'Active' : 'Inactive'}
               </span>
               {/* <span className="delivered onDelivery text-nowrap">On Delivery</span>
                  <span className="canceled text-nowrap">Canceled 26/05/2022, 12:31 AM Festac, Lagos</span> */}
            </td>
            <td className="text-nowrap align-middle">{readableDateString(createdAt)}</td>
            <td className="text-nowrap align-middle">
               <div className="d-flex align-items-center">
                  <DeleteIcon
                     onClick={() => toggleDelete(data)}
                     className="me-3"
                     style={{transform: 'scale(.7)', cursor: 'pointer'}}
                  />
                  <Dialog
                     modalOpen={deleteAdmin}
                     title="Admin User"
                     handleDelete={handleDelete}
                     setModalOpen={setDeleteAdmin}
                  />
                  <EditIcon
                     onClick={() => handleEditModal(data)}
                     className="ms-3"
                     style={{transform: 'scale(.7)', cursor: 'pointer'}}
                  />
               </div>
            </td>
            {/* <td className="text-nowrap align-middle">+234 812 039 1910</td>
            <td className="text-nowrap align-middle">&#8358; {numberWithCommas(5000)}</td> */}
         </tr>
         <AdminDetails data={editDetails} modalOpen={adminModal} setModalOpen={setAdminModal} />
      </>
   );
};

export default AdminRow;
