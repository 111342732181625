import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import Formsy from 'formsy-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {FiEye, FiEyeOff} from 'react-icons/fi';
import {TextInput} from '../../../_components';
import {userActions} from '../../../_actions';
import {capitalizeFirstWord} from '../../../_helpers';

const Security = ({tab}) => {
   const dispatch = useDispatch();
   const {IsChangingPassword, errorMessage} = useSelector((s) => s.auth);
   const {userDetails} = useSelector((s) => s.user);

   const [showPass, setShowPass] = useState(false);
   const [canSubmit, setCanSubmit] = useState(false);
   const [passError, setPassError] = useState('');

   const handleSubmit = (data) => {
      setPassError('');
      if (data.confirm_password === data.password) {
         delete data['confirm_password'];
         data['_id'] = userDetails?._id;
         dispatch(userActions.changePassword(data));
      } else {
         setPassError('Passwords do not match');
      }
   };

   const passwordError = errorMessage?.message.filter((error) => error.split(' ')[0] === 'password');

   return (
      <div className={classNames('profile security px-4 flex-column', {'d-flex': tab === 2, 'd-none': tab !== 2})}>
         <p className="info" style={{fontSize: '1rem'}}>
            Change Password
         </p>
         <Formsy
            className="mt-4"
            onValidSubmit={handleSubmit}
            onValid={() => setCanSubmit(true)}
            onInvalid={() => setCanSubmit(false)}>
            {/* <div className="mb-4 row m-0 d-flex align-items-enter justify-content-between"> */}
            <TextInput
               id="current_password"
               type={showPass ? 'text' : 'password'}
               label="Current Password"
               name="currentPassword"
               className="mb-3 col-md-6 col-12 position-relative"
               inputClassName="dateTime"
               placeholder="********"
               required
               rightIcon={
                  <div onClick={() => setShowPass((t) => !t)} className="__icon position-absolute">
                     {showPass ? <FiEye /> : <FiEyeOff />}
                  </div>
               }
            />
            <TextInput
               id="new_password"
               type={showPass ? 'text' : 'password'}
               label="New Password"
               name="password"
               className="mb-3 col-md-6 col-12 position-relative"
               inputClassName="dateTime"
               placeholder="********"
               required
               rightIcon={
                  <div onClick={() => setShowPass((t) => !t)} className="__icon position-absolute">
                     {showPass ? <FiEye /> : <FiEyeOff />}
                  </div>
               }
            />
            <p className="error">{passwordError?.length > 0 && capitalizeFirstWord(passwordError?.[0])}</p>
            {/* </div> */}
            {/* <div className="mb-4 row m-0 d-flex align-items-enter justify-content-between"> */}
            <TextInput
               type={showPass ? 'text' : 'password'}
               id="confirm_password"
               name="confirm_password"
               label="Confirm Password"
               className="mb-3 col-md-6 col-12 position-relative"
               inputClassName="dateTime"
               placeholder="********"
               required
               rightIcon={
                  <div onClick={() => setShowPass((t) => !t)} className="__icon position-absolute">
                     {showPass ? <FiEye /> : <FiEyeOff />}
                  </div>
               }
            />
            <p style={{color: 'red'}}>{passError}</p>
            {/* <TextInput
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  label="Mobile Number"
                  className="mb-2 col-md-6 col-12"
                  inputClassName="dateTime"
                  placeholder=""
                  value="+234 810 738 8028"
               /> */}
            {/* </div> */}
            <button disabled={!canSubmit} className="submit mt-4 text-nowrap">
               {IsChangingPassword ? <CircularProgress color="inherit" thickness={2} /> : 'Save and Update'}
            </button>
         </Formsy>
      </div>
   );
};

export default Security;
