import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import FullscreenModal from '../..';
import Select from 'react-dropdown-select';
import Formsy from 'formsy-react';
import {TextInput} from '../../../CustomInput';
import {FiEye, FiEyeOff} from 'react-icons/fi';
import {userActions} from '../../../../_actions';
import {roles, isActive} from '../../../../_constants';
import './index.scss';

import {ReactComponent as CloseIcon} from '../../../../_assets/icons/close.svg';

const AdminDetails = ({modalOpen, setModalOpen, data, type}) => {
   const dispatch = useDispatch();
   const {IsRegisteringUser, userCreated, IsUpdatingUserStatus, userStatusUpdated} = useSelector((s) => s.user);

   const [canSubmit, setCanSubmit] = useState(false);
   const [showPass, setShowPass] = useState();
   const [role, setRole] = useState('');
   const [active, setActive] = useState('');

   const handleSubmit = (formInfo) => {
      if (formInfo.firstname) {
         if (type === 'add') {
            formInfo['role'] = role;
            dispatch(userActions.createUser(formInfo));
         } else {
            // data['is_active'] = active;
            const model = {
               is_active: active,
               _id: data?._id,
            };
            dispatch(userActions.updateUserStatus(model));
         }
      }
   };

   useEffect(() => {
      if (userCreated || userStatusUpdated) {
         setModalOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userCreated, userStatusUpdated]);

   return (
      <FullscreenModal open={modalOpen} onClickAway={() => setModalOpen(false)}>
         <div className="addMenu px-md-5 px-3 py-md-4 py-3 position-relative">
            <div className="position-absolute" style={{top: '1rem', right: '1.5rem'}}>
               <CloseIcon onClick={() => setModalOpen(false)} style={{transform: 'scale(.7)', cursor: 'pointer'}} />
            </div>
            <Formsy
               className="w-100"
               onValidSubmit={handleSubmit}
               onValid={() => setCanSubmit(true)}
               onInvalid={() => setCanSubmit(false)}>
               {type === 'add' ? (
                  <>
                     <h5 className="text-center">Add Admin User</h5>
                     <h6 className="text-center pt-2">
                        Enter the email address of the user you want to add, <br /> and select a role for the user.
                     </h6>
                  </>
               ) : (
                  <h5 className="text-center mb-3 mx-5 px-4">Edit Admin User</h5>
               )}
               {/* <div className="py-3 my-3">
                  <span className="">Add Image</span>
                  <div className="mt-1 d-flex align-items-center">
                     <div className="img me-3">
                        <img src={image} alt="" className="h-100 w-100" />
                     </div>
                     <div className="">
                        <button onClick={() => input.current.click()} className="choose">
                           Choose Image
                        </button>
                        <p className="">JPG or PNG not more than 1MB</p>
                        <input
                           ref={input}
                           onClick={(e) => (input.current.value = null)}
                           onChange={handleChange}
                           type="file"
                           accept=".png, .jpg, .jpeg"
                           className=""
                           style={{display: 'none'}}
                        />
                     </div>
                  </div>
               </div> */}
               <TextInput
                  type="text"
                  id="full_name"
                  name="firstname"
                  label="First Name"
                  className="mb-3"
                  value={data?.firstname}
                  inputClassName="dateTime"
                  placeholder=""
                  required
                  disabled={type === 'add' ? false : true}
               />
               <TextInput
                  type="text"
                  id="full_name"
                  name="lastname"
                  value={data?.lastname}
                  label="Last Name"
                  className="mb-3"
                  inputClassName="dateTime"
                  placeholder=""
                  required
                  disabled={type === 'add' ? false : true}
               />
               <TextInput
                  type="text"
                  id="email"
                  name="email"
                  value={data?.email}
                  label="Email Address"
                  className="mb-3"
                  inputClassName="dateTime"
                  placeholder="toyinanifowoshe@gmail.com"
                  required
                  disabled={type === 'add' ? false : true}
               />
               {type === 'add' && (
                  <div className="">
                     <label htmlFor="" className="">
                        Role
                     </label>
                     <Select
                        className="mb-3 text-capitalize"
                        options={roles}
                        values={type === 'add' ? [] : [roles?.find((opt) => opt.value === data?.role)]}
                        onChange={(values) => setRole(values[0]?.value)}
                     />
                  </div>
               )}
               {type === 'add' ? (
                  <TextInput
                     type={showPass ? 'text' : 'password'}
                     id="password"
                     name="password"
                     label="Password"
                     className="mb-3 position-relative"
                     value={data?.password}
                     inputClassName="dateTime pe-5"
                     placeholder="********"
                     required
                     rightIcon={
                        <div onClick={() => setShowPass((t) => !t)} className="__icon position-absolute">
                           {showPass ? <FiEye /> : <FiEyeOff />}
                        </div>
                     }
                  />
               ) : (
                  <div className="">
                     <label htmlFor="" className="">
                        Active
                     </label>
                     <Select
                        className="mb-3 text-capitalize"
                        options={isActive}
                        values={type === 'add' ? [] : [isActive?.find((opt) => opt.value === data?.is_active)]}
                        onChange={(values) => setActive(values[0]?.value)}
                     />
                  </div>
               )}
               <button type="submit" className="save w-100 mt-4" disabled={!canSubmit}>
                  {IsRegisteringUser || IsUpdatingUserStatus ? (
                     <CircularProgress color="inherit" thickness={1} />
                  ) : (
                     'Save'
                  )}
               </button>
            </Formsy>
         </div>
      </FullscreenModal>
   );
};

export default AdminDetails;
