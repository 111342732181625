import {appConstants} from './app.constant';

const tag = 'tags';

export const tagConstants = {
   REQUEST_CREATE_TAG: 'REQUEST_CREATE_TAG',
   CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
   CREATE_TAG_ERROR: 'CREATE_TAG_ERROR',

   REQUEST_ALL_TAGS: 'REQUEST_ALL_TAGS',
   REQUEST_ALL_TAGS_SUCCESS: 'REQUEST_ALL_TAGS_SUCCESS',
   REQUEST_ALL_TAGS_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_TAGS_SUCCESS_WITHOUT_DATA',
   REQUEST_ALL_TAGS_ERROR: 'REQUEST_ALL_TAGS_ERROR',

   REQUEST_SEARCH_TAG: 'REQUEST_SEARCH_TAG',
   SEARCH_TAG_SUCCESS: 'SEARCH_TAG_SUCCESS',
   SEARCH_TAG_ERROR: 'SEARCH_TAG_ERROR',
   SEARCH_TAG_SUCCESS_WITHOUT_DATA: 'SEARCH_TAG_SUCCESS_WITHOUT_DATA',

   REQUEST_DELETE_TAG: 'REQUEST_DELETE_TAG',
   DELETE_TAG_SUCCESS: 'DELETE_TAG_SUCCESS',
   DELETE_TAG_ERROR: 'DELETE_TAG_ERROR',

   REQUEST_UPDATE_TAG: 'REQUEST_UPDATE_TAG',
   UPDATE_TAG_SUCCESS: 'UPDATE_TAG_SUCCESS',
   UPDATE_TAG_ERROR: 'UPDATE_TAG_ERROR',

   GET_ALL_TAGS: 'GET_ALL_TAGS',
   CREATE_TAG: 'CREATE_TAG',
   SEARCH_TAG: 'SEARCH_TAG',

   DELETE_TAG: 'DELETE_TAG',
   UPDATE_TAG: 'UPDATE_TAG',

   TAG_URI: `${appConstants.BASE_URI}${tag}`,
};
