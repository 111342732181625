import React from 'react';
import {useSelector} from 'react-redux';
import {numberWithCommas} from '../../_helpers';

import {ReactComponent as MenuIcon} from '../../_assets/icons/menuOverview.svg';
import {ReactComponent as CustomersIcon} from '../../_assets/icons/customersOverview.svg';
import {ReactComponent as ReservationsIcon} from '../../_assets/icons/reservationsOverview.svg';
import {ReactComponent as OrdersIcon} from '../../_assets/icons/ordersOverview.svg';
import {ReactComponent as SalesIcon} from '../../_assets/icons/salesOverview.svg';
// import {ReactComponent as RevenueIcon} from '../../_assets/icons/revenueOverview.svg';
// import {ReactComponent as SpendingIcon} from '../../_assets/icons/spendingOverview.svg';
// import {ReactComponent as EarningsIcon} from '../../_assets/icons/earningsOverview.svg';

const Overview = () => {
   const dashboardMetrics = useSelector((s) => s.dashboard.dashboardMetrics);

   return (
      <div className="p-4">
         <div className="row m-0 overview p-2">
            <div className="col-md-6 col-12 p-4 pe-0">
               <div className="row m-0 totalOverview borderRight">
                  <p className="total pb-3">Total Overview</p>
                  <div className="mb-4 p-4 first col-6 d-flex align-items-center">
                     <div className="icon">
                        <MenuIcon className="h-100 w-100" />
                     </div>
                     <div className="">
                        <p className="label">Menu</p>
                        <h5 className="">{numberWithCommas(dashboardMetrics?.totalMenu)}</h5>
                     </div>
                  </div>
                  <div className="col-6 mb-4 p-4 d-flex align-items-center">
                     <div className="icon">
                        <CustomersIcon className="h-100 w-100" />
                     </div>
                     <div className="">
                        <p className="label">Posts</p>
                        <h5 className="">{numberWithCommas(dashboardMetrics?.totalPosts)}</h5>
                     </div>
                  </div>
                  <div className="col-6 p-4 d-flex align-items-center">
                     <div className="icon">
                        <ReservationsIcon className="h-100 w-100" />
                     </div>
                     <div className="">
                        <p className="label">Reservations</p>
                        <h5 className="">{numberWithCommas(dashboardMetrics?.totalReservation)}</h5>
                     </div>
                  </div>
                  <div className="col-6 p-4 last d-flex align-items-center">
                     <div className="icon">
                        <OrdersIcon className="h-100 w-100" />
                     </div>
                     <div className="">
                        <p className="label">Orders</p>
                        <h5 className="">{numberWithCommas(dashboardMetrics?.totalOrder)}</h5>
                     </div>
                  </div>
               </div>
            </div>

            <div className="col-md-6 col-12 p-4">
               <div className="totalOverview h-100 d-flex flex-column align-items-center justify-content-center">
                  <p className="total" style={{fontSize: '1.5rem'}}>
                     Sales Overview
                  </p>
                  <div className="p-4 d-flex align-items-center">
                     <div className="icon" style={{width: '4rem', height: '4rem'}}>
                        <SalesIcon className="h-100 w-100" />
                     </div>
                     <div className="">
                        <p className="label" style={{fontSize: '1.1rem'}}>
                           Total Amount
                        </p>
                        <h5 className="" style={{fontSize: '2rem'}}>
                           {numberWithCommas(dashboardMetrics?.totalAmount)}
                        </h5>
                     </div>
                  </div>
                  {/* <div className="col-6 p-4 mb-4 d-flex align-items-center">
                     <div className="icon">
                        <RevenueIcon className="h-100 w-100" />
                     </div>
                     <div className="">
                        <p className="label">Revenue</p>
                        <h5 className="">{numberWithCommas(1375)}</h5>
                     </div>
                  </div>
                  <div className="col-6 p-4 d-flex align-items-center">
                     <div className="icon">
                        <SpendingIcon className="h-100 w-100" />
                     </div>
                     <div className="">
                        <p className="label">Spending</p>
                        <h5 className="">{numberWithCommas(1375)}</h5>
                     </div>
                  </div>
                  <div className="col-6 p-4 last d-flex align-items-center">
                     <div className="icon">
                        <EarningsIcon className="h-100 w-100" />
                     </div>
                     <div className="">
                        <p className="label">Earnings</p>
                        <h5 className="">{numberWithCommas(1375)}</h5>
                     </div>
                  </div> */}
               </div>
            </div>
         </div>
      </div>
   );
};

export default Overview;
