import React from 'react';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {MDBTable, MDBTableBody, MDBTableHead, MDBProgress, MDBProgressBar} from 'mdb-react-ui-kit';
import {Doughnut} from 'react-chartjs-2';
import {numberWithCommas} from '../../_helpers';
import RecentTableRow from './RecentTableRow';
// import {recentOrders} from '../../_constants';

import {ReactComponent as ArrowRightIcon} from '../../_assets/icons/redArrowRight.svg';

const RecentOrders = () => {
   const navigate = useNavigate();
   const dashboardMetrics = useSelector((s) => s.dashboard.dashboardMetrics);
   const recentOrderList = useSelector((s) => s.order.recentOrderList);

   const completedOrdersPercentage = dashboardMetrics?.orderSummary.completedOrdersPercentage.toFixed(2);
   const deliveredPercentage = dashboardMetrics?.orderSummary.deliveredPercentage.toFixed(2);
   const processingOrdersPercentage = dashboardMetrics?.orderSummary.processingOrdersPercentage.toFixed(2);

   const doughnutLabels = ['Delivered', 'Sent', 'Processing'];
   const doughnutData = {
      labels: doughnutLabels,
      datasets: [
         {
            label: '',
            data: [completedOrdersPercentage, deliveredPercentage, processingOrdersPercentage],
            // data: [23, 53, 89],
            backgroundColor: ['#00BC9A', '#E84E1B', '#EE8F01'],
            borderRadius: 3,
            borderWidth: 0,
            hoverOffset: 10,
            borderJoinStyle: 'miter',
            rotation: -90,
            // circumference: 270,
            // spacing: 5,
            offset: 5,
         },
      ],
   };
   const doughnutConfig = {
      type: 'doughnut',
      data: doughnutData,
      options: {
         responsive: true,
         plugins: {
            legend: {
               position: 'bottom',
               color: '#fff',
            },
            title: {
               display: true,
               text: 'Chart',
            },
         },
      },
   };

   const handleOrders = () => {
      navigate('/orders');
   };

   return (
      <div className="row m-0 recent">
         <div className="col-md-6 col-12 p-4 pt-0 pe-3">
            <div className="recentOrders h-100">
               <h5 className="p-4">Recent Orders</h5>
               <MDBTable responsive>
                  <MDBTableHead>
                     <tr>
                        <th>Order ID</th>
                        <th>Customer</th>
                        <th>Amount</th>
                        <th>Status</th>
                     </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                     {recentOrderList?.length > 0 ? (
                        recentOrderList.map((item, i) => {
                           return <RecentTableRow key={i} {...item} data={item} />;
                        })
                     ) : (
                        <td align="center" className="no-content pt-3" colSpan={5}>
                           No order has been made recently
                        </td>
                     )}
                  </MDBTableBody>
               </MDBTable>
            </div>
         </div>

         <div className="col-md-6 col-12 p-4 pt-0 ps-3">
            <div className="orderSummary p-4 h-100">
               <h5 className="">Order Summary</h5>
               <div className="my-4 d-flex align-items-center justify-content-between">
                  <Link to="/orders" className="newOrders">
                     {dashboardMetrics?.orderSummary.newOrder} New{' '}
                     {dashboardMetrics?.orderSummary.newOrder > 1 ? 'Orders' : 'Order'}
                  </Link>
                  <button onClick={handleOrders} className="manageOrder d-flex align-items-center">
                     Manage Order <ArrowRightIcon className="ps-2" style={{transform: 'scale(1)'}} />
                  </button>
               </div>
               <div className="row m-0">
                  <div className="col-md-4">
                     <div className="delivered status p-2">
                        <p className="me-3 px-1">{numberWithCommas(dashboardMetrics?.orderSummary.completedOrders)}</p>
                        <small className="">Delivered</small>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="onDelivery status p-2">
                        <p className="me-3 px-1">{numberWithCommas(dashboardMetrics?.orderSummary.deliveredOrders)}</p>
                        <small className="">Sent</small>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="canceled status p-2">
                        <p className="me-3 px-1">{numberWithCommas(dashboardMetrics?.orderSummary.processingOrders)}</p>
                        <small className="">Processing</small>
                     </div>
                  </div>
               </div>

               <div className="row m-0 mt-3 py-3 chart align-items-center">
                  <div className="col-md-5">
                     <div className="doughnut">
                        <Doughnut data={doughnutData} options={doughnutConfig} />
                     </div>
                  </div>
                  <div className="col-md-7">
                     <div className="delivered d-flex align-items-center">
                        <span className="text-nowrap deliver">Delivered</span>
                        <MDBProgress className="w-100 mx-3">
                           <MDBProgressBar
                              className="bg__delivered"
                              bgColor="#00BC9A"
                              width={completedOrdersPercentage}
                              valuemin={0}
                              valuemax={100}
                           />
                        </MDBProgress>
                        <span className="percent">{completedOrdersPercentage}%</span>
                     </div>
                     <div className="onDelivery my-3 d-flex align-items-center">
                        <span className="text-nowrap deliver">Sent</span>
                        <MDBProgress className="w-100 mx-3">
                           <MDBProgressBar
                              className="bg__onDelivery"
                              bgColor="#EE8F01"
                              width={deliveredPercentage}
                              valuemin={0}
                              valuemax={100}
                           />
                        </MDBProgress>
                        <span className="percent">{deliveredPercentage}%</span>
                     </div>
                     <div className="canceled d-flex align-items-center">
                        <span className="text-nowrap deliver">Processing</span>
                        <MDBProgress className="w-100 mx-3">
                           <MDBProgressBar
                              className="bg__canceled"
                              bgColor="#E84E1B"
                              width={processingOrdersPercentage}
                              valuemin={0}
                              valuemax={100}
                           />
                        </MDBProgress>
                        <span className="percent">{processingOrdersPercentage}%</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default RecentOrders;
