import {combineReducers} from 'redux';
import {reservationConstants} from '../../_constants';
import {updateObject, updateItemInArray} from '../../_helpers';

const IsRequestingAllReservations = (state = false, action) => {
   switch (action.type) {
      case reservationConstants.REQUEST_ALL_RESERVATIONS:
         return true;
      case reservationConstants.REQUEST_ALL_RESERVATIONS_ERROR:
         return false;
      case reservationConstants.REQUEST_ALL_RESERVATIONS_SUCCESS:
         return false;
      case reservationConstants.REQUEST_ALL_RESERVATIONS_SUCCESS_WITHOUT_DATA:
         return false;
      case reservationConstants.REQUEST_FILTER_RESERVATIONS:
         return true;
      case reservationConstants.REQUEST_FILTER_RESERVATIONS_ERROR:
         return false;
      case reservationConstants.REQUEST_FILTER_RESERVATIONS_SUCCESS:
         return false;
      case reservationConstants.REQUEST_FILTER_RESERVATIONS_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsRequestingAReservation = (state = false, action) => {
   switch (action.type) {
      case reservationConstants.REQUEST_A_RESERVATION:
         return true;
      case reservationConstants.REQUEST_A_RESERVATION_ERROR:
         return false;
      case reservationConstants.REQUEST_A_RESERVATION_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsSearchingReservation = (state = false, action) => {
   switch (action.type) {
      case reservationConstants.REQUEST_SEARCH_RESERVATION:
         return true;
      case reservationConstants.SEARCH_RESERVATION_ERROR:
         return false;
      case reservationConstants.SEARCH_RESERVATION_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsUpdatingReservation = (state = false, action) => {
   switch (action.type) {
      case reservationConstants.REQUEST_UPDATE_RESERVATION:
         return true;
      case reservationConstants.UPDATE_RESERVATION_SUCCESS:
         return false;
      case reservationConstants.UPDATE_RESERVATION_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingReservationSuccess = (state = false, action) => {
   switch (action.type) {
      case reservationConstants.REQUEST_UPDATE_RESERVATION:
         return false;
      case reservationConstants.UPDATE_RESERVATION_SUCCESS:
         return true;
      case reservationConstants.UPDATE_RESERVATION_ERROR:
         return false;
      default:
         return state;
   }
};

const reservationDetails = (state = null, {type, reservation}) => {
   switch (type) {
      case reservationConstants.REQUEST_A_RESERVATION_SUCCESS:
         return updateObject(state, reservation.data);
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   state_id: '',
   industry_id: '',
   specialization_id: '',
   closing_date: '',
   isPublished: '',
};

const reservationList = (state = [], action) => {
   switch (action.type) {
      case reservationConstants.REQUEST_ALL_RESERVATIONS_SUCCESS: {
         return action.reservations.data.docs;
      }
      case reservationConstants.SEARCH_RESERVATION_SUCCESS: {
         return action.reservation.data.docs;
      }
      case reservationConstants.REQUEST_FILTER_RESERVATIONS_SUCCESS: {
         return action.reservation.data.docs;
      }
      case reservationConstants.SEARCH_RESERVATION_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case reservationConstants.UPDATE_RESERVATION_SUCCESS: {
         const newList = updateItemInArray(state, action.reservation._id, true, (reservation) => {
            return updateObject(reservation, action.reservation);
         });

         return newList;
      }
      case reservationConstants.DELETE_RESERVATION_SUCCESS: {
         return state.filter((reservation) => reservation._id !== action._id);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case reservationConstants.UPDATE_RESERVATION_ERROR:
         return updateObject(state, error);
      case reservationConstants.REQUEST_UPDATE_RESERVATION:
         return null;
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case reservationConstants.REQUEST_ALL_RESERVATIONS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} = action.reservations.data;
         const result = {hasNextPage, nextPage, totalDocs, totalPages, limit, page};

         return updateObject(state, result);
      }
      case reservationConstants.REQUEST_FILTER_RESERVATIONS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} = action.reservation.data;
         const result = {hasNextPage, nextPage, totalPages, totalDocs, limit, page};

         return updateObject(state, result);
      }
      case reservationConstants.SEARCH_RESERVATION_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} = action.reservation.data;
         const result = {hasNextPage, nextPage, totalPages, totalDocs, limit, page};

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingAllReservations,
   IsRequestingAReservation,
   IsSearchingReservation,
   IsUpdatingReservation,
   IsUpdatingReservationSuccess,
   reservationDetails,
   reservationList,
   errorMessage,
   pagination,
});

export default rootReducer;
