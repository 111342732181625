import React from 'react';
// import {Link} from 'react-router-dom';
// import Formsy from 'formsy-react';
// import {TextInput} from '../../_components';
import './index.scss';

import logo from '../../_assets/images/LTHLogo.png';
import signIn from '../../_assets/images/signIn.jpg';
// import sign1 from '../../_assets/images/sign1.png';
// import sign2 from '../../_assets/images/sign2.png';
// import {ReactComponent as NutsIcon} from '../../_assets/icons/nuts.svg';

const AuthWrapper = ({children}) => {
   // const [showPass, setShowPass] = useState();
   return (
      <div className="container-fluid p-0 login position-relative">
         <div className="container p-0 h-100">
            <div className="logo position-absolute">
               <img src={logo} alt="" className="h-100 w-100" />
            </div>
            <div className="row m-0 h-100">
               <div className="col-md-6 col-12 order-2 order-md-1 px-0 pb-md-0 pb-4 d-flex align-items-center justify-content-center">
                  {children}
               </div>

               <div className="col-md-6 col-12 order-1 order-md-2 px-0 py-4 pt-md-0 pt-5 d-flex align-items-center justify-content-center">
                  <div className="img position-relative">
                     <img src={signIn} alt="" className="h-100 w-100" />
                     <div className="overlay position-absolute h-100 w-100 d-flex align-items-center justify-content-center">
                        <div className="p-4">
                           <p className="welcome">WELCOME TO</p>
                           <h1 className="py-3">LAGOS TEA HOUSE</h1>
                           <div className="desc">
                              We can provide you with the finest and healthy food <br /> that you and your guest will
                              remember.
                           </div>
                        </div>
                     </div>
                     {/* <img src={sign1} alt="" className="small position-absolute" />
                     <img src={sign2} alt="" className="small2 position-absolute" /> */}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AuthWrapper;
