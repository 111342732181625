import React from 'react';
import classNames from 'classnames';
import './index.scss';

import {ReactComponent as Prev} from '../../_assets/icons/arrowLeft.svg';
import {ReactComponent as Next} from '../../_assets/icons/arrowRight.svg';

const Pagination = (props) => {
   const {page, totalPages} = props;

   const handleBackButtonClick = (event) => {
      if (page !== 0) {
         props.onChangePage(event, page - 1);
      }
   };

   const handleNextButtonClick = (event) => {
      if (page + 1 !== totalPages) {
         props.onChangePage(event, page + 1);
      }
   };

   const handlePageClick = (i) => {
      props.onChangePage(i, Number(i.target.innerHTML) - 1);
   };

   const buttons = [];
   const button = [];
   const pages = page + 5;

   for (var i = 0; i < totalPages; i++) {
      buttons.push(
         <li className={classNames('list mx-2 d-flex align-items-center', {active: page === i})}>
            <p onClick={(i) => handlePageClick(i)} className={page === i ? 'active' : ''}>
               {i + 1}
            </p>
         </li>,
      );
   }

   for (var j = page; j < pages; j++) {
      button.push(
         <li className={classNames('list mx-2 d-flex align-items-center', {active: page === j})}>
            <p onClick={(j) => handlePageClick(j)} className={page === j ? 'active' : ''}>
               {j + 1}
            </p>
         </li>,
      );
   }
   return (
      <div className="my-4">
         <ul className="pagination d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
               <li
                  disabled={page === 0}
                  onClick={handleBackButtonClick}
                  className={classNames('prev mx-3 d-flex align-items-center', {disabled: page === 0})}>
                  <Prev className="icon me-2" />
                  Previous
               </li>
               {/* {totalPages > 5 ? button : buttons} */}
               <li
                  onClick={handleNextButtonClick}
                  disabled={page + 1 === totalPages}
                  className={classNames('next mx-3 d-flex align-items-center', {disabled: page + 1 === totalPages})}>
                  Next
                  <Next className="icon ms-2" />
               </li>
            </div>
            <div className="d-flex align-items-center">{totalPages > 5 ? button : buttons}</div>
         </ul>
      </div>
   );
};

export default Pagination;
