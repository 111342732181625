import {setupConstants} from '../../_constants';
import {combineReducers} from 'redux';

const IsRequestingSetUp = (state = true, action) => {
   switch (action.type) {
      case setupConstants.REQUEST_SETUP_DATA:
         return true;
      case setupConstants.REQUEST_SETUP_DATA_SUCCESS:
         return false;
      case setupConstants.REQUEST_SETUP_DATA_ERROR:
         return false;
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingSetUp,
});

export default rootReducer;
