import {combineReducers} from 'redux';
import {categoryConstants, appConstants} from '../../_constants';
import {updateObject, updateItemInArray} from '../../_helpers';

const IsRequestingCategories = (state = false, action) => {
   switch (action.type) {
      case categoryConstants.REQUEST_ALL_CATEGORIES:
         return true;
      case categoryConstants.REQUEST_ALL_CATEGORIES_ERROR:
         return false;
      case categoryConstants.REQUEST_ALL_CATEGORIES_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsCreatingCategory = (state = false, action) => {
   switch (action.type) {
      case categoryConstants.REQUEST_CREATE_CATEGORY:
         return true;
      case categoryConstants.CREATE_CATEGORY_SUCCESS:
         return false;
      case categoryConstants.CREATE_CATEGORY_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingCategory = (state = false, action) => {
   switch (action.type) {
      case categoryConstants.REQUEST_UPDATE_CATEGORY:
         return true;
      case categoryConstants.UPDATE_CATEGORY_SUCCESS:
         return false;
      case categoryConstants.UPDATE_CATEGORY_ERROR:
         return false;
      default:
         return state;
   }
};

const categoryCreatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case categoryConstants.REQUEST_CREATE_CATEGORY:
         return false;
      case categoryConstants.CREATE_CATEGORY_SUCCESS:
         return true;
      case categoryConstants.CREATE_CATEGORY_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const categoryUpdatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case categoryConstants.REQUEST_UPDATE_CATEGORY:
         return false;
      case categoryConstants.UPDATE_CATEGORY_SUCCESS:
         return true;
      case categoryConstants.UPDATE_CATEGORY_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const IsSearchingCategory = (state = false, action) => {
   switch (action.type) {
      case categoryConstants.REQUEST_SEARCH_CATEGORY:
         return true;
      case categoryConstants.SEARCH_CATEGORY_ERROR:
         return false;
      case categoryConstants.SEARCH_CATEGORY_SUCCESS:
         return false;
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   image_uri: '',
   number: '',
   published: '',
};

const categoriesList = (state = [], action) => {
   switch (action.type) {
      case categoryConstants.REQUEST_ALL_CATEGORIES_SUCCESS: {
         return action.categories.data;
      }
      case categoryConstants.REQUEST_ALL_CATEGORIES_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case categoryConstants.REQUEST_ALL_CATEGORIES_WITHOUT_PAGINATION_SUCCESS: {
         return action.categories;
      }
      case categoryConstants.SEARCH_CATEGORY_SUCCESS: {
         return action.category.data.docs;
      }
      case categoryConstants.SEARCH_CATEGORY_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case categoryConstants.CREATE_CATEGORY_SUCCESS: {
         const newList = [...state, action.category.data];

         return newList;
      }
      // case categoryConstants.CREATE_CATEGORY_SUCCESS: {
      //    const newList = [...state, action.category.data];
      //    const obj_arr_appended = newList.map(function (currentValue, Index) {
      //       currentValue.serialNo = Index + 1;
      //       return currentValue;
      //    });

      //    return obj_arr_appended;
      // }
      case categoryConstants.UPDATE_CATEGORY_SUCCESS: {
         // const newList = updateItemInArray(state, action.category.data.id, (category) => {
         //    return updateObject(category, action.category.data);
         // });
         const newList = updateItemInArray(state, action.category.data._id, true, (category) => {
            return updateObject(category, action.category.data);
         });

         return newList;
      }
      case categoryConstants.DELETE_CATEGORY_SUCCESS: {
         return state.filter((category) => category._id !== action._id);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case categoryConstants.CREATE_CATEGORY_ERROR:
         return updateObject(state, error);
      case categoryConstants.REQUEST_CREATE_CATEGORY:
         return null;
      case categoryConstants.UPDATE_CATEGORY_ERROR:
         return updateObject(state, error);
      case categoryConstants.REQUEST_UPDATE_CATEGORY:
         return null;
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      // case categoryConstants.REQUEST_ALL_CATEGORIES_SUCCESS: {
      //    const {hasNextPage, nextPage, totalDocs, limit, page} = action.categories.data;
      //    const result = {
      //       hasNextPage,
      //       nextPage,
      //       totalDocs,
      //       limit,
      //       page,
      //    };

      //    return updateObject(state, result);
      // }
      // case categoryConstants.CREATE_CATEGORY_SUCCESS: {
      //    console.log('state', state);
      //    const {hasNextPage, nextPage, totalDocs, limit, page} = state;
      //    const TotalDocs = totalDocs + 1;
      //    const result = {hasNextPage, nextPage, TotalDocs, limit, page};
      //    console.log('result', result);

      //    return updateObject(state, result);
      // }
      // case categoryConstants.DELETE_CATEGORY_SUCCESS: {
      //    console.log('state', state);
      //    const {hasNextPage, nextPage, totalDocs, limit, page} = state;
      //    const TotalDocs = totalDocs - 1;
      //    const result = {hasNextPage, nextPage, TotalDocs, limit, page};
      //    console.log('result', result);

      //    return updateObject(state, result);
      // }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   categoriesList,
   IsRequestingCategories,
   IsCreatingCategory,
   IsUpdatingCategory,
   categoryCreatedSuccessfully,
   categoryUpdatedSuccessfully,
   IsSearchingCategory,
   errorMessage,
   pagination,
});

export default rootReducer;
