import {all} from 'redux-saga/effects';
import authenticationRootSaga from './auth.saga';
import blogRootSaga from './blog.saga';
import categoryRootSaga from './category.saga';
import customerRootSaga from './customer.saga';
import dashboardRootSaga from './dashboard.saga';
import forgotPasswordRootSaga from './forgotPassword.saga';
import menuRootSaga from './menu.saga';
import orderRootSaga from './order.saga';
import paymentRootSaga from './payment.saga';
import reservationRootSaga from './reservation.saga';
import setupRootSaga from './setup.saga';
import tagRootSaga from './tag.saga';
import userRootSaga from './user.saga';

export default function* rootSaga() {
   yield all([
      authenticationRootSaga(),
      blogRootSaga(),
      categoryRootSaga(),
      customerRootSaga(),
      dashboardRootSaga(),
      forgotPasswordRootSaga(),
      menuRootSaga(),
      orderRootSaga(),
      paymentRootSaga(),
      reservationRootSaga(),
      setupRootSaga(),
      tagRootSaga(),
      userRootSaga(),
   ]);
}
