import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {orderConstants, appConstants, userConstants} from '../../_constants';
import {
   createRequest,
   getObjectFromStorage,
   clearObjectFromStorage,
   checkStatus,
   createRequestWithToken,
} from '../../_helpers';

function* getAllOrders({data}) {
   yield put({type: orderConstants.REQUEST_ALL_ORDERS});
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);
      let orderUri = `${orderConstants.ORDER_URI}`;
      if (data?.page) {
         orderUri = `${orderUri}?page=${data.page + 1}`;
      }
      const req = createRequestWithToken(orderUri, {method: 'GET'})(user.accessToken);

      const response = yield call(fetch, req);

      if (response?.status === 403) {
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.TOKEN_HAS_EXPIRED});
         return;
      }

      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: orderConstants.REQUEST_ALL_ORDERS_SUCCESS, orders: jsonResponse});
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({type: orderConstants.REQUEST_ALL_ORDERS_ERROR});
   }
}

function* getRecentOrders() {
   yield put({type: orderConstants.REQUEST_RECENT_ORDERS});
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);
      let orderUri = `${orderConstants.ORDER_URI}?type=4`;

      const req = createRequestWithToken(orderUri, {method: 'GET'})(user.accessToken);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: orderConstants.REQUEST_RECENT_ORDERS_SUCCESS, recentOrders: jsonResponse});
   } catch (error) {
      yield put({type: orderConstants.REQUEST_RECENT_ORDERS_ERROR});
   }
}

function* getAnOrder({data}) {
   yield put({type: orderConstants.REQUEST_AN_ORDER});

   try {
      let orderUri = `${appConstants.BASE_URI}${orderConstants.ORDER}/search`;

      const orderReq = yield call(createRequest, orderUri, {method: 'POST', body: JSON.stringify(data)});

      const response = yield call(fetch, orderReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({type: orderConstants.REQUEST_AN_ORDER_SUCCESS, order: jsonResponse});
   } catch (error) {
      yield put({type: orderConstants.REQUEST_AN_ORDER_ERROR});
   }
}

function* updateOrderSaga({data}) {
   try {
      yield put({type: orderConstants.REQUEST_UPDATE_ORDER});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${orderConstants.ORDER_URI}/${data._id}`;
      const req = createRequestWithToken(url, {method: 'PATCH', body: JSON.stringify(data)})(user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));
      yield put({type: orderConstants.UPDATE_ORDER_SUCCESS, order: jsonResponse && jsonResponse.data});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Order successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({type: orderConstants.UPDATE_ORDER_ERROR, error: errorMessage});
      yield put(
         appActions.setSnackBar({
            message: errorMessage ? errorMessage?.message : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* searchOrder({data}) {
   yield put({type: orderConstants.REQUEST_SEARCH_ORDER});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      let orderUri = `${orderConstants.ORDER_URI}/search?query=${data.searchInput}`;

      const orderReq = createRequestWithToken(orderUri, {method: 'GET'})(user?.accessToken);

      const response = yield call(fetch, orderReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({type: orderConstants.SEARCH_ORDER_SUCCESS_WITHOUT_DATA});

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: orderConstants.SEARCH_ORDER_SUCCESS, order: jsonResponse});
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({type: orderConstants.SEARCH_ORDER_ERROR, error: errorMessage});
   }
}

function* deleteOrderSaga({_id}) {
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      yield put({type: orderConstants.REQUEST_DELETE_ORDER});

      const url = `${orderConstants.ORDER_URI}/${_id}`;
      const req = createRequestWithToken(url, {method: 'DELETE', body: JSON.stringify({_id: _id})})(user && user.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: orderConstants.DELETE_ORDER_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'ORDER successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({type: orderConstants.DELETE_ORDER_ERROR, error: errorMessage});
      yield put(appActions.setSnackBar({message: 'Something went wrong', variant: 'error'}));
   }
}

function* getAllOrdersWatcher() {
   yield takeLatest(orderConstants.GET_ALL_ORDERS, getAllOrders);
}

function* getRecentOrdersWatcher() {
   yield takeLatest(orderConstants.GET_RECENT_ORDERS, getRecentOrders);
}

function* getAnOrderWatcher() {
   yield takeLatest(orderConstants.GET_AN_ORDER, getAnOrder);
}

function* updateOrderWatcher() {
   yield takeLatest(orderConstants.UPDATE_ORDER, updateOrderSaga);
}

function* searchOrderWatcher() {
   yield takeLatest(orderConstants.SEARCH_ORDER, searchOrder);
}

function* deleteOrderWatcher() {
   yield takeLatest(orderConstants.DELETE_ORDER, deleteOrderSaga);
}

export default function* rootSaga() {
   yield all([
      getAllOrdersWatcher(),
      getRecentOrdersWatcher(),
      getAnOrderWatcher(),
      updateOrderWatcher(),
      searchOrderWatcher(),
      deleteOrderWatcher(),
   ]);
}
