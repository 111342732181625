import {createStore, applyMiddleware} from 'redux';
import rootReducer from '../_redux/reducers';
import createSagaMiddleware from 'redux-saga';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootSaga from '../_redux/sagas';
import {userConstants} from '../_constants';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

store.dispatch({type: userConstants.GET_USER});

export default store;
