import React, {useRef} from 'react';
import {useLocationCode} from '../../_helpers';
// import {TextInput} from '../../_components';
// import Formsy from 'formsy-react';
import './index.scss';

// import profileImg from '../../_assets/images/profileImg.jpg';
// import {ReactComponent as BellIcon} from '../../_assets/icons/bell.svg';
// import {ReactComponent as SearchIcon} from '../../_assets/icons/search.svg';
import {ReactComponent as Avatar} from '../../_assets/icons/avatar.svg';
import ModalCenter from '../../_controllers/ModalCenter';
import {useMediaQuery} from 'react-responsive';
import {ReactComponent as BurgerMenu} from '../../_assets/icons/burger-menu.svg';

const getLocationName = (num) => {
   switch (num) {
      case 1:
         return 'Dashboard';
      case 2:
         return 'Orders';
      case 3:
         return 'Menu';
      case 4:
         return 'Reservations';
      case 5:
         return 'Customers';
      case 6:
         return 'Categories';
      case 7:
         return 'Payments';
      case 8:
         return 'Tags';
      case 9:
         return 'Blogs';
      case 10:
         return 'Settings';
      default:
         return '';
   }
};

const Header = ({user}) => {
   const code = useLocationCode();
   const view1090 = useMediaQuery({query: '(max-width: 1090px)'});

   return code !== 11 ? (
      <div className="header px-md-4 px-3 d-flex align-items-center justify-content-center">
         <div className="content w-100 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
               {view1090 && <BurgerMenu className="menu__icon me-4" onClick={ModalCenter.openModal} />}
               <h5 className="pageName">{getLocationName(code)}</h5>
            </div>
            <div className="search">
               {/* <Formsy>
                  <TextInput
                     id="search"
                     type="text"
                     name="search"
                     placeholder="Search"
                     className=""
                     leftIcon={
                        <div className="icon__Wrapper position-absolute">
                           <SearchIcon className="w-100 h-100" />
                        </div>
                     }
                  />
               </Formsy> */}
            </div>

            <div className="pill d-flex align-items-center">
               {/* <div className="notification position-relative d-flex align-items-center justify-content-center">
                  <BellIcon className="w-100 h-100" />
                  <div className="new position-absolute"></div>
               </div> */}
               <div className="profile d-flex align-items-center">
                  <p className="username me-3 text-nowrap text-capitalize">{user?.firstname + ' ' + user?.lastname}</p>
                  <div className="img">
                     <Avatar className="w-100 h-100" />
                     {/* <img src={profileImg} alt="" className="w-100 h-100" /> */}
                  </div>
               </div>
            </div>
         </div>
      </div>
   ) : null;
};

export default Header;
