import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import Formsy from 'formsy-react';
import {AddTag} from '../../_components';
import TagRow from './TagRow';
import Loader from '../../_helpers/loader';
import {Pagination, TextInput} from '../../_components';
import './index.scss';
import {tagActions} from '../../_actions';
import {ReactComponent as SearchIcon} from '../../_assets/icons/search.svg';

const Tags = () => {
   const dispatch = useDispatch();

   const {IsRequestingTags, IsSearchingTag, tagsList, pagination} = useSelector((s) => s.tag);

   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   // const [TagType, setTagType] = useState(newArr?.[0]?.contents);
   const [addTagModal, setAddTagModal] = useState(false);
   useEffect(() => {
      dispatch(tagActions.getAllTags());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(tagActions.getAllTags(model));
   };

   const handleAddTag = () => {
      setAddTagModal(true);
   };

   const searchTag = (data) => {
      if (data.searchInput === '') {
         dispatch(tagActions.getAllTags());
      } else {
         dispatch(tagActions.searchTag(data));
      }
   };

   return (
      <div className="p-4">
         <div className="tag">
            <div className=" p-4 d-flex align-items-center justify-content-between">
               {/* <Select
                  className="text-capitalize"
                  // name={`${newArr?.[0]?.label}`}
                  // placeholder={`${newArr?.[0]?.label}`}
                  values={newArr.find((opt) => opt.value === String(newArr?.[0]?.label))}
                  options={newArr}
                  // onChange={(values) => setTagType(values?.[0]?.contents)}
                  onChange={(values) => setCategory(values)}
               /> */}
               <div className="search">
                  <Formsy onValidSubmit={searchTag}>
                     <TextInput
                        id="search"
                        type="text"
                        name="searchInput"
                        placeholder="Search"
                        className=""
                        leftIcon={
                           <div className="icon__Wrapper position-absolute">
                              <SearchIcon className="w-100 h-100" />
                           </div>
                        }
                     />
                  </Formsy>
               </div>
               <button onClick={handleAddTag} className="addTag">
                  Add Tag
               </button>
               <AddTag modalOpen={addTagModal} type="add" setModalOpen={setAddTagModal} />
            </div>
            {IsRequestingTags || IsSearchingTag ? (
               <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                  <div />
               </Loader>
            ) : (
               <MDBTable responsive>
                  <MDBTableHead>
                     <tr>
                        <th>Name</th>
                        {/* <th>Action</th> */}
                        <th></th>
                     </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                     {tagsList?.length > 0 ? (
                        tagsList.map((item, i) => {
                           return <TagRow key={i} {...item} data={item} />;
                        })
                     ) : (
                        <td align="center" className="no-content" colSpan={5}>
                           No Tag has been uploaded on this platform
                        </td>
                     )}
                  </MDBTableBody>
               </MDBTable>
            )}
         </div>
         {!IsRequestingTags && totalPages > 1 && (
            <Pagination
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
         {/* <Pagination /> */}
      </div>
   );
};

export default Tags;
