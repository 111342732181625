import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import Formsy from 'formsy-react';
import OrderTableRow from './OrderTableRow';
import {TextInput, Pagination} from '../../_components';
import Loader from '../../_helpers/loader';
import './index.scss';
import {orderActions} from '../../_actions';
import {ReactComponent as SearchIcon} from '../../_assets/icons/search.svg';

const Orders = () => {
   const dispatch = useDispatch();

   const {IsRequestingAllOrders, orderList, pagination} = useSelector((s) => s.order);

   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   useEffect(() => {
      dispatch(orderActions.getAllOrders());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(orderActions.getAllOrders(model));
   };

   const searchOrder = (data) => {
      if (data.searchInput === '') {
         dispatch(orderActions.getAllOrders());
      } else {
         dispatch(orderActions.searchOrder(data));
      }
   };

   return (
      <div className="p-4">
         <div className="orders">
            <div className="p-0 d-flex align-items-center justify-content-between">
               <h5 className="p-4">Orders</h5>
               <div className="search">
                  <Formsy onValidSubmit={searchOrder}>
                     <TextInput
                        id="search"
                        type="text"
                        name="searchInput"
                        placeholder="Search by ID"
                        className=""
                        leftIcon={
                           <div className="icon__Wrapper position-absolute">
                              <SearchIcon className="w-100 h-100" />
                           </div>
                        }
                     />
                  </Formsy>
               </div>
            </div>

            {IsRequestingAllOrders ? (
               <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                  <div />
               </Loader>
            ) : (
               <MDBTable responsive>
                  <MDBTableHead>
                     <tr>
                        {/* <th className="text-nowrap">Meal</th> */}
                        <th className="text-nowrap">Order ID</th>
                        <th className="text-nowrap">Customer</th>
                        <th className="text-nowrap">Amount</th>
                        <th className="text-nowrap">Location</th>
                        <th className="text-nowrap">Date</th>
                        <th className="text-nowrap">Status Order</th>
                        {/* <th className="text-nowrap"></th> */}
                     </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                     {orderList?.length > 0 ? (
                        orderList?.map((item) => <OrderTableRow data={item} {...item} />)
                     ) : (
                        <td align="center" className="no-content" colSpan={5}>
                           No order has been made yet.
                        </td>
                     )}
                  </MDBTableBody>
               </MDBTable>
            )}
         </div>
         {!IsRequestingAllOrders && totalPages > 1 && (
            <Pagination
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </div>
   );
};

export default Orders;
