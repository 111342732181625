import {combineReducers} from 'redux';
import {tagConstants, appConstants} from '../../_constants';
import {updateObject, updateItemInArray} from '../../_helpers';

const IsRequestingTags = (state = false, action) => {
   switch (action.type) {
      case tagConstants.REQUEST_ALL_TAGS:
         return true;
      case tagConstants.REQUEST_ALL_TAGS_ERROR:
         return false;
      case tagConstants.REQUEST_ALL_TAGS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsCreatingTag = (state = false, action) => {
   switch (action.type) {
      case tagConstants.REQUEST_CREATE_TAG:
         return true;
      case tagConstants.CREATE_TAG_SUCCESS:
         return false;
      case tagConstants.CREATE_TAG_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingTag = (state = false, action) => {
   switch (action.type) {
      case tagConstants.REQUEST_UPDATE_TAG:
         return true;
      case tagConstants.UPDATE_TAG_SUCCESS:
         return false;
      case tagConstants.UPDATE_TAG_ERROR:
         return false;
      default:
         return state;
   }
};

const tagCreatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case tagConstants.REQUEST_CREATE_TAG:
         return false;
      case tagConstants.CREATE_TAG_SUCCESS:
         return true;
      case tagConstants.CREATE_TAG_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const tagUpdatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case tagConstants.REQUEST_UPDATE_TAG:
         return false;
      case tagConstants.UPDATE_TAG_SUCCESS:
         return true;
      case tagConstants.UPDATE_TAG_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const IsSearchingTag = (state = false, action) => {
   switch (action.type) {
      case tagConstants.REQUEST_SEARCH_TAG:
         return true;
      case tagConstants.SEARCH_TAG_ERROR:
         return false;
      case tagConstants.SEARCH_TAG_SUCCESS:
         return false;
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   image_uri: '',
   number: '',
   published: '',
};

const tagsList = (state = [], action) => {
   switch (action.type) {
      case tagConstants.REQUEST_ALL_TAGS_SUCCESS: {
         return action.tags.data;
      }
      case tagConstants.SEARCH_TAG_SUCCESS: {
         return action.tag.data.docs;
      }
      case tagConstants.SEARCH_TAG_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case tagConstants.REQUEST_ALL_TAGS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case tagConstants.REQUEST_ALL_TAGS_WITHOUT_PAGINATION_SUCCESS: {
         return action.tags;
      }
      case tagConstants.CREATE_TAG_SUCCESS: {
         const newList = [...state, action.tag.data];

         return newList;
      }
      case tagConstants.UPDATE_TAG_SUCCESS: {
         const newList = updateItemInArray(state, action.tag.data._id, true, (tag) => {
            return updateObject(tag, action.tag.data);
         });

         return newList;
      }
      case tagConstants.DELETE_TAG_SUCCESS: {
         return state.filter((tag) => tag._id !== action._id);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case tagConstants.CREATE_TAG_ERROR:
         return updateObject(state, error);
      case tagConstants.REQUEST_CREATE_TAG:
         return null;
      case tagConstants.UPDATE_TAG_ERROR:
         return updateObject(state, error);
      case tagConstants.REQUEST_UPDATE_TAG:
         return null;
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      // case tagConstants.REQUEST_ALL_TAGS_SUCCESS: {
      //    const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} = action.tags.data;
      //    const result = {
      //       hasNextPage,
      //       nextPage,
      //       totalDocs,
      //       totalPages,
      //       limit,
      //       page,
      //    };

      //    return updateObject(state, result);
      // }
      // case tagConstants.CREATE_TAG_SUCCESS: {
      //    console.log('state', state);
      //    const {hasNextPage, nextPage, totalDocs, limit, page} = state;
      //    const TotalDocs = totalDocs + 1;
      //    const result = {hasNextPage, nextPage, TotalDocs, limit, page};
      //    console.log('result', result);

      //    return updateObject(state, result);
      // }
      // case tagConstants.DELETE_TAG_SUCCESS: {
      //    console.log('state', state);
      //    const {hasNextPage, nextPage, totalDocs, limit, page} = state;
      //    const TotalDocs = totalDocs - 1;
      //    const result = {hasNextPage, nextPage, TotalDocs, limit, page};
      //    console.log('result', result);

      //    return updateObject(state, result);
      // }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   tagsList,
   IsRequestingTags,
   IsCreatingTag,
   IsUpdatingTag,
   tagCreatedSuccessfully,
   tagUpdatedSuccessfully,
   IsSearchingTag,
   errorMessage,
   pagination,
});

export default rootReducer;
