import {call, put, takeLatest, all} from 'redux-saga/effects';
import {customerConstants, userConstants} from '../../_constants';
import {appActions} from '../../_actions';
import {createRequestWithToken, checkStatus, getObjectFromStorage} from '../../_helpers';

function* getAllCustomers({data}) {
   yield put({type: customerConstants.REQUEST_ALL_CUSTOMERS});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      let allCustomersUri = `${customerConstants.ALL_CUSTOMERS_URI}?role=3`;

      if (data?.page) {
         allCustomersUri = `${allCustomersUri}&page=${data.page + 1}`;
      }

      const req = createRequestWithToken(allCustomersUri, {
         method: 'GET',
      })(user?.accessToken);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: customerConstants.REQUEST_ALL_CUSTOMERS_SUCCESS,
         allCustomers: jsonResponse,
      });
   } catch (error) {
      yield put({type: customerConstants.REQUEST_ALL_CUSTOMERS_ERROR});
   }
}

function* getCustomerDetails({data}) {
   yield put({type: customerConstants.REQUEST_CUSTOMER_DETAILS});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const detailsUrl = `${userConstants.GET_USER_URI}/${data}/info`;

      const req = createRequestWithToken(detailsUrl, {method: 'GET'})(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response, customerConstants.CUSTOMER_KEY);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: customerConstants.REQUEST_CUSTOMER_DETAILS_SUCCESS,
         customerDetails: jsonResponse,
      });
   } catch (error) {
      yield put({type: customerConstants.REQUEST_CUSTOMER_DETAILS_ERROR});
   }
}

function* getCustomerTransactionHistory({data}) {
   yield put({type: customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      let customerTransactionsUri = `${customerConstants.CUSTOMER_TRANSACTIONS_URI}?type=wallet`;

      if (data?.page) {
         customerTransactionsUri = `${customerTransactionsUri}?page=${data.page + 1}`;
      }

      // const req = createRequestWithToken(customerTransactionsUri, {
      //    method: 'GET',
      // })(user?.token);
      const req = createRequestWithToken(customerTransactionsUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_SUCCESS,
         customerTransactions: jsonResponse,
      });
   } catch (error) {
      yield put({
         type: customerConstants.REQUEST_CUSTOMER_TRANSACTION_HISTORY_ERROR,
      });
   }
}

function* changeUserStatus({data}) {
   yield put({type: customerConstants.REQUEST_CHANGE_USER_STATUS});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${customerConstants.CHANGE_USER_STATUS_URI}/${data._id}`;

      const req = createRequestWithToken(url, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(user?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      //const jsonResponse = yield call(response.json.bind(response))

      yield put({
         type: customerConstants.CHANGE_USER_STATUS_SUCCESS,
         userDetails: data,
      });

      yield put(
         appActions.setSnackBar({
            message: 'status successfully changed',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: customerConstants.CHANGE_USER_STATUS_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message ? errorMessage.message : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* searchCustomer({data}) {
   yield put({type: customerConstants.REQUEST_SEARCH_CUSTOMER});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const customerUri = `${customerConstants.ALL_CUSTOMERS_URI}/search?query=${data.searchInput}`;

      const customerReq = createRequestWithToken(customerUri, {method: 'GET'})(user?.accessToken);

      const response = yield call(fetch, customerReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: customerConstants.SEARCH_CUSTOMER_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type:
            data.type === 'registered'
               ? customerConstants.SEARCH_REGISTERED_CUSTOMER_SUCCESS
               : customerConstants.SEARCH_LOAN_CUSTOMER_SUCCESS,
         customer: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: customerConstants.SEARCH_CUSTOMER_ERROR,
         error: errorMessage,
      });
   }
}

function* getAllCustomersSagaWatcher() {
   yield takeLatest(customerConstants.GET_ALL_CUSTOMERS, getAllCustomers);
}

function* getCustomerDetailsSagaWatcher() {
   yield takeLatest(customerConstants.GET_CUSTOMER_DETAILS, getCustomerDetails);
}

function* getCustomerTransactionHistorySagaWatcher() {
   yield takeLatest(customerConstants.GET_CUSTOMER_TRANSACTION_HISTORY, getCustomerTransactionHistory);
}

function* changeUserStatusSagaWatcher() {
   yield takeLatest(customerConstants.CHANGE_USER_STATUS, changeUserStatus);
}

function* searchCustomerWatcher() {
   yield takeLatest(customerConstants.SEARCH_CUSTOMER, searchCustomer);
}

export default function* rootSaga() {
   yield all([
      getAllCustomersSagaWatcher(),
      getCustomerDetailsSagaWatcher(),
      getCustomerTransactionHistorySagaWatcher(),
      changeUserStatusSagaWatcher(),
      searchCustomerWatcher(),
   ]);
}
