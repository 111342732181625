import React, {useState} from 'react';
import StarRating from 'react-svg-star-rating';
import {useDispatch} from 'react-redux';
// import {ReplyComment} from '../../_components';
import LetteredAvatar from 'lettered-avatar';
import Dialog from '../../_components/Modal/Group/Dialog';
import {readableDateString} from '../../_helpers';

import {ReactComponent as DeleteIcon} from '../../_assets/icons/delete.svg';
// import {ReactComponent as ReplyIcon} from '../../_assets/icons/replyComments.svg';
import {blogActions} from '../../_actions';

const BlogViewRow = ({data, _id, img, blogId, name, comment, rating, date}) => {
   const dispatch = useDispatch();
   // const [starRating, setStarRating] = useState(rating);
   // const [replyComment, setReplyComment] = useState(null);
   // const [replyCommentModal, setReplyCommentModal] = useState(false);
   const [deleteComment, setDeleteComment] = useState(false);

   const toggleDelete = (row) => {
      // setCategoryData(row);
      setDeleteComment(!deleteComment);
   };

   const handleDelete = () => {
      if (_id) {
         const model = {
            _id: _id,
            blogId: blogId,
         };

         dispatch(blogActions.deleteBlogComment(model));
      }
      setDeleteComment(false);
   };

   // const handleStarClick = (rating) => {
   //    setStarRating(rating);
   // };
   // const handleReplyComment = (itm) => {
   //    setReplyCommentModal(true);
   //    setReplyComment(itm);
   // };

   return (
      <tr key={`-${_id}`} className="d-table-row">
         <th scope="col" className="d-flex align-items-center pe-0">
            <div className="img me-2 d-flex align-items-center justify-content-center">
               {img ? (
                  <img src={img} alt="" className="h-100 w-100" />
               ) : (
                  <LetteredAvatar
                     name={name}
                     options={{
                        size: 100,
                        twoLetter: false,
                        shape: 'square',
                        bgColor: '#595959',
                        imgClass: 'img-fluid h-100 w-100 user-image',
                     }}
                  />
               )}
            </div>
            <p className="mealName text-truncate">{name}</p>
         </th>
         <td className="align-middle">{comment}</td>
         <td className="align-middle">
            <p className="d-flex text-nowrap align-items-center">
               <span className="me-1">{rating}</span>
               <StarRating
                  unit="half"
                  size={15}
                  count={5}
                  initialRating={rating}
                  activeColor="#FFC403"
                  hoverColor="#FFC403"
                  emptyColor="rgba(0, 0, 0, .3)"
                  roundedCorner={true}
                  // handleOnClick={handleStarClick}
                  isReadOnly={true}
                  innerRadius={20}
                  outerRadius={45}
                  starClassName="each-star"
                  containerClassName=""
               />
            </p>
         </td>
         <td className="align-middle text-nowrap">{readableDateString(date)}</td>
         <td className="align-middle">
            <div className="d-flex align-items-center">
               <DeleteIcon
                  onClick={() => toggleDelete(data)}
                  className="me-3"
                  style={{transform: 'scale(.7)', cursor: 'pointer'}}
               />
               <Dialog
                  modalOpen={deleteComment}
                  title="Blog Comment"
                  handleDelete={handleDelete}
                  setModalOpen={setDeleteComment}
               />
               {/* <ReplyIcon
                  className=""
                  onClick={() => handleReplyComment(data)}
                  style={{transform: 'scale(.7)', cursor: 'pointer'}}
               />
               <ReplyComment data={replyComment} modalOpen={replyCommentModal} setModalOpen={setReplyCommentModal} /> */}
            </div>
         </td>
      </tr>
   );
};

export default BlogViewRow;
