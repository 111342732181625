import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {categoryConstants, userConstants} from '../../_constants';
import {
   getObjectFromStorage,
   checkStatus,
   createRequestWithToken,
   createMultiPartRequestWithToken,
   clearObjectFromStorage,
} from '../../_helpers';

function* getCategories({data}) {
   yield put({type: categoryConstants.REQUEST_ALL_CATEGORIES});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);
      let categoriesUri = `${categoryConstants.CATEGORY_URI}`;

      if (data?.page) {
         categoriesUri = `${categoriesUri}?page=${data.page + 1}`;
      }
      if (data?.pagination) {
         categoriesUri = `${categoryConstants.CATEGORY_URI}get_all`;
      }

      const categoriesReq = createRequestWithToken(categoriesUri, {
         method: 'GET',
      })(user?.accessToken);

      const response = yield call(fetch, categoriesReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: categoryConstants.REQUEST_ALL_CATEGORIES_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: data?.pagination
            ? categoryConstants.REQUEST_ALL_CATEGORIES_WITHOUT_PAGINATION_SUCCESS
            : categoryConstants.REQUEST_ALL_CATEGORIES_SUCCESS,
         categories: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({type: categoryConstants.REQUEST_ALL_CATEGORIES_ERROR});
   }
}

function* createCategorySaga({data}) {
   try {
      yield put({type: categoryConstants.REQUEST_CREATE_CATEGORY});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${categoryConstants.CATEGORY_URI}`;

      const curriedReq = yield call(createMultiPartRequestWithToken, url, {
         method: 'POST',
         body: data,
      });
      const req = yield call(curriedReq, user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: categoryConstants.CREATE_CATEGORY_SUCCESS,
         category: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Category successfully posted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({
         type: categoryConstants.CREATE_CATEGORY_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* updateCategorySaga({data}) {
   try {
      yield put({type: categoryConstants.REQUEST_UPDATE_CATEGORY});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${categoryConstants.CATEGORY_URI}/${data._id}`;
      // const req = createRequestWithToken(url, {
      //    method: 'PATCH',
      //    body: JSON.stringify(data),
      // })(user?.access_token);
      const curriedReq = yield call(createMultiPartRequestWithToken, url, {
         method: 'PATCH',
         body: data.fields,
      });
      const req = yield call(curriedReq, user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: categoryConstants.UPDATE_CATEGORY_SUCCESS,
         category: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Category successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({
         type: categoryConstants.UPDATE_CATEGORY_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* deleteCategorySaga({_id}) {
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      yield put({type: categoryConstants.REQUEST_DELETE_CATEGORY});

      const url = `${categoryConstants.CATEGORY_URI}/${_id}`;
      const req = createRequestWithToken(url, {
         method: 'DELETE',
      })(user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: categoryConstants.DELETE_CATEGORY_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Category successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({
         type: categoryConstants.DELETE_CATEGORY_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* searchCategorySaga({data}) {
   try {
      yield put({type: categoryConstants.REQUEST_SEARCH_CATEGORY});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const categoryUri = `${categoryConstants.CATEGORY_URI}/search?query=${data.searchInput}`;

      const categoryReq = createRequestWithToken(categoryUri, {method: 'GET'})(user?.accessToken);

      const response = yield call(fetch, categoryReq);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: categoryConstants.SEARCH_CATEGORY_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: categoryConstants.SEARCH_CATEGORY_SUCCESS,
         category: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({
         type: categoryConstants.SEARCH_CATEGORY_ERROR,
         error: errorMessage,
      });
   }
}
function* getCategoriesWatcher() {
   yield takeLatest(categoryConstants.GET_ALL_CATEGORIES, getCategories);
}

function* createCategoryWatcher() {
   yield takeLatest(categoryConstants.CREATE_CATEGORY, createCategorySaga);
}

function* updateCategoryWatcher() {
   yield takeLatest(categoryConstants.UPDATE_CATEGORY, updateCategorySaga);
}

function* deleteCategoryWatcher() {
   yield takeLatest(categoryConstants.DELETE_CATEGORY, deleteCategorySaga);
}

function* searchCategoryWatcher() {
   yield takeLatest(categoryConstants.SEARCH_CATEGORY, searchCategorySaga);
}

export default function* rootSaga() {
   yield all([
      getCategoriesWatcher(),
      createCategoryWatcher(),
      updateCategoryWatcher(),
      deleteCategoryWatcher(),
      searchCategoryWatcher(),
   ]);
}
