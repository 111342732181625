import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {OrderSummary, Profile} from '../../_components';
// import OrderSummary from './OrderSummary';
import History from './History';
// import Map from './Map';
import './index.scss';

import {ReactComponent as Prev} from '../../_assets/icons/arrowLeft.svg';

const OrderView = () => {
   const {state} = useLocation();
   const orderDetails = state.orderDetails;
   const navigate = useNavigate();
   const [startDelivery, setStartDelivery] = useState(false);
   const history = orderDetails?.history;

   const handleGoBack = () => {
      navigate(-1);
   };

   return (
      <div className="p-4">
         <div className="OrderView">
            <div className="back mb-4 ps-2">
               <button onClick={handleGoBack} className="d-inline-flex align-items-center">
                  <Prev className="icon me-2" cursor="pointer" />
                  Back
               </button>
            </div>

            <div className="row m-0">
               <div className="OrderDetails col-md-4">
                  <Profile data={orderDetails} />
                  <History history={history} orderDetails={orderDetails} />
               </div>
               <div className="OrderDetails col-md-8">
                  <OrderSummary data={orderDetails} startDelivery={startDelivery} setStartDelivery={setStartDelivery} />
                  {/* <Map startDelivery={startDelivery} /> */}
               </div>
            </div>
         </div>
      </div>
   );
};

export default OrderView;
