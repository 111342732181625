import React from 'react';

import {ReactComponent as Icon} from '../../_assets/icons/historyKnob.svg';
import {readableDateString, capitalizeFirstLetter, GetTime} from '../../_helpers';

// const history = [
//    {id: 1, label: 'Order Delivered', time: 'Mon, 24 May 2022, 12:12 AM'},
//    {id: 1, label: 'Order Delivered', time: 'Mon, 24 May 2022, 12:12 AM'},
//    {id: 1, label: 'On Delivery', time: 'Mon, 24 May 2022, 12:12 AM'},
//    {id: 1, label: 'Order Created', time: 'Mon, 24 May 2022, 12:12 AM'},
//    {id: 1, label: 'Payment Success', time: 'Mon, 24 May 2022, 12:12 AM'},
// ];

const History = ({history}) => {
   return (
      <div className="w-100 py-3">
         <div className="third p-4">
            <h5 className="pb-4">History</h5>
            <ul className="">
               {history.map((item) => (
                  <li key={`history-${item._id}`} className="pb-4 d-flex align-items-start">
                     <Icon style={{transform: 'scale(.7)'}} />
                     <div className="ps-2">
                        <p className="">{capitalizeFirstLetter(item.title)}</p>
                        <small className="">{readableDateString(item.date) + ' ' + GetTime(item.date)}</small>
                     </div>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   );
};

export default History;
