import React from 'react';
// import {useDispatch} from 'react-redux';
// import {useNavigate} from 'react-router-dom';
import {numberWithCommas} from '../../_helpers';
// import StarRating from 'react-svg-star-rating';

import logoImg from '../../_assets/images/LTHLogo.png';
// import {menuActions} from '../../_actions';

const OrderViewRow = ({data, id, name, img_url, description, quantity, amount}) => {
   // const dispatch = useDispatch();
   // const navigate = useNavigate();

   // const handleViewMeal = (item) => {
   //    console.log('item', item)
   //    dispatch(menuActions.getAMenu({_id: item?.meal_id}));
   //    navigate(`/menu/${item.name}`, {state: {mealDetails: item}});
   // };
   // onClick={() => handleViewMeal(data)}

   return (
      <tr key={`recentOrderList-${id}`} className="d-table-row">
         <th scope="col" className="d-flex align-items-center pe-0">
            <div className="img m-0 d-flex align-items-center justify-content-center">
               {img_url ? (
                  <img src={img_url} alt="" className="h-100 w-100" />
               ) : (
                  <img src={logoImg} alt="" style={{transform: 'scale(.5)'}} />
               )}
            </div>
            <div className="ms-3">
               <span className="breakfast">{name}</span>
               <p className="mealName">{description}</p>
               {/* <p className="">
                  <StarRating
                     unit="half"
                     size={15}
                     count={5}
                     initialRating={starRating}
                     activeColor="#FFC403"
                     hoverColor="#FFC403"
                     emptyColor="rgba(0, 0, 0, .3)"
                     roundedCorner={true}
                     // handleOnClick={handleStarClick}
                     isReadOnly={true}
                     innerRadius={20}
                     outerRadius={45}
                     starClassName="each-star"
                     containerClassName=""
                  />
               </p> */}
            </div>
         </th>
         <td className="text-nowrap ps-0 align-middle">
            {quantity} {quantity > 1 ? 'plates' : 'plate'}
         </td>
         <td className="text-nowrap align-middle">&#8358; {numberWithCommas(amount)}</td>
      </tr>
   );
};

export default OrderViewRow;
