import {reservationConstants} from '../_constants';

const getAllReservations = (model) => ({
   type: reservationConstants.GET_ALL_RESERVATIONS,
   data: model,
});

const filterReservations = (model) => ({
   type: reservationConstants.FILTER_RESERVATIONS,
   data: model,
});

const getAReservation = (model) => ({
   type: reservationConstants.GET_A_RESERVATION,
   data: model,
});

const searchReservation = (model) => ({
   type: reservationConstants.SEARCH_RESERVATION,
   data: model,
});

const updateReservation = (model) => ({
   type: reservationConstants.UPDATE_RESERVATION,
   data: model,
});

const deleteReservation = (model) => ({
   type: reservationConstants.DELETE_RESERVATION,
   data: model,
});

export const reservationActions = {
   getAllReservations,
   filterReservations,
   getAReservation,
   searchReservation,
   updateReservation,
   deleteReservation,
};
