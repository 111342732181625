import React from 'react';
import {numberWithCommas, readableDateString} from '../../_helpers';

// import logoImg from '../../_assets/images/LTHLogo.png';

const PaymentRow = ({data, _id, id, img, img_url, updatedAt, name, description, createdAt, pay_ref, amount_paid}) => {
   return (
      <tr key={`recentOrderList-${_id}`} className="d-table-row" style={{cursor: 'auto'}}>
         {/* <th scope="col" className="d-flex align-items-center pe-0">
            <div className="img me-2 d-flex align-items-center justify-content-center">
               {img_url ? (
                  <img src={img_url} alt="" className="h-100 w-100" />
               ) : (
                  <img src={logoImg} alt="" style={{transform: 'scale(.5)'}} />
               )}
               img
            </div>
            <p className="mealName">{name + ' ' + description} name</p>
         </th> */}
         <td className="text-nowrap align-middle">&#8358; {numberWithCommas(amount_paid)}</td>
         <td className="text-nowrap align-middle">{pay_ref}</td>
         <td className="text-nowrap align-middle">{id}</td>
         <td className="text-nowrap align-middle">{readableDateString(createdAt)} 12:31 AM</td>
      </tr>
   );
};

export default PaymentRow;
