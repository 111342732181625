import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import {useNavigate} from 'react-router-dom';
import Formsy from 'formsy-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AuthWrapper, TextInput} from '../../_components';
import './index.scss';
import {forgotPasswordActions} from '../../_actions';
import {capitalizeFirstLetter} from '../../_helpers';

import {ReactComponent as NutsIcon} from '../../_assets/icons/nuts.svg';

const ForgotPassword = () => {
   const dispatch = useDispatch();
   const {IsSendingResetPasswordLink, successMessage, errorMessage} = useSelector((s) => s.forgotPassword);

   // const navigate = useNavigate();
   const [canSubmit, setCanSubmit] = useState(false);

   const handleSubmit = (data) => {
      data['user'] = 2;
      dispatch(forgotPasswordActions.getResetPasswordLink(data));

      // navigate('/resetPassword');
   };

   return (
      <AuthWrapper>
         <Formsy
            className="position-relative p-md-5 p-3"
            onValidSubmit={handleSubmit}
            onValid={() => setCanSubmit(true)}
            onInvalid={() => setCanSubmit(false)}>
            <NutsIcon className="nutIcon position-absolute" />
            <p className="signIn py-2">Forgot Password</p>
            <p className="small pb-4">Please input your email address and a link will be sent.</p>
            <p className="success mb-3">{successMessage && capitalizeFirstLetter(successMessage?.message)}</p>
            <p className="error mb-3">{errorMessage && capitalizeFirstLetter(errorMessage?.message)}</p>
            <TextInput
               type="text"
               id="email"
               name="email"
               label="Email Address"
               className="mb-3"
               inputClassName="dateTime"
               placeholder="toyinanifowoshe@gmail.com"
               value=""
            />
            <button type="submit" className="sign mt-4 w-100" disabled={!canSubmit}>
               {IsSendingResetPasswordLink ? <CircularProgress color="inherit" thickness={2} /> : 'Send'}
            </button>
         </Formsy>
      </AuthWrapper>
   );
};

export default ForgotPassword;
