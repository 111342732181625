import React from 'react';
import {useNavigate} from 'react-router-dom';
import {numberWithCommas, capitalizeFirstLetter} from '../../_helpers';

const RecentTableRow = ({data, id, customer, total, order_status_name, createdAt}) => {
   const navigate = useNavigate();

   const handleViewOrder = (item) => {
      navigate(`/orders/${item.mealName}`, {state: {orderDetails: item}});
   };

   return (
      <tr key={`orders-${id}`} onClick={() => handleViewOrder(data)} className="d-table-row">
         <th scope="col" className="d-flex align-items-center pe-0">
            {/* <div className="img me-2">
               <img src={mealImg} alt="" className="h-100 w-100" />
            </div> */}
            <p className="mealName text-nowrap text-truncate">{id}</p>
         </th>
         <td className="text-nowrap align-middle">{customer?.firstname + ' ' + customer?.lastname}</td>
         <td className="text-nowrap align-middle">&#8358; {numberWithCommas(total)}</td>
         <td className="align-middle">
            <span className="delivered text-nowrap">{capitalizeFirstLetter(order_status_name)}</span>
            {/* <span className="onDelivery text-nowrap">On Delivery</span>
            <span className="canceled text-nowrap">Canceled</span> */}
         </td>
      </tr>
   );
};

export default RecentTableRow;
