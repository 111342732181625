import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
// import Formsy from 'formsy-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AuthWrapper} from '../../_components';
import {capitalizeFirstLetter} from '../../_helpers';
// import {FiEye, FiEyeOff} from 'react-icons/fi';
import Lottie from 'react-lottie';
import verifying from '../../_assets/lottie/verifying.json';
// import verified from '../../_assets/lottie/verified.json';
import error from '../../_assets/lottie/error.json';
import './index.scss';

import {ReactComponent as NutsIcon} from '../../_assets/icons/nuts.svg';
import {userActions} from '../../_actions';

const Verifying = () => {
   const dispatch = useDispatch();
   let navigate = useNavigate();
   let {token} = useParams();

   const {
      IsVerifyingEmail,
      IsResendingVerificationLink,
      emailVerificationSuccessful,
      emailVerificationFailed,
      errorMessage,
   } = useSelector((s) => s.auth);

   // const [canSubmit, setCanSubmit] = useState(false);
   // const [showPass, setShowPass] = useState();

   useEffect(() => {
      // window.scrollTo(0, 0);
      const data = {
         token: token,
         // email: email,
      };
      // console.log('isverifying!!! my email!!!', data)
      dispatch(userActions.verifyEmail(data));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // const SuccessAnimation = {
   //    loop: true,
   //    autoplay: true,
   //    animationData: verified,
   //    rendererSettings: {
   //       preserveAspectRatio: 'xMidYMid slice',
   //    },
   // };
   const VerifyingAnimation = {
      loop: true,
      autoplay: true,
      animationData: verifying,
      rendererSettings: {
         preserveAspectRatio: 'xMidYMid slice',
      },
   };
   const ErrorAnimation = {
      loop: true,
      autoplay: true,
      animationData: error,
      rendererSettings: {
         preserveAspectRatio: 'xMidYMid slice',
      },
   };

   const resendLink = () => {
      const user_id = errorMessage?.user_id;
      const data = {
         id: user_id,
      };
      dispatch(userActions.resendEmailLink(data));
   };

   // const handleContinue = () => {
   //    navigate('/dashboard');
   // };

   const handleLogin = () => {
      navigate('/login');
   };

   return (
      <AuthWrapper>
         <div className="verifying p-md-5 p-3 text-center position-relative">
            <NutsIcon className="nutIcon position-absolute" />
            <h5 className="signIn py-2">Verify</h5>
            {IsVerifyingEmail && (
               <>
                  <Lottie options={VerifyingAnimation} height={150} width={150} />
                  <h5 className="my-4">Verifying your email address</h5>
                  {/* <p className="">Please wait while we verify your email</p> */}
               </>
            )}
            {emailVerificationSuccessful && (
               <>
                  {/* <Lottie options={SuccessAnimation} height={150} width={150} /> */}
                  <h5 className="my-4">Email verification successful</h5>
                  <p className="mb-4">you can click on the button below to continue with your account.</p>
                  <button type="submit" onClick={handleLogin} className="sign mt-4 w-100">
                     Proceed to Login
                  </button>
               </>
            )}
            {emailVerificationFailed && (
               <>
                  {errorMessage !== null ? (
                     <div className="server-error mb-2">
                        <Lottie options={ErrorAnimation} height={50} width={50} />
                        <p className="mt-4">{capitalizeFirstLetter(errorMessage?.message)}!</p>
                     </div>
                  ) : (
                     <p className="text-center">We couldn't verify your email</p>
                  )}
                  {errorMessage?.message === 'User Account Already Active' ? (
                     <button type="submit" onClick={handleLogin} className="sign mt-4 w-100">
                        Proceed to Login
                     </button>
                  ) : (
                     <button type="submit" onClick={resendLink} className="sign mt-4 w-100">
                        {IsResendingVerificationLink ? (
                           <CircularProgress color="inherit" thickness={1} />
                        ) : (
                           'Re-send Link'
                        )}
                     </button>
                  )}
               </>
            )}
            {/* <p className="small pb-4">Please input your details below.</p> */}
            {/* <p className="forgot my-3">
               <Link to="/forgotPassword">Forgot Password?</Link>
            </p> */}
         </div>
      </AuthWrapper>
   );
};

export default Verifying;
