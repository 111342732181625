import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// import Select from 'react-dropdown-select';
import CircularProgress from '@material-ui/core/CircularProgress';
import FullscreenModal from '../..';
import Formsy from 'formsy-react';
import {TextInput} from '../../../CustomInput';
// import {mealCategories} from '../../../../_constants';
import {capitalizeFirstWord} from '../../../../_helpers';
import {tagActions} from '../../../../_actions';
import './index.scss';

import {ReactComponent as CloseIcon} from '../../../../_assets/icons/close.svg';

// const availability = [
//    {id: 1, label: 'Available'},
//    {id: 2, label: 'Unavailable'},
// ];

// const fileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
// function validFileTypes(file) {
//    return fileTypes.includes(file.type);
// }

const AddTag = ({modalOpen, setModalOpen, tagDetails, type}) => {
   // const input = useRef();
   const dispatch = useDispatch();
   // const {categoriesList} = useSelector((s) => s.category);
   // const {tagsList} = useSelector((s) => s.tag);
   const {IsCreatingTag, tagCreatedSuccessfully, IsUpdatingTag, tagUpdatedSuccessfully, errorMessage} = useSelector(
      (s) => s.tag,
   );

   const [canSubmit, setCanSubmit] = useState(false);

   const handleSubmit = (data) => {
      if (tagDetails) {
         data['_id'] = tagDetails?._id;
         dispatch(tagActions.updateTag(data));
      } else {
         dispatch(tagActions.createTag(data));
      }
   };

   useEffect(() => {
      if (tagCreatedSuccessfully || tagUpdatedSuccessfully) {
         setModalOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [tagCreatedSuccessfully, tagUpdatedSuccessfully]);

   return (
      <FullscreenModal open={modalOpen} onClickAway={() => setModalOpen(false)}>
         <div className="addTag px-md-5 px-3 py-md-4 py-3 position-relative">
            <div className="position-absolute" style={{top: '1rem', right: '1.5rem'}}>
               <CloseIcon onClick={() => setModalOpen(false)} style={{transform: 'scale(.7)', cursor: 'pointer'}} />
            </div>
            <Formsy
               className="w-100"
               onValidSubmit={handleSubmit}
               onValid={() => setCanSubmit(true)}
               onInvalid={() => setCanSubmit(false)}>
               <h5 className="text-center">{type === 'edit' ? 'Edit Tag' : 'Add Tag'}</h5>
               <TextInput
                  type="text"
                  id="tag_name"
                  name="name"
                  label="Tag Name"
                  className="mb-3"
                  inputClassName="dateTime"
                  placeholder="Breakfast"
                  value={tagDetails?.name?.replace(/^#/, '')}
               />
               {errorMessage && (
                  <div className="server-error mb-2">
                     <p>{capitalizeFirstWord(errorMessage?.message?.[0])}</p>
                  </div>
               )}
               <button type="submit" className="save w-100 mt-4" disabled={!canSubmit}>
                  {IsCreatingTag || IsUpdatingTag ? <CircularProgress color="inherit" thickness={1} /> : 'Save'}
               </button>
            </Formsy>
         </div>
      </FullscreenModal>
   );
};

export default AddTag;
