import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
// import StarRating from 'react-svg-star-rating';
import LetteredAvatar from 'lettered-avatar';
import parse from 'html-react-parser';
import Dialog from '../../_components/Modal/Group/Dialog';
import {blogActions} from '../../_actions';
import {readableDateString} from '../../_helpers';

// import logoImg from '../../_assets/images/LTHLogo.png';
import {ReactComponent as DeleteIcon} from '../../_assets/icons/deleteBucket.svg';
import {ReactComponent as EditIcon} from '../../_assets/icons/editPen.svg';

const BlogRow = ({data, id, title, excerpt, _id, img, img_url, totalComments, updatedAt, stars}) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   // const [starRating, setStarRating] = useState(4.5);

   const [deleteBlog, setDeleteBlog] = useState(false);
   const [blogData, setBlogData] = useState({});

   const toggleDelete = (row) => {
      setBlogData(row);
      setDeleteBlog(!deleteBlog);
   };

   const handleDelete = () => {
      const _id = blogData?._id;

      if (_id) {
         dispatch(blogActions.deleteBlog(_id));
      }
      setDeleteBlog(false);
   };

   // const handleStarClick = (rating) => {
   //    setStarRating(rating);
   // };
   const handleViewBlog = (item) => {
      dispatch(blogActions.getABlog({_id: item?._id}));

      navigate(`/blogs/${item.title}`, {state: {blogDetails: item}});
   };

   return (
      <tr
         key={`recentOrderList-${id}`}
         data-toggle="tooltip"
         data-placement="top"
         title={title}
         // onClick={() => handleViewBlog(data)}
         className="d-table-row">
         <th scope="col" className="d-flex align-items-center">
            <div className="img me-2 d-flex align-items-center justify-content-center">
               {img_url ? (
                  <img src={img_url} alt="" className="h-100 w-100" />
               ) : (
                  <LetteredAvatar
                     name={title}
                     options={{
                        size: 50,
                        twoLetter: false,
                        //shape: 'square',
                        bgColor: '',
                        imgClass: 'img-fluid user-image',
                     }}
                  />
               )}
            </div>
            <p className="mealName">{title}</p>
         </th>
         <td className="align-middle">
            <p className="blogPost">{parse(excerpt)}</p>
         </td>
         <td className="text-nowrap align-middle">{totalComments}</td>
         {/* <td className="text-nowrap align-middle">
            <p className="d-flex align-items-center">
               <span className="me-1">{starRating}</span>
               <StarRating
                  unit="half"
                  size={15}
                  count={5}
                  initialRating={starRating}
                  activeColor="#FFC403"
                  hoverColor="#FFC403"
                  emptyColor="rgba(0, 0, 0, .3)"
                  roundedCorner={true}
                  // handleOnClick={handleStarClick}
                  isReadOnly={true}
                  innerRadius={20}
                  outerRadius={45}
                  starClassName="each-star"
                  containerClassName=""
               />
            </p>
         </td> */}
         <td className="text-nowrap align-middle">{readableDateString(updatedAt)}</td>
         {/* <td className="text-nowrap align-middle">
            <span className="available">Available</span>
         </td> */}
         <td className="text-nowrap align-middle">
            <div className="d-flex align-items-center">
               <DeleteIcon
                  onClick={() => toggleDelete(data)}
                  className="me-3"
                  style={{transform: 'scale(.7)', cursor: 'pointer'}}
               />
               <Dialog modalOpen={deleteBlog} title="Blog" handleDelete={handleDelete} setModalOpen={setDeleteBlog} />
               <EditIcon
                  onClick={() => handleViewBlog(data)}
                  className="ms-3"
                  style={{transform: 'scale(.7)', cursor: 'pointer'}}
               />
            </div>
         </td>
      </tr>
   );
};

export default BlogRow;
