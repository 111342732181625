import {appConstants} from './app.constant';

const orders = 'orders';

export const orderConstants = {
   REQUEST_ALL_ORDERS: 'REQUEST_ALL_ORDERS',
   REQUEST_ALL_ORDERS_SUCCESS: 'REQUEST_ALL_ORDERS_SUCCESS',
   REQUEST_ALL_ORDERS_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_ORDERS_SUCCESS_WITHOUT_DATA',
   REQUEST_ALL_ORDERS_ERROR: 'REQUEST_ALL_ORDERS_ERROR',

   REQUEST_RECENT_ORDERS: 'REQUEST_RECENT_ORDERS',
   REQUEST_RECENT_ORDERS_SUCCESS: 'REQUEST_RECENT_ORDERS_SUCCESS',
   REQUEST_RECENT_ORDERS_SUCCESS_WITHOUT_DATA: 'REQUEST_RECENT_ORDERS_SUCCESS_WITHOUT_DATA',
   REQUEST_RECENT_ORDERS_ERROR: 'REQUEST_RECENT_ORDERS_ERROR',

   REQUEST_AN_ORDER: 'REQUEST_AN_ORDER',
   REQUEST_AN_ORDER_SUCCESS: 'REQUEST_AN_ORDER_SUCCESS',
   REQUEST_AN_ORDER_ERROR: 'REQUEST_AN_ORDER_ERROR',

   REQUEST_SEARCH_ORDER: 'REQUEST_SEARCH_ORDER',
   SEARCH_ORDER_SUCCESS: 'SEARCH_ORDER_SUCCESS',
   SEARCH_ORDER_ERROR: 'SEARCH_ORDER_ERROR',
   SEARCH_ORDER_SUCCESS_WITHOUT_DATA: 'SEARCH_ORDER_SUCCESS_WITHOUT_DATA',

   REQUEST_DELETE_ORDER: 'REQUEST_DELETE_ORDER',
   DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
   DELETE_ORDER_ERROR: 'DELETE_ORDER_ERROR',

   REQUEST_UPDATE_ORDER: 'REQUEST_UPDATE_ORDER',
   UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
   UPDATE_ORDER_ERROR: 'UPDATE_ORDER_ERROR',

   GET_ALL_ORDERS: 'GET_ALL_ORDERS',
   GET_RECENT_ORDERS: 'GET_RECENT_ORDERS',
   GET_AN_ORDER: 'GET_AN_ORDER',
   SEARCH_ORDER: 'SEARCH_ORDER',

   DELETE_ORDER: 'DELETE_ORDER',
   UPDATE_ORDER: 'UPDATE_ORDER',

   ORDER_URI: `${appConstants.BASE_URI}${orders}`,
};
