import {matchPath, useLocation} from 'react-router-dom';

export const useLocationCode = () => {
   const {pathname} = useLocation();

   const dashboard = matchPath('/dashboard', pathname);
   const orders = matchPath('/orders', pathname);
   const ordersView = matchPath('/orders/*', pathname);
   const menu = matchPath('/menu', pathname);
   const menuView = matchPath('/menu/*', pathname);
   const reservations = matchPath('/reservations', pathname);
   const reservationsView = matchPath('/reservations/*', pathname);
   const customers = matchPath('/customers', pathname);
   const customersView = matchPath('/customers/*', pathname);
   const categories = matchPath('/categories', pathname);
   const payments = matchPath('/payments', pathname);
   const tags = matchPath('/tags', pathname);
   const blogs = matchPath('/blogs', pathname);
   const blogsView = matchPath('/blogs/*', pathname);
   const settings = matchPath('/settings', pathname);

   if (dashboard) {
      return 1;
   } else if (orders || ordersView) {
      return 2;
   } else if (menu || menuView) {
      return 3;
   } else if (reservations || reservationsView) {
      return 4;
   } else if (customers || customersView) {
      return 5;
   } else if (categories) {
      return 6;
   } else if (payments) {
      return 7;
   } else if (tags) {
      return 8;
   } else if (blogs || blogsView) {
      return 9;
   } else if (settings) {
      return 10;
   } else {
      return 11;
   }
};

export const useAuthRoute = () => {
   const {pathname} = useLocation();

   const routes = [
      '/signup',
      '/login',
      '/forgotPassword',
      '/verify_email/:token',
      '/user/reset/token/:token',
      '/reset_password/:token',
   ];

   const checker = (route) => routes.indexOf(route) !== -1;

   return checker(pathname);
};

export const useMatch = (path) => {
   const {pathname} = useLocation();
   // console.log('matchPath(pathname, path)', matchPath(path, pathname))

   // return matchPath(pathname, path);
   return matchPath(path, pathname);
};

export const numToString = (num, bool) => {
   switch (num) {
      case 1:
         return bool ? 'first' : 'one';
      case 2:
         return bool ? 'second' : 'two';
      case 3:
         return bool ? 'third' : 'three';
      case 4:
         return bool ? 'fourth' : 'four';
      case 5:
         return bool ? 'fifth' : 'five';
      case 6:
         return bool ? 'sixth' : 'six';
      case 7:
         return bool ? 'seventh' : 'seven';
      case 8:
         return bool ? 'eighth' : 'eight';
      case 9:
         return bool ? 'ninth' : 'nine';
      case 10:
         return bool ? 'tenth' : 'ten';
      default:
         return '';
   }
};
