import {menuConstants} from '../_constants';

const getAllMenus = (model) => ({
   type: menuConstants.GET_ALL_MENUS,
   data: model,
});

const getAllMenusWithoutPagination = () => ({
   type: menuConstants.GET_ALL_MENUS_WITHOUT_PAGINATION,
});

const getCategoryMenus = (model) => ({
   type: menuConstants.GET_CATEGORY_MENUS,
   data: model,
});

const getAMenu = (model) => ({
   type: menuConstants.GET_A_MENU,
   data: model,
});

const searchMenu = (model) => ({
   type: menuConstants.SEARCH_MENU,
   data: model,
});

const createMenu = (model) => ({
   type: menuConstants.CREATE_MENU,
   data: model,
});

const updateMenu = (model) => ({
   type: menuConstants.UPDATE_MENU,
   data: model,
});

const deleteMenu = (_id) => ({
   type: menuConstants.DELETE_MENU,
   _id,
});

const deleteMenuComment = (model) => ({
   type: menuConstants.DELETE_MENU_COMMENT,
   data: model,
});

export const menuActions = {
   getAllMenus,
   getAllMenusWithoutPagination,
   getCategoryMenus,
   getAMenu,
   searchMenu,
   createMenu,
   updateMenu,
   deleteMenu,
   deleteMenuComment,
};
