import React from 'react';
import {useNavigate} from 'react-router-dom';
import {numberWithCommas, readableDateString, capitalizeFirstLetter, GetTime} from '../../_helpers';
// import Dropdown from './Dropdown';

// import {ReactComponent as DropIcon} from '../../_assets/icons/dots.svg';

const OrderTableRow = ({data, id, customer, total, order_status_name, createdAt}) => {
   const navigate = useNavigate();
   // const [showDropdown, setShowDropdown] = useState(false);

   const handleViewOrder = (item) => {
      navigate(`/orders/${item.id}`, {state: {orderDetails: item}});
   };

   return (
      <tr key={`recentOrderList-${id}`} onClick={() => handleViewOrder(data)} className="d-table-row">
         {/* <th scope="col" className="d-flex align-items-center pe-0">
            <div className="img me-2">
               <img src={mealImg} alt="" className="h-100 w-100" />
            </div>
            <p className="mealName text-nowrap text-truncate">{mealName}</p>
         </th> */}
         <td className="text-nowrap align-middle"># {id}</td>
         <td className="text-nowrap align-middle">{customer?.firstname + ' ' + customer?.lastname}</td>
         <td className="text-nowrap align-middle">&#8358; {numberWithCommas(total)}</td>
         <td className="text-nowrap align-middle">{customer?.city}, Lagos</td>
         <td className="text-nowrap align-middle">
            {readableDateString(createdAt)}, {GetTime(createdAt)}
         </td>
         <td>
            <span className="delivered text-nowrap align-middle">{capitalizeFirstLetter(order_status_name)}</span>
            {/* <span className="onDelivery text-nowrap">On Delivery</span>
            <span className="canceled text-nowrap">Canceled</span> */}
         </td>
         {/* <td className="position-relative">
            <DropIcon
               className=""
               onClick={() => setShowDropdown(true)}
               style={{transform: 'scale(1)', cursor: 'pointer'}}
            />
            {showDropdown && (
               <Dropdown
                  data={data}
                  handleViewOrder={handleViewOrder}
                  showDropdown={showDropdown}
                  setShowDropdown={setShowDropdown}
               />
            )}
         </td> */}
      </tr>
   );
};

export default OrderTableRow;
