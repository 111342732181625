import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appConstants, setupConstants} from '../../_constants';
import {checkStatus, createRequest, setObjectInStorage} from '../../_helpers';

function* getSetupDataSaga({data}) {
   yield put({type: setupConstants.REQUEST_SETUP_DATA});
   try {
      const productsReq = yield call(createRequest, `${appConstants.PRODUCT_URL}?limit=10000`, {method: 'GET'}, '');
      const banksReq = yield call(createRequest, `${appConstants.BANK_URL}?limit=1000`, {method: 'GET'}, '');

      const responses = yield all([call(fetch, productsReq), call(fetch, banksReq)]);
      const [productsRes, banksRes] = responses;
      yield all([call(checkStatus, productsRes), call(checkStatus, banksRes)]);
      const jsonResponses = yield all([call(productsRes.json.bind(productsRes)), call(banksRes.json.bind(banksRes))]);
      const [products, banks] = jsonResponses;

      yield call(setObjectInStorage, appConstants.PRODUCT_KEY, products);
      yield call(setObjectInStorage, appConstants.BANK_KEY, banks);

      yield put({
         type: setupConstants.REQUEST_SETUP_DATA_SUCCESS,
         data: {
            products,
            banks,
         },
      });
   } catch (error) {
      yield put({type: setupConstants.REQUEST_SETUP_DATA_ERROR});
   }
}
function* getSetupDataSagaWatcher() {
   yield takeLatest('SETUP_APP', getSetupDataSaga);
}
export default function* rootSaga() {
   yield all([getSetupDataSagaWatcher()]);
}
