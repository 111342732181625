import React, {useEffect} from 'react';
// import {Link} from 'react-router-dom';
import './index.scss';

import {ReactComponent as SuccessIcon} from '../../_assets/icons/snackbarSuccess.svg';

export default function Snackbar({message, variant, onClose}) {
   useEffect(() => {
      const timer = setTimeout(() => {
         onClose();
         // console.log('dispatch');
      }, 3000);
      return () => clearTimeout(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [message]);

   // console.log('variant', variant);

   return (
      <div className={'notice p-4 d-flex align-items-center justify-content-center' + variant}>
         <div className="d-flex align-items-center" style={{textAlign: 'center'}}>
            {/* <div className="img me-3">
               <img src={img} alt="" className="h-100 w-100" />
            </div> */}
            <span className="me-4">{message}</span>
            {variant === 'success' ? <SuccessIcon className="" style={{transform: 'scale(.7)'}} /> : ''}
         </div>
      </div>
   );
}
