import React from 'react';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import BlogViewRow from './BlogViewRow';
// import {menuComments} from '../../_constants';

const BlogComments = ({comments, blogId}) => {
   return (
      <div className="col-md-12 col-12 py-3">
         <div className="comments">
            <h5 className="p-4">Comments</h5>
            <MDBTable responsive>
               <MDBTableHead>
                  <tr>
                     <th>Customer</th>
                     <th>Comments</th>
                     <th>Ratings</th>
                     <th>Date</th>
                     <th></th>
                  </tr>
               </MDBTableHead>
               <MDBTableBody>
                  {comments.length > 0 ? (
                     comments.map((item) => <BlogViewRow data={item} blogId={blogId} {...item} />)
                  ) : (
                     <p className="text-center">No comment has been made on this blog post</p>
                  )}
               </MDBTableBody>
            </MDBTable>
         </div>
      </div>
   );
};

export default BlogComments;
