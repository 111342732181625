import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import {useMatch, numberWithCommas, readableDateString, capitalizeFirstLetter} from '../../_helpers';
import OrderViewRow from './OrderViewRow';
import Select from 'react-dropdown-select';
// import {recentOrders} from '../../_constants';
// import {ConfirmPayment} from '..';
import './index.scss';

import {ReactComponent as PaidIcon} from '../../_assets/icons/paid.svg';
import {orderActions, reservationActions} from '../../_actions';

const status = [
   {value: 1, label: 'IN PROGRESS'},
   {value: 2, label: 'ON DELIVERY'},
   {value: 3, label: 'DELIVERED'},
];

const reservationStatus = [
   {value: 1, label: 'PENDING'},
   {value: 2, label: 'CANCEL'},
   {value: 3, label: 'CONFIRM'},
];

const OrderSummary = ({data}) => {
   const dispatch = useDispatch();
   const orderRoute = useMatch('orders/*');

   // const [confirmPayment, setConfirmPayment] = useState(false);
   // const [confirmPaymentModal, setConfirmPaymentModal] = useState(false);
   // const [category, setCategory] = useState(data?.order_status);
   const [category, setCategory] = useState(null);

   useEffect(() => {
      if (category) {
         const model = {
            order_status: category,
            _id: data?._id,
         };
         const reservationModel = {
            status: category,
            _id: data?._id,
            time: data?.time,
            date: data?.date,
         };
         // console.log('model', model);
         orderRoute
            ? dispatch(orderActions.updateOrder(model))
            : dispatch(reservationActions.updateReservation(reservationModel));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [category]);

   return (
      <div className="w-100 py-3">
         <div className="second">
            <h5 className="p-4">{orderRoute ? 'Order List' : 'Reservation Details'}</h5>
            {orderRoute && (
               <MDBTable responsive>
                  <MDBTableHead>
                     <tr>
                        <th>Meal</th>
                        <th>Qty</th>
                        <th>Amount</th>
                     </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                     {data?.order_detail.map((item) => (
                        <OrderViewRow data={item} {...item} />
                     ))}
                  </MDBTableBody>
               </MDBTable>
            )}

            <div className="p-4">
               <div className="d-flex align-items-center justify-content-between">
                  <p className="val">{orderRoute ? 'Order Value' : 'Date'}</p>
                  {orderRoute ? (
                     <p className="amount">&#8358; {numberWithCommas(data.total)}</p>
                  ) : (
                     <p className="amount"> {readableDateString(data.date)}</p>
                  )}
               </div>
               <div className="py-3 d-flex align-items-center justify-content-between">
                  <p className="val">{orderRoute ? 'Delivery' : 'Time'}</p>
                  {orderRoute ? (
                     <p className="amount">&#8358; {numberWithCommas(0.0)}</p>
                  ) : (
                     <p className="amount"> {data.time}</p>
                  )}
               </div>
               {!orderRoute ? (
                  <>
                     <div className="py-2 d-flex align-items-center justify-content-between">
                        <p className="val">Email</p>
                        <p className="amount">{data.email}</p>
                     </div>
                     <div className="py-2 d-flex align-items-center justify-content-between">
                        <p className="val">Phone Number</p>
                        <p className="amount">{data.phone_no}</p>
                     </div>
                     <div className="py-2 d-flex align-items-center justify-content-between">
                        <p className="val">Facility</p>
                        <p className="amount">{capitalizeFirstLetter(data.facility)}</p>
                     </div>
                     <div className="py-2 d-flex align-items-center justify-content-between">
                        <p className="val">Purpose</p>
                        <p className="amount">{capitalizeFirstLetter(data.purpose)}</p>
                     </div>
                  </>
               ) : (
                  <div className="d-flex align-items-center justify-content-between">
                     <p className="val d-flex align-items-center">
                        Total <PaidIcon style={{transform: 'scale(.7)'}} />
                     </p>
                     <p className="amount">&#8358; {numberWithCommas(data.total)}</p>
                  </div>
               )}
               <div className="mt-4 d-flex align-items-center justify-content-between">
                  <h5 className="status d-flex align-items-center">
                     Status:
                     {/* <span className="text-uppercase paid ms-1">
                        {confirmPaymentModal ? (
                           'Confirmed'
                        ) : startDelivery ? (
                           <span className="deliveryOngoing">Delivery Ongoing</span>
                        ) : (
                           'paid'
                        )}
                     </span> */}
                     <Select
                        values={
                           orderRoute
                              ? [status.find((opt) => opt.value === data?.order_status)]
                              : [reservationStatus.find((opt) => opt.value === data?.status)]
                        }
                        options={orderRoute ? status : reservationStatus}
                        onChange={(values) => setCategory(values?.[0]?.value)}
                     />
                  </h5>
                  {/* <div className="mt-4 d-flex align-items-center">
                     {confirmPayment ? (
                        <>
                           <button onClick={() => setConfirmPaymentModal(true)} className="acceptOrder me-3">
                              Confirm Payment
                           </button>
                           <ConfirmPayment
                              data={data}
                              setConfirmPayment={setConfirmPayment}
                              setStartDelivery={setStartDelivery}
                              modalOpen={confirmPaymentModal}
                              setModalOpen={setConfirmPaymentModal}
                           />
                        </>
                     ) : startDelivery ? (
                        <button onClick={() => {}} className="acceptOrder me-3">
                           Start Delivery
                        </button>
                     ) : (
                        <>
                           <button onClick={() => setConfirmPayment(true)} className="acceptOrder me-3">
                              Accept Order
                           </button>
                           <button className="declineOrder">Decline Order</button>
                        </>
                     )}
                  </div> */}
               </div>
            </div>
         </div>
      </div>
   );
};

export default OrderSummary;
