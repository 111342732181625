import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import Formsy from 'formsy-react';
import {TextInput} from '../../_components';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {toFormData, readableDateString, capitalizeFirstWord} from '../../_helpers';
import BlogComments from './BlogComments';
import './index.scss';
import {blogActions} from '../../_actions';

import logoImg from '../../_assets/images/LTHLogo.png';
import {ReactComponent as Prev} from '../../_assets/icons/arrowLeft.svg';

// const availability = [
//    {id: 1, label: 'Available'},
//    {id: 2, label: 'Unavailable'},
// ];
const fileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
function validFileTypes(file) {
   return fileTypes.includes(file.type);
}

const BlogView = () => {
   const input = useRef();
   const dispatch = useDispatch();
   const {state} = useLocation();
   const blogDetails = state.blogDetails;
   const navigate = useNavigate();
   const {blogDetail, errorMessage} = useSelector((s) => s.blog);

   const [image, setImage] = useState(blogDetail?.img_url || blogDetails?.img_url || null);
   const [imageData, setImageData] = useState();
   const [imageError, setImageError] = useState('');
   const [description, setDescription] = useState(blogDetail?.body || blogDetails?.body);

   // const allTags = tagsList.map((v) => ({
   //    ...v,
   //    label: v.name,
   //    value: v._id,
   // }));
   useEffect(() => {
      if (blogDetail) {
         setImage(blogDetail?.img_url);
         setDescription(blogDetail?.body);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [blogDetail]);

   const handleGoBack = () => {
      navigate(-1);
   };

   const handleChange = () => {
      const files = input.current.files;

      if (files.length) {
         if (validFileTypes(files[0])) {
            setImage(URL.createObjectURL(files[0]));
            setImageData(files[0]);
            setImageError('');
            input.current.files = null;
         } else {
            setImageError('Selected file is not an image type, please choose one of types: .png, .jpg, .png');
            input.current.files = null;
         }
      }
   };

   const handleSubmit = (data) => {
      if (blogDetails) {
         const fData = toFormData(data);
         fData.append('body', description);
         imageData && fData.append('file', imageData);

         dispatch(blogActions.updateBlog({fields: fData, _id: blogDetails?._id}));
      }
   };

   const titleError = errorMessage?.message.filter((error) => error.split(' ')[0] === 'title');
   const bodyError = errorMessage?.message.filter((error) => error.split(' ')[0] === 'body');

   return (
      <div className="p-4">
         <div className="menuView blogComments">
            <div className="back mb-4 ps-2">
               <button onClick={handleGoBack} className="d-inline-flex align-items-center">
                  <Prev className="icon me-2" cursor="pointer" />
                  Back
               </button>
            </div>

            <div className="row m-0">
               <div className="col-md-12 col-12 py-3">
                  <div className="mealDetails p-4">
                     <Formsy className="w-100" onValidSubmit={handleSubmit}>
                        <div className="row m-0 py-3">
                           <div className="col-md-4 col-12">
                              <div className="img d-flex align-items-center justify-content-center">
                                 {blogDetail?.img_url || blogDetails?.img_url ? (
                                    <img
                                       src={image || blogDetail?.img_url || blogDetails?.img_url}
                                       alt=""
                                       className="w-100 h-100"
                                    />
                                 ) : (
                                    <img src={logoImg} alt="" style={{transform: 'scale(1)'}} />
                                 )}
                              </div>
                              <button onClick={() => input.current.click()} className="change my-2">
                                 Change Image
                              </button>
                              <input
                                 ref={input}
                                 onClick={(e) => (input.current.value = null)}
                                 onChange={handleChange}
                                 type="file"
                                 accept=".png, .jpg, .jpeg"
                                 className=""
                                 style={{display: 'none'}}
                              />
                              <TextInput
                                 type="text"
                                 id="blog_title"
                                 name="title"
                                 label="Blog Title"
                                 className="mb-3"
                                 inputClassName="dateTime"
                                 placeholder="Breakfast"
                                 value={blogDetail?.title || blogDetails?.title}
                              />
                              <p className="error">{titleError?.length > 0 && capitalizeFirstWord(titleError?.[0])}</p>
                              {/* <div className="mt-3">
                                 <label htmlFor="" className="">
                                    Tags
                                 </label>
                                 <Select
                                    className="mb-3 text-capitalize"
                                    multi
                                    options={allTags}
                                    values={allTags.find((opt) => opt.value === String(allTags?.[0]?.label))}
                                    onChange={(values) => setTags(values)}
                                 />
                              </div> */}
                              <TextInput
                                 id="date_time"
                                 type="text"
                                 label="Last Updated"
                                 name="date_time"
                                 className="mb-2"
                                 inputClassName="dateTime"
                                 placeholder=""
                                 value={readableDateString(blogDetail?.updatedAt || blogDetails?.updatedAt)}
                                 disabled
                              />
                           </div>
                           <div className="col-md-8 col-12">
                              <div className="descDetails">
                                 {/* <TextArea
                                    id="description"
                                    type="text"
                                    label="Description"
                                    name="body"
                                    className="mb-2"
                                    placeholder=""
                                    value={blogDetail?.body || blogDetails?.body}
                                    rows={20}
                                 /> */}
                                 <div className="">
                                    <label htmlFor="" className="">
                                       Description
                                    </label>
                                    <CKEditor
                                       editor={ClassicEditor}
                                       data={description}
                                       onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setDescription(data);
                                       }}
                                    />
                                 </div>
                                 <p className="error mt-1">
                                    {bodyError?.length > 0 && capitalizeFirstWord(bodyError?.[0])}
                                 </p>
                              </div>
                           </div>
                        </div>
                        <button className="save px-5 my-4 ms-2" type="submit">
                           Update Changes
                        </button>
                     </Formsy>
                  </div>
               </div>
            </div>
            <BlogComments comments={blogDetail?.comments || blogDetails?.comments} blogId={blogDetails?._id} />
         </div>
      </div>
   );
};

export default BlogView;
