import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {blogConstants, userConstants} from '../../_constants';
import {
   getObjectFromStorage,
   checkStatus,
   createRequestWithToken,
   createMultiPartRequestWithToken,
   clearObjectFromStorage,
} from '../../_helpers';

function* getBlogs({data}) {
   yield put({type: blogConstants.REQUEST_ALL_BLOGS});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);
      let blogsUri = `${blogConstants.BLOG_URI}`;

      if (data?.page) {
         blogsUri = `${blogsUri}?page=${data.page + 1}`;
      }
      if (data?.pagination) {
         blogsUri = `${blogConstants.BLOG_URI}get_all`;
      }

      const blogsReq = createRequestWithToken(blogsUri, {
         method: 'GET',
      })(user?.accessToken);

      const response = yield call(fetch, blogsReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: blogConstants.REQUEST_ALL_BLOGS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: blogConstants.REQUEST_ALL_BLOGS_SUCCESS,
         blogs: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({type: blogConstants.REQUEST_ALL_BLOGS_ERROR});
   }
}

function* getABlog({data}) {
   yield put({type: blogConstants.REQUEST_A_BLOG});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      let blogUri = `${blogConstants.BLOG_URI}/${data._id}`;

      // const blogReq = yield call(createRequest, blogUri, {method: 'GET'});
      const blogReq = createRequestWithToken(blogUri, {
         method: 'GET',
      })(user?.accessToken);

      const response = yield call(fetch, blogReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({type: blogConstants.REQUEST_A_BLOG_SUCCESS, blog: jsonResponse});
   } catch (error) {
      yield put({type: blogConstants.REQUEST_A_BLOG_ERROR});
   }
}

function* createBlogSaga({data}) {
   try {
      yield put({type: blogConstants.REQUEST_CREATE_BLOG});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${blogConstants.BLOG_URI}`;

      const curriedReq = yield call(createMultiPartRequestWithToken, url, {
         method: 'POST',
         body: data,
      });
      const req = yield call(curriedReq, user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: blogConstants.CREATE_BLOG_SUCCESS,
         blog: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Blog successfully posted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({
         type: blogConstants.CREATE_BLOG_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* updateBlogSaga({data}) {
   try {
      yield put({type: blogConstants.REQUEST_UPDATE_BLOG});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${blogConstants.BLOG_URI}/${data._id}`;
      const curriedReq = yield call(createMultiPartRequestWithToken, url, {
         method: 'PATCH',
         body: data.fields,
      });
      const req = yield call(curriedReq, user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: blogConstants.UPDATE_BLOG_SUCCESS,
         blog: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Blog successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({
         type: blogConstants.UPDATE_BLOG_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* deleteBlogSaga({_id}) {
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      yield put({type: blogConstants.REQUEST_DELETE_BLOG});

      const url = `${blogConstants.BLOG_URI}/${_id}`;
      const req = createRequestWithToken(url, {
         method: 'DELETE',
      })(user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: blogConstants.DELETE_BLOG_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Blog successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({
         type: blogConstants.DELETE_BLOG_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* searchBlogSaga({data}) {
   try {
      yield put({type: blogConstants.REQUEST_SEARCH_BLOG});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${blogConstants.BLOG_URI}/search?query=${data.searchInput}`;

      const BLOGReq = createRequestWithToken(url, {
         method: 'GET',
      })(user?.access_token);

      const response = yield call(fetch, BLOGReq);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: blogConstants.SEARCH_BLOG_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      if (response.status === 401) {
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: blogConstants.SEARCH_BLOG_SUCCESS,
         blog: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: blogConstants.SEARCH_BLOG_ERROR,
         error: errorMessage,
      });
   }
}

function* deleteBlogComment({data}) {
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      yield put({type: blogConstants.REQUEST_DELETE_BLOG_COMMENT});

      const url = `${blogConstants.BLOG_URI}/${data.blogId}/comment/${data._id}`;
      const req = createRequestWithToken(url, {method: 'PATCH'})(user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: blogConstants.DELETE_BLOG_COMMENT_SUCCESS, blog: jsonResponse});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Blog comment successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({type: blogConstants.DELETE_BLOG_COMMENT_ERROR, error: errorMessage});
      yield put(appActions.setSnackBar({message: 'Something went wrong', variant: 'error'}));
   }
}

function* getBlogsWatcher() {
   yield takeLatest(blogConstants.GET_ALL_BLOGS, getBlogs);
}

function* getABlogWatcher() {
   yield takeLatest(blogConstants.GET_A_BLOG, getABlog);
}
function* createBlogWatcher() {
   yield takeLatest(blogConstants.CREATE_BLOG, createBlogSaga);
}

function* updateBlogWatcher() {
   yield takeLatest(blogConstants.UPDATE_BLOG, updateBlogSaga);
}

function* deleteBlogWatcher() {
   yield takeLatest(blogConstants.DELETE_BLOG, deleteBlogSaga);
}

function* searchBlogWatcher() {
   yield takeLatest(blogConstants.SEARCH_BLOG, searchBlogSaga);
}

function* deleteBlogCommentWatcher() {
   yield takeLatest(blogConstants.DELETE_BLOG_COMMENT, deleteBlogComment);
}

export default function* rootSaga() {
   yield all([
      getBlogsWatcher(),
      getABlogWatcher(),
      createBlogWatcher(),
      updateBlogWatcher(),
      deleteBlogWatcher(),
      searchBlogWatcher(),
      deleteBlogCommentWatcher(),
   ]);
}
