import React, {useLayoutEffect} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import NotFound from './404';
import {Sidebar, Header} from './_navigation';
import {appActions, userActions} from './_actions';
import {Snackbar} from './_components';
import {
   Dashboard,
   Orders,
   Menu,
   Reservations,
   // Customers,
   Settings,
   Login,
   OrderView,
   MenuView,
   ReservationView,
   // CustomerView,
   Verifying,
   ForgotPassword,
   ResetPassword,
   Category,
   Tags,
   Blogs,
   BlogView,
   Payments,
} from './Pages';
import {PrivateRoute} from './_components';
import {useLocationCode} from './_helpers';
import Aos from 'aos';
import './App.scss';

function App() {
   const code = useLocationCode();
   const dispatch = useDispatch();
   let navigate = useNavigate();
   const location = useLocation();

   const snackbar = useSelector((s) => s.snackbar);
   const user = useSelector((s) => s.user);

   const clearSnackBar = () => {
      dispatch(appActions.clearSnackBar());
   };

   const logout = () => {
      navigate('/');
      dispatch(userActions.logout());
   };

   useLayoutEffect(() => {
      Aos.init({duration: 2000});
   }, []);

   return (
      <>
         <Sidebar logout={logout} />
         <div className="container-fluid p-0 position-relative">
            <div className="row m-0 h-100">
               <Header user={user?.userDetails} />
               <div className={classNames('body col-12 p-0 m-0', {content: code !== 11, full: code === 11})}>
                  <Routes>
                     <Route path="/" element={<Login />} />
                     <Route
                        path="/dashboard"
                        element={
                           <PrivateRoute>
                              <Dashboard />
                           </PrivateRoute>
                        }
                     />
                     <Route
                        path="/orders"
                        element={
                           <PrivateRoute>
                              <Orders />
                           </PrivateRoute>
                        }
                     />
                     <Route
                        path="/orders/:uuid"
                        element={
                           <PrivateRoute>
                              <OrderView />
                           </PrivateRoute>
                        }
                     />
                     <Route
                        path="/menu"
                        element={
                           <PrivateRoute>
                              <Menu />
                           </PrivateRoute>
                        }
                     />
                     <Route
                        path="/menu/:uuid"
                        element={
                           <PrivateRoute>
                              <MenuView />
                           </PrivateRoute>
                        }
                     />
                     <Route
                        path="/reservations"
                        element={
                           <PrivateRoute>
                              <Reservations />
                           </PrivateRoute>
                        }
                     />
                     <Route
                        path="/reservations/:uuid"
                        element={
                           <PrivateRoute>
                              <ReservationView />
                           </PrivateRoute>
                        }
                     />
                     {/* <Route path="/customers" element={<Customers />} />
                     <Route path="/customers/:uuid" element={<CustomerView />} /> */}
                     <Route
                        path="/categories"
                        element={
                           <PrivateRoute>
                              <Category />
                           </PrivateRoute>
                        }
                     />
                     <Route
                        path="/payments"
                        element={
                           <PrivateRoute>
                              <Payments />
                           </PrivateRoute>
                        }
                     />
                     <Route
                        path="/tags"
                        element={
                           <PrivateRoute>
                              <Tags />
                           </PrivateRoute>
                        }
                     />
                     <Route
                        path="/blogs"
                        element={
                           <PrivateRoute>
                              <Blogs />
                           </PrivateRoute>
                        }
                     />
                     <Route
                        path="/blogs/:id"
                        element={
                           <PrivateRoute>
                              <BlogView />
                           </PrivateRoute>
                        }
                     />
                     <Route
                        path="/settings"
                        element={
                           <PrivateRoute>
                              <Settings />
                           </PrivateRoute>
                        }
                     />
                     <Route path="/login" element={<Login />} />
                     <Route path="/verify_email/:token" element={<Verifying />} />
                     <Route path="/forgotPassword" element={<ForgotPassword />} />
                     <Route path="/reset_password/:token" element={<ResetPassword />} />
                     <Route path="*" element={<NotFound />} />
                  </Routes>
               </div>
            </div>

            {snackbar?.message && (
               <Snackbar
                  message={snackbar?.message.text}
                  variant={snackbar?.message.variant}
                  img={snackbar?.message.img}
                  onClose={clearSnackBar}
               />
            )}
         </div>
      </>
   );
}

export default App;
