import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
// import Select from 'react-dropdown-select';
import Formsy from 'formsy-react';
import {AddCategory} from '../../_components';
import CategoryRow from './CategoryRow';
import Loader from '../../_helpers/loader';
import {Pagination, TextInput} from '../../_components';
import './index.scss';
import {categoryActions, menuActions} from '../../_actions';
import {ReactComponent as SearchIcon} from '../../_assets/icons/search.svg';

const Menu = () => {
   const dispatch = useDispatch();

   const {IsRequestingCategories, IsSearchingCategory, categoriesList, pagination} = useSelector((s) => s.category);

   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   // const [menuType, setMenuType] = useState(newArr?.[0]?.contents);
   const [addMenuModal, setAddMenuModal] = useState(false);
   useEffect(() => {
      dispatch(categoryActions.getAllCategories());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // const newArr = categoriesList?.map((v) => ({
   //    ...v,
   //    label: v.name,
   //    value: v._id,
   // }));

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(menuActions.getAllMenus(model));
   };

   const handleAddMenu = () => {
      setAddMenuModal(true);
   };

   const searchCategory = (data) => {
      if (data.searchInput === '') {
         dispatch(categoryActions.getAllCategories());
      } else {
         dispatch(categoryActions.searchCategory(data));
      }
   };

   return (
      <div className="p-4">
         <div className="category">
            <div className=" p-4 d-flex align-items-center justify-content-between">
               {/* <Select
                  className="text-capitalize"
                  values={newArr.find((opt) => opt.value === String(newArr?.[0]?.label))}
                  options={newArr}
                  onChange={(values) => setCategory(values)}
               /> */}
               <div className="search">
                  <Formsy onValidSubmit={searchCategory}>
                     <TextInput
                        id="search"
                        type="text"
                        name="searchInput"
                        placeholder="Search"
                        className=""
                        leftIcon={
                           <div className="icon__Wrapper position-absolute">
                              <SearchIcon className="w-100 h-100" />
                           </div>
                        }
                     />
                  </Formsy>
               </div>
               <button onClick={handleAddMenu} className="addMenu text-nowrap" style={{width: '8rem'}}>
                  Add Category
               </button>
               <AddCategory modalOpen={addMenuModal} type="add" setModalOpen={setAddMenuModal} />
            </div>
            {IsRequestingCategories || IsSearchingCategory ? (
               <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                  <div />
               </Loader>
            ) : (
               <MDBTable responsive>
                  <MDBTableHead>
                     <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th className="text-nowrap ps-1">Total Menu</th>
                        <th className="text-nowrap">Last Updated</th>
                        <th>Action</th>
                        <th></th>
                     </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                     {categoriesList?.length > 0 ? (
                        categoriesList.map((item, i) => {
                           return <CategoryRow key={i} {...item} data={item} />;
                        })
                     ) : (
                        <td align="center" className="no-content" colSpan={5}>
                           No category has been uploaded on this platform
                        </td>
                     )}
                  </MDBTableBody>
               </MDBTable>
            )}
         </div>
         {!IsRequestingCategories && totalPages > 1 && (
            <Pagination
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </div>
   );
};

export default Menu;
