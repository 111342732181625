import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import Formsy from 'formsy-react';
import ReservationRow from './ReservationRow';
// import Select from 'react-dropdown-select';
// import {recentOrders} from '../../_constants';
import Loader from '../../_helpers/loader';
import {Pagination, TextInput} from '../../_components';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import './index.scss';
import {reservationActions} from '../../_actions';

import {ReactComponent as SearchIcon} from '../../_assets/icons/search.svg';
import {ReactComponent as FilterIcon} from '../../_assets/icons/filter.svg';

// const statusList = [
//    {id: 1, name: 'Reserved'},
//    {id: 2, name: 'Unreserved'},
// ];

const Reservations = () => {
   const dispatch = useDispatch();

   const {IsRequestingAllReservations, IsSearchingReservation, reservationList, pagination} = useSelector(
      (s) => s.reservation,
   );
   // const {categoriesList} = useSelector((s) => s.category);

   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   const [date, setDate] = useState([new Date(), new Date()]);
   const [changedDate, setChangedDate] = useState(false);
   // const [toDate, setToDate] = useState(new Date());

   // const [status, setStatus] = useState([]);
   useEffect(() => {
      dispatch(reservationActions.getAllReservations());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // const handleChangeDate = () => {
   //    console.log('GOTHERE');

   //    setChangedDate(true);
   // };
   // console.log('OUTDATE', date);

   useEffect(() => {
      if (changedDate === true && date !== null) {
         // console.log('date', date);
         // console.log('[new Date(), new Date()]', [new Date(), new Date()]);

         const model = {
            from: date[0].toISOString(),
            to: date[1].toISOString(),
         };
         // console.log('model', model);

         dispatch(reservationActions.filterReservations(model));
         // setChangedDate(false);
      }
      setChangedDate(false);
      // else {
      //    dispatch(reservationActions.getAllReservations());
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [changedDate]);

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };
      const filterModel = {
         from: date[0].toISOString(),
         to: date[1].toISOString(),
         page: pages,
      };
      if (date !== null) {
         dispatch(reservationActions.filterReservations(filterModel));
      } else {
         dispatch(reservationActions.getAllReservations(model));
      }

      // dispatch(reservationActions.getAllReservations(model));
   };

   const searchReservation = (data) => {
      if (data.searchInput === '') {
         dispatch(reservationActions.getAllReservations());
      } else {
         dispatch(reservationActions.searchReservation(data));
      }
   };

   return (
      <div className="p-4">
         <div className="orders reservations">
            <div className="p-0 my-3 d-flex flex-wrap align-items-center justify-content-between">
               <h5 className="p-4">Reservation List</h5>
               <div className="d-flex align-items-center">
                  <button className="date p-0 mx-3">
                     <DateRangePicker onChange={(event) => setDate(event, setChangedDate(true))} value={date} />
                  </button>
                  {/* <button className="status mx-3">
                     <Select
                        className="text-capitalize"
                        options={statusArr}
                        values={statusArr.find((opt) => opt.value === String(statusArr?.[0]?.label))}
                        onChange={(values) => setStatus(values)}
                     />
                  </button> */}
                  {/* <button className="applyFilter">
                     <FilterIcon style={{transform: 'scale(.7)'}} className="me-2" /> Apply Filter
                  </button> */}
               </div>
               <div className="search my-md-0 my-4 ps-4 ps-md-0">
                  <Formsy onValidSubmit={searchReservation}>
                     <TextInput
                        id="search"
                        type="text"
                        name="searchInput"
                        placeholder="Search"
                        className=""
                        leftIcon={
                           <div className="icon__Wrapper position-absolute">
                              <SearchIcon className="w-100 h-100" />
                           </div>
                        }
                     />
                  </Formsy>
               </div>
            </div>
            {IsRequestingAllReservations || IsSearchingReservation ? (
               <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                  <div />
               </Loader>
            ) : (
               <MDBTable responsive>
                  <MDBTableHead>
                     <tr>
                        <th className="text-nowrap">Customer</th>
                        <th className="text-nowrap">Ticket No</th>
                        <th className="text-nowrap">Email</th>
                        <th className="text-nowrap">Phone Number</th>
                        {/* <th className="text-nowrap">Guest</th>
                        <th className="text-nowrap">Facility</th> */}
                        <th className="text-nowrap">Date & Time</th>
                        <th className="text-nowrap">Status</th>
                     </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                     {/* {recentOrders.map((item) => (
                     <ReservationRow data={item} {...item} />
                  ))} */}
                     {reservationList?.length > 0 ? (
                        reservationList.map((item, i) => {
                           return <ReservationRow key={i} {...item} data={item} />;
                        })
                     ) : (
                        <td align="center" className="no-content pt-3" colSpan={5}>
                           No reservation has been made on this platform
                        </td>
                     )}
                  </MDBTableBody>
               </MDBTable>
            )}
         </div>
         {(!IsRequestingAllReservations || !IsSearchingReservation) && totalPages > 1 && (
            <Pagination
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </div>
   );
};

export default Reservations;
