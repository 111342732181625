import React, {useState, useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// import Select from 'react-dropdown-select';
import CircularProgress from '@material-ui/core/CircularProgress';
import FullscreenModal from '../..';
import Formsy from 'formsy-react';
import {TextInput} from '../../../CustomInput';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {toFormData, capitalizeFirstWord} from '../../../../_helpers';
import {blogActions} from '../../../../_actions';
import './index.scss';

import {ReactComponent as CloseIcon} from '../../../../_assets/icons/close.svg';

// const availability = [
//    {id: 1, label: 'Available'},
//    {id: 2, label: 'Unavailable'},
// ];

const fileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
function validFileTypes(file) {
   return fileTypes.includes(file.type);
}

const AddBlog = ({modalOpen, setModalOpen, menuDetails}) => {
   const input = useRef();
   const dispatch = useDispatch();
   // const {categoriesList} = useSelector((s) => s.category);
   // const {tagsList} = useSelector((s) => s.tag);
   const {IsCreatingBlog, blogCreatedSuccessfully, errorMessage} = useSelector((s) => s.blog);

   const [canSubmit, setCanSubmit] = useState(false);
   // const [category, setCategory] = useState([]);
   const [description, setDescription] = useState('');
   const [image, setImage] = useState(menuDetails?.image_url || null);
   const [imageData, setImageData] = useState();
   const [imageError, setImageError] = useState('');
   const [imageDataError, setImageDataError] = useState('');

   const handleChange = () => {
      const files = input.current.files;

      if (files.length) {
         if (validFileTypes(files[0])) {
            setImage(URL.createObjectURL(files[0]));
            setImageData(files[0]);
            setImageError('');
            input.current.files = null;
            if (files[0]?.size > 1048576) {
               setImageDataError('Image size should not exceed 1MB');
            } else {
               setImageDataError('');
            }
         } else {
            setImageError('Selected file is not an image type, please choose one of types: .png, .jpg, .png');
            input.current.files = null;
         }
      }
   };

   const handleSubmit = (data) => {
      if (data.title) {
         if (imageData?.size > 1048576) {
            setImageDataError('Image size should not exceed 1MB');
         } else {
            const fData = toFormData(data);
            fData.append('body', description);
            imageData && fData.append('file', imageData);

            dispatch(blogActions.createBlog(fData));
         }
      }
   };

   useEffect(() => {
      if (blogCreatedSuccessfully) {
         setModalOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [blogCreatedSuccessfully]);

   const titleError = errorMessage?.message.filter((error) => error.split(' ')[0] === 'title');
   const bodyError = errorMessage?.message.filter((error) => error.split(' ')[0] === 'body');

   return (
      <FullscreenModal open={modalOpen} onClickAway={() => setModalOpen(false)}>
         <div className="addMenu px-md-5 px-3 py-md-4 py-3 position-relative">
            <div className="position-absolute" style={{top: '1rem', right: '1.5rem'}}>
               <CloseIcon onClick={() => setModalOpen(false)} style={{transform: 'scale(.7)', cursor: 'pointer'}} />
            </div>
            <Formsy
               className="w-100"
               onValidSubmit={handleSubmit}
               onValid={() => setCanSubmit(true)}
               onInvalid={() => setCanSubmit(false)}>
               <h5 className="text-center">Add Blog</h5>
               {/* <h6 className="text-center pt-2">Enter food details and upload them.</h6> */}
               <div className="py-3 my-3">
                  <span className="">Add Image</span>
                  <div className="mt-1 d-flex align-items-center">
                     <div className="img" style={{width: '100%'}}>
                        <img src={image} alt="" className="h-100 w-100" />
                     </div>
                  </div>
                  <div className="mt-3">
                     <button onClick={() => input.current.click()} className="choose">
                        Choose Image
                     </button>
                     <p className="">JPG or PNG not more than 1MB</p>
                     <p className="error">{imageDataError}</p>
                     <input
                        ref={input}
                        onClick={(e) => (input.current.value = null)}
                        onChange={handleChange}
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className=""
                        style={{display: 'none'}}
                     />
                  </div>
               </div>
               <TextInput
                  type="text"
                  id="title"
                  name="title"
                  label="Title"
                  className="mb-3"
                  inputClassName="dateTime"
                  placeholder=""
               />
               <p className="error">{titleError?.length > 0 && capitalizeFirstWord(titleError?.[0])}</p>
               {/* <div className="">
                  <label htmlFor="" className="">
                     Category
                  </label>
                  <Select
                     className="mb-3 text-capitalize"
                     // name={`${newArr?.[0]?.label}`}
                     multi
                     options={newArr}
                     values={newArr.find((opt) => opt.value === String(newArr?.[0]?.label))}
                     onChange={(values) => setCategory(values)}
                  />
               </div> */}
               {/* <TextArea
                  type="text"
                  id="description"
                  name="body"
                  label="Body"
                  className="mb-2"
                  placeholder=""
                  rows={8}
               /> */}
               <div className="editoor">
                  <label htmlFor="" className="">
                     Description
                  </label>
                  <CKEditor
                     editor={ClassicEditor}
                     data={description}
                     onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                     }}
                  />
               </div>
               <p className="error">{bodyError?.length > 0 && capitalizeFirstWord(bodyError?.[0])}</p>
               {/* <TextInput
                  type="text"
                  id="price"
                  name="price"
                  label="Price"
                  className="mb-3"
                  inputClassName="dateTime"
                  placeholder="#5,000"
               /> */}
               {/* <div className="">
                  <label htmlFor="" className="">
                     Tags
                  </label>
                  <Select
                     className="mb-3 text-capitalize"
                     multi
                     options={tagArr}
                     values={tagArr.find((opt) => opt.value === String(tagArr?.[0]?.label))}
                     onChange={(values) => setTag(values)}
                  />
               </div> */}
               {/* <div className="">
                  <label htmlFor="" className="">
                     Status
                  </label>
                  <Select
                     className="mb-3 text-capitalize"
                     name={`${newArr?.[0]?.label}`}
                     options={newArr}
                     values={newArr.find((opt) => opt.value === String(mealCategories?.[0]?.label))}
                     onChange={(values) => setMenuType(values?.[0]?.label)}
                  />
               </div> */}
               <button type="submit" className="save w-100 mt-4" disabled={!canSubmit}>
                  {IsCreatingBlog ? <CircularProgress color="inherit" thickness={1} /> : 'Save'}
               </button>
            </Formsy>
         </div>
      </FullscreenModal>
   );
};

export default AddBlog;
