import {combineReducers} from 'redux';
import {orderConstants} from '../../_constants';
import {updateObject, updateItemInArray} from '../../_helpers';

const IsRequestingAllOrders = (state = false, action) => {
   switch (action.type) {
      case orderConstants.REQUEST_ALL_ORDERS:
         return true;
      case orderConstants.REQUEST_ALL_ORDERS_ERROR:
         return false;
      case orderConstants.REQUEST_ALL_ORDERS_SUCCESS:
         return false;
      case orderConstants.REQUEST_ALL_ORDERS_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsRequestingAnOrder = (state = false, action) => {
   switch (action.type) {
      case orderConstants.REQUEST_AN_ORDER:
         return true;
      case orderConstants.REQUEST_AN_ORDER_ERROR:
         return false;
      case orderConstants.REQUEST_AN_ORDER_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsSearchingOrder = (state = false, action) => {
   switch (action.type) {
      case orderConstants.REQUEST_SEARCH_ORDER:
         return true;
      case orderConstants.SEARCH_ORDER_ERROR:
         return false;
      case orderConstants.SEARCH_ORDER_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsUpdatingOrder = (state = false, action) => {
   switch (action.type) {
      case orderConstants.REQUEST_UPDATE_ORDER:
         return true;
      case orderConstants.UPDATE_ORDER_SUCCESS:
         return false;
      case orderConstants.UPDATE_ORDER_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingOrderSuccess = (state = false, action) => {
   switch (action.type) {
      case orderConstants.REQUEST_UPDATE_ORDER:
         return false;
      case orderConstants.UPDATE_ORDER_SUCCESS:
         return true;
      case orderConstants.UPDATE_ORDER_ERROR:
         return false;
      default:
         return state;
   }
};

const orderDetails = (state = null, {type, order}) => {
   switch (type) {
      case orderConstants.REQUEST_AN_ORDER_SUCCESS:
         return updateObject(state, order.data);
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   state_id: '',
   industry_id: '',
   specialization_id: '',
   closing_date: '',
   isPublished: '',
};

const recentOrderList = (state = [], action) => {
   switch (action.type) {
      case orderConstants.REQUEST_RECENT_ORDERS_SUCCESS: {
         return action.recentOrders.data;
      }
      default:
         return state;
   }
};

const orderList = (state = [], action) => {
   switch (action.type) {
      case orderConstants.REQUEST_ALL_ORDERS_SUCCESS: {
         return action.orders.data.docs;
      }
      case orderConstants.SEARCH_ORDER_SUCCESS: {
         return action.order.data.docs;
      }
      case orderConstants.SEARCH_ORDER_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case orderConstants.UPDATE_ORDER_SUCCESS: {
         const newList = updateItemInArray(state, action.order._id, true, (order) => {
            return updateObject(order, action.order);
         });

         return newList;
      }
      case orderConstants.DELETE_ORDER_SUCCESS: {
         return state.filter((order) => order._id !== action._id);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case orderConstants.UPDATE_ORDER_ERROR:
         return updateObject(state, error);
      case orderConstants.REQUEST_UPDATE_ORDER:
         return null;
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case orderConstants.REQUEST_ALL_ORDERS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} = action.orders.data;
         const result = {hasNextPage, nextPage, totalDocs, totalPages, limit, page};

         return updateObject(state, result);
      }
      case orderConstants.SEARCH_ORDER_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, limit, page} = action.order.data;
         const result = {hasNextPage, nextPage, totalDocs, limit, page};

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingAllOrders,
   IsRequestingAnOrder,
   IsSearchingOrder,
   IsUpdatingOrder,
   IsUpdatingOrderSuccess,
   orderDetails,
   recentOrderList,
   orderList,
   errorMessage,
   pagination,
});

export default rootReducer;
