import React, {useState, useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// import Select from 'react-dropdown-select';
import CircularProgress from '@material-ui/core/CircularProgress';
import FullscreenModal from '../..';
import Formsy from 'formsy-react';
import {TextArea, TextInput} from '../../../CustomInput';
// import {mealCategories} from '../../../../_constants';
import {toFormData, capitalizeFirstWord} from '../../../../_helpers';
import {categoryActions} from '../../../../_actions';
import './index.scss';

import {ReactComponent as CloseIcon} from '../../../../_assets/icons/close.svg';

// const availability = [
//    {id: 1, label: 'Available'},
//    {id: 2, label: 'Unavailable'},
// ];

const fileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
function validFileTypes(file) {
   return fileTypes.includes(file.type);
}

const AddCategory = ({modalOpen, setModalOpen, type, categoryDetails}) => {
   const input = useRef();
   const dispatch = useDispatch();
   const {
      IsCreatingCategory,
      IsUpdatingCategory,
      categoryCreatedSuccessfully,
      categoryUpdatedSuccessfully,
      errorMessage,
   } = useSelector((s) => s.category);

   const [canSubmit, setCanSubmit] = useState(false);
   const [image, setImage] = useState(categoryDetails?.img_url || null);
   const [imageData, setImageData] = useState();
   const [imageError, setImageError] = useState('');
   const [imageDataError, setImageDataError] = useState('');

   const handleChange = () => {
      const files = input.current.files;

      if (files?.length) {
         if (validFileTypes(files[0])) {
            setImage(URL.createObjectURL(files[0]));
            setImageData(files[0]);
            setImageError('');
            input.current.files = null;
            if (files[0]?.size > 1048576) {
               setImageDataError('Image size should not exceed 1MB');
            } else {
               setImageDataError('');
            }
         } else {
            setImageError('Selected file is not an image type, please choose one of types: .png, .jpg, .png');
            input.current.files = null;
         }
      }
   };

   const handleSubmit = (data) => {
      if (data.name) {
         if (imageData?.size > 1048576) {
            setImageDataError('Image size should not exceed 1MB');
         } else {
            const fData = toFormData(data);
            imageData && fData.append('file', imageData);
            if (categoryDetails) {
               dispatch(categoryActions.updateCategory({fields: fData, _id: categoryDetails?._id}));
            } else {
               dispatch(categoryActions.createCategory(fData));
            }
         }
      }
   };

   useEffect(() => {
      if (categoryCreatedSuccessfully || categoryUpdatedSuccessfully) {
         setModalOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [categoryCreatedSuccessfully, categoryUpdatedSuccessfully]);

   const nameError = errorMessage?.message.filter((error) => error.split(' ')[0] === 'name');
   const descriptionError = errorMessage?.message.filter((error) => error.split(' ')[0] === 'description');

   return (
      <FullscreenModal open={modalOpen} onClickAway={() => setModalOpen(false)}>
         <div className="addMenu px-md-5 px-3 py-md-4 py-3 position-relative">
            <div className="position-absolute" style={{top: '1rem', right: '1.5rem'}}>
               <CloseIcon onClick={() => setModalOpen(false)} style={{transform: 'scale(.7)', cursor: 'pointer'}} />
            </div>
            <h5 className="text-center">{type === 'add' ? 'Add Category' : 'Edit Category'}</h5>
            <h6 className="text-center pt-2">Enter category details and upload them.</h6>
            <div className="py-3 my-3">
               <span className="">Add Image</span>
               <div className="mt-1 d-flex align-items-center">
                  <div className="img me-3">
                     <img src={image || categoryDetails?.img_url} alt="" className="h-100 w-100" />
                  </div>
                  <div className="">
                     <button onClick={() => input.current.click()} className="choose">
                        Choose Image
                     </button>
                     <p className="">JPG or PNG not more than 1MB</p>
                     <p className="error">{imageDataError}</p>
                     <input
                        ref={input}
                        onClick={(e) => (input.current.value = null)}
                        onChange={handleChange}
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className=""
                        style={{display: 'none'}}
                     />
                  </div>
               </div>
            </div>
            <Formsy
               className="w-100"
               onValidSubmit={handleSubmit}
               onValid={() => setCanSubmit(true)}
               onInvalid={() => setCanSubmit(false)}>
               <TextInput
                  type="text"
                  id="name"
                  name="name"
                  label="Category Name"
                  className="mb-3"
                  inputClassName="dateTime"
                  placeholder="Breakfast"
                  value={categoryDetails?.name}
               />
               <p className="error">{nameError?.length > 0 && capitalizeFirstWord(nameError?.[0])}</p>
               <TextArea
                  id="description"
                  type="text"
                  label="Description"
                  name="description"
                  className="mb-2"
                  placeholder="English breakfast scrambled eggs toast grilled tomatoes baked beans Bacon mushroom and sausage"
                  rows={3}
                  value={categoryDetails?.description}
               />
               <p className="error">{descriptionError?.length > 0 && capitalizeFirstWord(descriptionError?.[0])}</p>
               {/* <div className="server-error mb-2">
                  <p>{errorMessage?.message[0]}</p>
               </div> */}
               <button type="submit" className="save w-100 mt-4" disabled={!canSubmit}>
                  {IsCreatingCategory || IsUpdatingCategory ? (
                     <CircularProgress color="inherit" thickness={1} />
                  ) : (
                     'Save'
                  )}
               </button>
            </Formsy>
         </div>
      </FullscreenModal>
   );
};

export default AddCategory;
