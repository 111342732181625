import React, {useState, useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import Formsy from 'formsy-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {TextArea, TextInput} from '../../_components';
import Select from 'react-dropdown-select';
import {readableDateString, toFormData} from '../../_helpers';
import MenuComments from './MenuComments';
import {appActions, categoryActions, menuActions, tagActions} from '../../_actions';
import {frontPageArr, priorityArr} from '../../_constants';
import './index.scss';

import logoImg from '../../_assets/images/LTHLogo.png';
import {ReactComponent as Prev} from '../../_assets/icons/arrowLeft.svg';

// const availability = [
//    {id: 1, label: 'Available'},
//    {id: 2, label: 'Unavailable'},
// ];

const fileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
function validFileTypes(file) {
   return fileTypes.includes(file.type);
}

const MenuView = () => {
   const input = useRef();
   const dispatch = useDispatch();
   const {state} = useLocation();
   const mealDetails = state?.mealDetails;
   const navigate = useNavigate();
   const {categoriesList} = useSelector((s) => s.category);
   const {tagsList} = useSelector((s) => s.tag);
   const {IsUpdatingMenu, menuDetails} = useSelector((s) => s.menu);

   const [category, setCategory] = useState([]);
   // const [category, setCategory] = useState(menuDetails?.categories || []);
   const [frontPage, setFrontPage] = useState(menuDetails?.frontPage || 0);
   const [tag, setTag] = useState([]);
   // const [tag, setTag] = useState(menuDetails?.tags || []);
   const [image, setImage] = useState(menuDetails?.image_url || null);
   const [imageData, setImageData] = useState();
   const [imageError, setImageError] = useState('');

   useEffect(() => {
      if (mealDetails) {
         dispatch(menuActions.getAMenu({_id: mealDetails?._id}));
      }
      if (categoriesList.length === 0) {
         dispatch(categoryActions.getAllCategories());
      }
      if (tagsList.length === 0) {
         dispatch(tagActions.getAllTags());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [mealDetails]);

   useEffect(() => {
      if (menuDetails) {
         setCategory(menuDetails?.categories);
         setTag(menuDetails?.tags);
         setFrontPage(menuDetails?.frontPage);
         setImage(menuDetails?.image_url);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [menuDetails]);

   const newArr = categoriesList.map((v) => ({
      ...v,
      label: v.name,
      value: v._id,
   }));

   const tagArr = tagsList.map((v) => ({
      ...v,
      label: v.name,
      value: v._id,
   }));

   const handleGoBack = () => {
      navigate(-1);
      dispatch(appActions.clearMessages());
   };

   const handleChange = () => {
      const files = input.current.files;

      if (files.length) {
         if (validFileTypes(files[0])) {
            setImage(URL.createObjectURL(files[0]));
            setImageData(files[0]);
            setImageError('');
            input.current.files = null;
         } else {
            setImageError('Selected file is not an image type, please choose one of types: .png, .jpg, .png');
            input.current.files = null;
         }
      }
   };

   const handleSubmit = (data) => {
      if (data.name) {
         const fData = toFormData(data);
         imageData && fData.append('file', imageData);
         fData.append('_id', mealDetails?._id);
         if (category.length === 1) {
            fData.append('categories[]', category[0]?.value || category[0]);
         } else {
            for (var i = 0; i < category.length; i++) {
               fData.append('categories', category[i]?.value || category[i]);
            }
         }

         if (tag.length === 1) {
            fData.append('tags[]', tag[0].value || tag[0]);
         } else {
            for (var j = 0; j < tag.length; j++) {
               fData.append('tags', tag[j].value || tag[j]);
            }
         }
         // if (frontPage === 1) {
         fData.append('frontPage', frontPage);
         // }
         // if (frontPage === 1) {
         //    fData.append('priority', priority);
         // }

         dispatch(menuActions.updateMenu({fields: fData, _id: mealDetails?._id}));
      }
   };

   return (
      <div className="p-4">
         <div className="menuView">
            <div className="back mb-4 ps-2">
               <button onClick={handleGoBack} className="d-inline-flex align-items-center">
                  <Prev className="icon me-2" cursor="pointer" />
                  Back
               </button>
            </div>

            <div className="row m-0">
               <div className="col-md-4 col-12 py-3">
                  <div className="mealDetails p-4">
                     <Formsy className="w-100" onValidSubmit={handleSubmit}>
                        <div className="img d-flex align-items-center justify-content-center">
                           {mealDetails?.img_url ? (
                              <img src={image || mealDetails?.img_url} alt="" className="w-100 h-100" />
                           ) : (
                              <img src={logoImg} alt="" style={{transform: 'scale(1)'}} />
                           )}
                        </div>
                        <button onClick={() => input.current.click()} className="change my-2">
                           Change Image
                        </button>
                        <input
                           ref={input}
                           onClick={(e) => (input.current.value = null)}
                           onChange={handleChange}
                           type="file"
                           accept=".png, .jpg, .jpeg"
                           className=""
                           style={{display: 'none'}}
                        />
                        <TextInput
                           type="text"
                           id="name"
                           name="name"
                           label="Name"
                           className="mb-3"
                           inputClassName="dateTime"
                           placeholder="Breakfast"
                           // value={menuDetails?.name || mealDetails?.name}
                           value={menuDetails?.name}
                        />
                        <div className="mt-3">
                           <label htmlFor="" className="">
                              Category
                           </label>
                           {category?.length > 0 && (
                              <Select
                                 className="mb-3 text-capitalize"
                                 multi
                                 options={newArr}
                                 values={
                                    category.length > 0 ? newArr.filter((opt) => category.includes(opt.value)) : []
                                 }
                                 onChange={(values) => setCategory(values)}
                              />
                           )}
                        </div>
                        <TextArea
                           id="description"
                           type="text"
                           label="Description"
                           name="description"
                           className="mb-2"
                           placeholder=""
                           value={menuDetails?.description || mealDetails?.description}
                           rows={3}
                        />
                        {/* <div className="">
                           <label htmlFor="" className="">
                              Status
                           </label>
                           <Select
                              className="mb-3 text-capitalize"
                              options={newArr}
                              values={newArr.find((opt) => opt.value === String(mealCategories?.[0]?.label))}
                              onChange={(values) => setMenuType(values?.[0]?.label)}
                           />
                        </div> */}
                        <TextInput
                           type="text"
                           id="price"
                           name="price"
                           label="Price"
                           className="mb-3"
                           inputClassName="dateTime"
                           placeholder="5000"
                           value={menuDetails?.price || mealDetails?.price}
                        />
                        <div className="mt-3">
                           <label htmlFor="" className="">
                              Tag
                           </label>
                           {tag?.length > 0 && (
                              <Select
                                 className="mb-3 text-capitalize"
                                 multi
                                 options={tagArr}
                                 backspaceDelete
                                 values={tag.length > 0 ? tagArr.filter((opt) => tag.includes(opt.value)) : []}
                                 onChange={(values) => setTag(values)}
                              />
                           )}
                        </div>
                        <div className="mt-3">
                           <label htmlFor="" className="">
                              Make front page?
                           </label>
                           <Select
                              className="mb-3 text-capitalize"
                              options={frontPageArr}
                              values={
                                 frontPage === 0 || frontPage === 1
                                    ? [frontPageArr.find((opt) => opt.value === frontPage)]
                                    : []
                              }
                              backspaceDelete
                              // values={newArr.find((opt) => opt.value === String(newArr?.[0]?.label))}
                              onChange={(values) => setFrontPage(values[0].value)}
                           />
                        </div>
                        {/* <div className="mt-3">
                           <label htmlFor="" className="">
                              Priority
                           </label>
                           <Select
                              className="mb-3 text-capitalize"
                              options={priorityArr}
                              backspaceDelete
                              onChange={(values) => setPriority(values[0].value)}
                           />
                        </div> */}
                        <TextInput
                           id="date_time"
                           type="text"
                           label="Last Updated"
                           name="date_time"
                           className="mb-2"
                           inputClassName="dateTime"
                           placeholder="26/06/2022, 12:31 AM"
                           value={readableDateString(menuDetails?.updatedAt || mealDetails?.updatedAt)}
                           disabled
                        />
                        <button className="save w-100 mt-4">
                           {IsUpdatingMenu ? <CircularProgress color="inherit" thickness={1} /> : 'Update Changes'}
                        </button>
                     </Formsy>
                  </div>
               </div>

               <MenuComments
                  comments={menuDetails?.comments || mealDetails?.comments}
                  mealId={menuDetails?._id || mealDetails?._id}
               />
            </div>
         </div>
      </div>
   );
};

export default MenuView;
