import {combineReducers} from 'redux';
import {paymentConstants} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsRequestingPayments = (state = false, action) => {
   switch (action.type) {
      case paymentConstants.REQUEST_ALL_PAYMENTS:
         return true;
      case paymentConstants.REQUEST_ALL_PAYMENTS_ERROR:
         return false;
      case paymentConstants.REQUEST_ALL_PAYMENTS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsSearchingPayment = (state = false, action) => {
   switch (action.type) {
      case paymentConstants.REQUEST_SEARCH_PAYMENT:
         return true;
      case paymentConstants.SEARCH_PAYMENT_ERROR:
         return false;
      case paymentConstants.SEARCH_PAYMENT_SUCCESS:
         return false;
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   image_uri: '',
   number: '',
   published: '',
};

const paymentsList = (state = [], action) => {
   switch (action.type) {
      case paymentConstants.REQUEST_ALL_PAYMENTS_SUCCESS: {
         return action.payments.data.docs;
      }
      case paymentConstants.REQUEST_ALL_PAYMENTS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case paymentConstants.SEARCH_PAYMENT_SUCCESS: {
         return action.payment.data;
      }
      case paymentConstants.SEARCH_PAYMENT_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case paymentConstants.REQUEST_ALL_PAYMENTS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} = action.payments.data;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      // case paymentConstants.CREATE_REQUEST_SEARCH_PAYMENT_SUCCESS: {
      //    console.log('state', state);
      //    const {hasNextPage, nextPage, totalDocs, limit, page} = state;
      //    const TotalDocs = totalDocs + 1;
      //    const result = {hasNextPage, nextPage, TotalDocs, limit, page};
      //    console.log('result', result);

      //    return updateObject(state, result);
      // }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   paymentsList,
   IsRequestingPayments,
   IsSearchingPayment,
   pagination,
});

export default rootReducer;
