import {paymentConstants} from '../_constants';

const getAllPayments = (model) => ({
   type: paymentConstants.GET_ALL_PAYMENTS,
   data: model,
});

const searchPayment = (model) => ({
   type: paymentConstants.SEARCH_PAYMENT,
   data: model,
});

export const paymentActions = {
   getAllPayments,
   searchPayment,
};
