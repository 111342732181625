import {combineReducers} from 'redux';
import {appConstants, menuConstants} from '../../_constants';
import {updateObject, updateItemInArray} from '../../_helpers';

const IsRequestingAllMenus = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_ALL_MENUS:
         return true;
      case menuConstants.REQUEST_ALL_MENUS_ERROR:
         return false;
      case menuConstants.REQUEST_ALL_MENUS_SUCCESS:
         return false;
      case menuConstants.REQUEST_CATEGORY_MENUS:
         return true;
      case menuConstants.REQUEST_CATEGORY_MENUS_ERROR:
         return false;
      case menuConstants.REQUEST_CATEGORY_MENUS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingAMenu = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_A_MENU:
         return true;
      case menuConstants.REQUEST_A_MENU_ERROR:
         return false;
      case menuConstants.REQUEST_A_MENU_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsSearchingMenu = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_SEARCH_MENU:
         return true;
      case menuConstants.SEARCH_MENU_ERROR:
         return false;
      case menuConstants.SEARCH_MENU_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsCreatingMenu = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_CREATE_MENU:
         return true;
      case menuConstants.CREATE_MENU_SUCCESS:
         return false;
      case menuConstants.CREATE_MENU_ERROR:
         return false;
      default:
         return state;
   }
};

const menuCreatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_CREATE_MENU:
         return false;
      case menuConstants.CREATE_MENU_SUCCESS:
         return true;
      case menuConstants.CREATE_MENU_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingMenu = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_UPDATE_MENU:
         return true;
      case menuConstants.UPDATE_MENU_SUCCESS:
         return false;
      case menuConstants.UPDATE_MENU_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingMenuSuccess = (state = false, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_UPDATE_MENU:
         return false;
      case menuConstants.UPDATE_MENU_SUCCESS:
         return true;
      case menuConstants.UPDATE_MENU_ERROR:
         return false;
      default:
         return state;
   }
};

const menuDetails = (state = null, {type, menu}) => {
   switch (type) {
      case menuConstants.REQUEST_A_MENU_SUCCESS:
         return updateObject(state, menu.data);
      case appConstants.CLEAR_MESSAGES:
         return null;
      case menuConstants.DELETE_MENU_COMMENT_SUCCESS:
         return updateObject(state, menu.data);
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   state_id: '',
   industry_id: '',
   specialization_id: '',
   closing_date: '',
   isPublished: '',
};

const allMenuList = (state = [], action) => {
   switch (action.type) {
      case menuConstants.REQUEST_ALL_MENUS_WITHOUT_PAGINATION_SUCCESS: {
         return action.allMenus.data;
      }
      default:
         return state;
   }
};

const menuList = (state = [], action) => {
   switch (action.type) {
      case menuConstants.REQUEST_ALL_MENUS_SUCCESS: {
         return action.menus.data.docs;
      }
      case menuConstants.REQUEST_CATEGORY_MENUS_SUCCESS: {
         return action.categoryMenus.data.docs;
      }
      case menuConstants.SEARCH_MENU_SUCCESS: {
         return action.menu.data.docs;
      }
      case menuConstants.SEARCH_MENU_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case menuConstants.CREATE_MENU_SUCCESS: {
         const newList = [...state, action.menu.data];

         return newList;
      }
      case menuConstants.UPDATE_MENU_SUCCESS: {
         const newList = updateItemInArray(state, action.menu._id, true, (menu) => {
            return updateObject(menu, action.menu);
         });

         return newList;
      }
      case menuConstants.DELETE_MENU_SUCCESS: {
         return state.filter((menu) => menu._id !== action._id);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case menuConstants.CREATE_MENU_ERROR:
         return updateObject(state, error);
      case menuConstants.UPDATE_MENU_ERROR:
         return updateObject(state, error);
      case menuConstants.REQUEST_UPDATE_MENU:
         return null;
      case menuConstants.REQUEST_CREATE_MENU:
         return null;
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case menuConstants.REQUEST_ALL_MENUS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} = action.menus.data;
         const result = {hasNextPage, nextPage, totalDocs, totalPages, limit, page};

         return updateObject(state, result);
      }
      case menuConstants.REQUEST_CATEGORY_MENUS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} = action.categoryMenus.data;
         const result = {hasNextPage, nextPage, totalDocs, totalPages, limit, page};

         return updateObject(state, result);
      }
      case menuConstants.SEARCH_MENU_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, limit, page} = action.menu.data;
         const result = {hasNextPage, nextPage, totalDocs, limit, page};

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingAllMenus,
   IsRequestingAMenu,
   IsSearchingMenu,
   IsCreatingMenu,
   menuCreatedSuccessfully,
   IsUpdatingMenu,
   IsUpdatingMenuSuccess,
   menuDetails,
   allMenuList,
   menuList,
   errorMessage,
   pagination,
});

export default rootReducer;
