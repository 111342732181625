import {appConstants} from './app.constant';

const payment = 'payment';

export const paymentConstants = {
   REQUEST_ALL_PAYMENTS: 'REQUEST_ALL_PAYMENTS',
   REQUEST_ALL_PAYMENTS_SUCCESS: 'REQUEST_ALL_PAYMENTS_SUCCESS',
   REQUEST_ALL_PAYMENTS_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_PAYMENTS_SUCCESS_WITHOUT_DATA',
   REQUEST_ALL_PAYMENTS_ERROR: 'REQUEST_ALL_PAYMENTS_ERROR',

   REQUEST_SEARCH_PAYMENT: 'REQUEST_SEARCH_PAYMENT',
   SEARCH_PAYMENT_SUCCESS: 'SEARCH_PAYMENT_SUCCESS',
   SEARCH_PAYMENT_ERROR: 'SEARCH_PAYMENT_ERROR',
   SEARCH_PAYMENT_SUCCESS_WITHOUT_DATA: 'SEARCH_PAYMENT_SUCCESS_WITHOUT_DATA',

   GET_ALL_PAYMENTS: 'GET_ALL_PAYMENTS',
   SEARCH_PAYMENT: 'SEARCH_PAYMENT',

   PAYMENT_URI: `${appConstants.BASE_URI}${payment}`,
};
