import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {readableDateString} from '../../_helpers';
import {AddCategory} from '../../_components';
import {categoryActions} from '../../_actions';
import Dialog from '../../_components/Modal/Group/Dialog';
import LetteredAvatar from 'lettered-avatar';

// import logoImg from '../../_assets/images/LTHLogo.png';
import {ReactComponent as DeleteIcon} from '../../_assets/icons/deleteBucket.svg';
import {ReactComponent as EditIcon} from '../../_assets/icons/editPen.svg';

const CategoryRow = ({data, _id, img, img_url, updatedAt, name, description, totalMeals, stars}) => {
   const dispatch = useDispatch();

   const [category, setCategory] = useState(false);
   const [editCat, setEdtCat] = useState(null);

   const [deleteCategory, setDeleteCategory] = useState(false);
   const [categoryData, setCategoryData] = useState({});

   const toggleDelete = (row) => {
      setCategoryData(row);
      setDeleteCategory(!deleteCategory);
   };

   const handleDelete = () => {
      const _id = categoryData?._id;

      if (_id) {
         dispatch(categoryActions.deleteCategory(_id));
      }
      setDeleteCategory(false);
   };

   const handleEditCategory = (item) => {
      setCategory(true);
      setEdtCat(data);
   };

   return (
      <tr key={`recentOrderList-${_id}`} className="d-table-row cat">
         <th scope="col" className="d-flex align-items-center pe-0">
            <div className="img me-2 d-flex align-items-center justify-content-center">
               {img_url ? (
                  <img src={img_url} alt="" className="h-100 w-100" />
               ) : (
                  <LetteredAvatar
                     name={name}
                     options={{
                        size: 50,
                        twoLetter: false,
                        //shape: 'square',
                        bgColor: '',
                        imgClass: 'img-fluid user-image',
                     }}
                  />
               )}
            </div>
            <p className="mealName">{name}</p>
         </th>
         <td className="align-middle">
            <p className="desc">{description}</p>
         </td>
         <td className="text-nowrap align-middle">{totalMeals}</td>
         <td className="text-nowrap align-middle">{readableDateString(updatedAt)}</td>
         <td className="text-nowrap align-middle">
            <span className="available">Available</span>
         </td>
         <td className="text-nowrap align-middle">
            <div className="d-flex align-items-center">
               <DeleteIcon
                  onClick={() => toggleDelete(data)}
                  className="me-3"
                  style={{transform: 'scale(.7)', cursor: 'pointer'}}
               />
               <Dialog
                  modalOpen={deleteCategory}
                  title="Category"
                  handleDelete={handleDelete}
                  setModalOpen={setDeleteCategory}
               />
               <EditIcon
                  onClick={() => handleEditCategory(data)}
                  className="ms-3"
                  style={{transform: 'scale(.7)', cursor: 'pointer'}}
               />
            </div>
            <AddCategory modalOpen={category} type="edit" setModalOpen={setCategory} categoryDetails={editCat} />
         </td>
      </tr>
   );
};

export default CategoryRow;
