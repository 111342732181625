import React from 'react';
import {useMatch, readableDateString, GetTime} from '../../_helpers';
import './index.scss';

import {ReactComponent as VanIcon} from '../../_assets/icons/deliveryVan.svg';
import {ReactComponent as Avatar} from '../../_assets/icons/avatar.svg';

const Profile = ({data}) => {
   const orderRoute = useMatch('/orders/*');

   return (
      <div className="w-100 py-3">
         <div className="first p-4 h-100">
            <h4 className="pt-3">
               {orderRoute ? 'Order' : 'Reservation'} ID: #{orderRoute ? data.id : data.ticket_no}
            </h4>
            <div className="img mt-5 mb-3 d-flex align-items-center justify-content-center">
               {data.customerImg ? (
                  <img src={data.customerImg} alt="" className="w-100 h-100" />
               ) : (
                  <Avatar className="" style={{transform: 'scale(1)'}} />
               )}
            </div>
            <p className="customerName text-center">
               {orderRoute ? data.customer.firstname + ' ' + data.customer.lastname : data.fullname}
            </p>
            <p className="customer text-center">Customer</p>
            <h5 className="pt-4">{orderRoute ? 'Note Order' : 'Special Request'}:</h5>
            <p className="noteOrder pt-2 pb-4">{orderRoute ? data.order_notes : data.special_request}</p>
            <div className="d-flex align-items-center">
               {!orderRoute && (
                  <div className="icon me-3">
                     <VanIcon className="h-100 w-100" />
                  </div>
               )}
               <p className="address">
                  {!orderRoute ? `${readableDateString(data.createdAt)},  ${GetTime(data.createdAt)}` : data.address}
               </p>
            </div>
         </div>
      </div>
   );
};

export default Profile;
