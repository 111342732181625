import React, {useState, useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Select from 'react-dropdown-select';
import CircularProgress from '@material-ui/core/CircularProgress';
import FullscreenModal from '../..';
import Formsy from 'formsy-react';
import {TextArea, TextInput} from '../../../CustomInput';
// import {mealCategories} from '../../../../_constants';
import {toFormData, capitalizeFirstWord} from '../../../../_helpers';
import {menuActions} from '../../../../_actions';
import './index.scss';

import {ReactComponent as CloseIcon} from '../../../../_assets/icons/close.svg';

// const availability = [
//    {id: 1, label: 'Available'},
//    {id: 2, label: 'Unavailable'},
// ];

const fileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
function validFileTypes(file) {
   return fileTypes.includes(file.type);
}

const AddMenu = ({modalOpen, setModalOpen, menuDetails}) => {
   const input = useRef();
   const dispatch = useDispatch();
   const {categoriesList} = useSelector((s) => s.category);
   const {tagsList} = useSelector((s) => s.tag);
   const {IsCreatingMenu, menuCreatedSuccessfully, errorMessage} = useSelector((s) => s.menu);

   const [canSubmit, setCanSubmit] = useState(false);
   const [category, setCategory] = useState([]);
   const [tag, setTag] = useState([]);
   const [image, setImage] = useState(menuDetails?.image_url || null);
   const [imageData, setImageData] = useState();
   const [imageError, setImageError] = useState('');
   const [imageDataError, setImageDataError] = useState('');
   const [categoryError, setCategoryError] = useState('');
   const [priceError, setPriceError] = useState('');

   const newArr = categoriesList.map((v) => ({
      ...v,
      label: v.name,
      value: v._id,
   }));

   const tagArr = tagsList.map((v) => ({
      ...v,
      label: v.name,
      value: v._id,
   }));

   const handleChange = () => {
      const files = input.current.files;

      if (files.length) {
         if (validFileTypes(files[0])) {
            setImage(URL.createObjectURL(files[0]));
            setImageData(files[0]);
            setImageError('');
            input.current.files = null;
            if (files[0]?.size > 1048576) {
               setImageDataError('Image size should not exceed 1MB');
            } else {
               setImageDataError('');
            }
         } else {
            setImageError('Selected file is not an image type, please choose one of types: .png, .jpg, .png');
            input.current.files = null;
         }
      }
   };

   const handleSubmit = (data) => {
      setPriceError('');
      if (data.name && data.price) {
         if (imageData?.size > 1048576) {
            setImageDataError('Image size should not exceed 1MB');
         } else {
            if (category.length > 0) {
               const fData = toFormData(data);
               imageData && fData.append('file', imageData);
               if (category.length === 1) {
                  fData.append('categories[]', category[0].value);
               } else {
                  for (var i = 0; i < category.length; i++) {
                     fData.append('categories', category[i].value);
                  }
               }
               if (tag.length === 1) {
                  fData.append('tags[]', tag[0].value);
               } else {
                  for (var j = 0; j < tag.length; j++) {
                     fData.append('tags', tag[j].value);
                  }
               }

               dispatch(menuActions.createMenu(fData));
            } else {
               setCategoryError('Select at least one category');
            }
         }
      }
      // else {
      //    setPriceError(data?.price === undefined && 'Price is required');
      // }
   };

   useEffect(() => {
      if (menuCreatedSuccessfully) {
         setModalOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [menuCreatedSuccessfully]);

   const nameError = errorMessage?.message.filter((error) => error.split(' ')[0] === 'name');
   const descriptionError = errorMessage?.message.filter((error) => error.split(' ')[0] === 'description');
   const pricesError = errorMessage?.message.filter((error) => error.split(' ')[0] === 'price');

   return (
      <FullscreenModal open={modalOpen} onClickAway={() => setModalOpen(false)}>
         <div className="addMenu px-md-5 px-3 py-md-4 py-3 position-relative">
            <div className="position-absolute" style={{top: '1rem', right: '1.5rem'}}>
               <CloseIcon onClick={() => setModalOpen(false)} style={{transform: 'scale(.7)', cursor: 'pointer'}} />
            </div>
            <Formsy
               className="w-100"
               onValidSubmit={handleSubmit}
               onValid={() => setCanSubmit(true)}
               onInvalid={() => setCanSubmit(false)}>
               <h5 className="text-center">Add Menu</h5>
               <h6 className="text-center pt-2">Enter food details and upload them.</h6>
               <div className="py-3 my-3">
                  <span className="">Add Image</span>
                  <div className="mt-1 d-flex align-items-center">
                     <div className="img me-3">
                        <img src={image} alt="" className="h-100 w-100" />
                     </div>
                     <div className="">
                        <button onClick={() => input.current.click()} className="choose">
                           Choose Image
                        </button>
                        <p className="">JPG or PNG not more than 1MB</p>
                        <p className="error">{imageDataError}</p>
                        <input
                           ref={input}
                           onClick={(e) => (input.current.value = null)}
                           onChange={handleChange}
                           type="file"
                           accept=".png, .jpg, .jpeg"
                           className=""
                           style={{display: 'none'}}
                        />
                     </div>
                  </div>
               </div>
               <TextInput
                  type="text"
                  id="name"
                  name="name"
                  label="Meal Name"
                  className="mb-3"
                  inputClassName="dateTime"
                  placeholder="Breakfast"
                  required
               />
               <p className="error">{nameError?.length > 0 && capitalizeFirstWord(nameError?.[0])}</p>
               <div className="">
                  <label htmlFor="" className="">
                     Category
                  </label>
                  <Select
                     className="mb-3 text-capitalize"
                     // name={`${newArr?.[0]?.label}`}
                     multi
                     options={newArr}
                     values={newArr.find((opt) => opt.value === String(newArr?.[0]?.label))}
                     onChange={(values) => setCategory(values, setCategoryError(''))}
                  />
               </div>
               <p className="error">{categoryError}</p>
               <TextArea
                  id="description"
                  type="text"
                  label="Description"
                  name="description"
                  className="mb-2"
                  placeholder="English breakfast scrambled eggs toast grilled tomatoes baked beans Bacon mushroom and sausage"
                  rows={3}
                  required
               />
               <p className="error">{descriptionError?.length > 0 && capitalizeFirstWord(descriptionError?.[0])}</p>
               <TextInput
                  type="text"
                  id="price"
                  name="price"
                  label="Price"
                  className="mb-3"
                  inputClassName="dateTime"
                  placeholder="5,000"
                  required
               />
               <p className="error">
                  {priceError || (pricesError?.length > 0 && capitalizeFirstWord(pricesError?.[0]))}
               </p>
               <div className="">
                  <label htmlFor="" className="">
                     Tags
                  </label>
                  <Select
                     className="mb-3 text-capitalize"
                     // name={`${newArr?.[0]?.label}`}
                     multi
                     options={tagArr}
                     values={tagArr.find((opt) => opt.value === String(tagArr?.[0]?.label))}
                     onChange={(values) => setTag(values)}
                  />
               </div>
               {/* <div className="">
                  <label htmlFor="" className="">
                     Status
                  </label>
                  <Select
                     className="mb-3 text-capitalize"
                     name={`${newArr?.[0]?.label}`}
                     options={newArr}
                     values={newArr.find((opt) => opt.value === String(mealCategories?.[0]?.label))}
                     onChange={(values) => setMenuType(values?.[0]?.label)}
                  />
               </div> */}
               <button type="submit" className="save w-100 mt-4" disabled={!canSubmit}>
                  {IsCreatingMenu ? <CircularProgress color="inherit" thickness={1} /> : 'Save'}
               </button>
            </Formsy>
         </div>
      </FullscreenModal>
   );
};

export default AddMenu;
