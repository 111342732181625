import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import AdminRow from './AdminRow';
import Loader from '../../../_helpers/loader';
// import {recentOrders} from '../../../_constants';
import {Pagination} from '../../../_components';
import {userActions} from '../../../_actions';

const AdminUsers = ({tab}) => {
   const dispatch = useDispatch();

   const {IsRequestingUsers, usersList, pagination} = useSelector((s) => s.user);

   const {totalDocs, limit, page, totalPages} = pagination;
   const total = totalDocs;
   const pages = page - 1;

   // const [menuType, setMenuType] = useState(newArr?.[0]?.contents);
   useEffect(() => {
      dispatch(userActions.getUsers());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
      };

      dispatch(userActions.getUsers(model));
   };

   return (
      <div className={classNames('adminUsers flex-column', {'d-flex': tab === 4, 'd-none': tab !== 4})}>
         {IsRequestingUsers ? (
            <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
               <div />
            </Loader>
         ) : (
            <MDBTable responsive>
               <MDBTableHead>
                  <tr>
                     <th className="text-nowrap">First Name</th>
                     <th className="text-nowrap">Last Name</th>
                     <th className="text-nowrap">Email</th>
                     <th className="text-nowrap">User Status</th>
                     <th className="text-nowrap">Date Joined</th>
                     <th className="text-nowrap"></th>
                     {/* <th className="text-nowrap">Mobile No</th>
                     <th className="text-nowrap">Last Order</th> */}
                  </tr>
               </MDBTableHead>
               <MDBTableBody>
                  {usersList?.length > 0 ? (
                     usersList.map((item) => <AdminRow data={item} {...item} />)
                  ) : (
                     <td align="center" className="no-content" colSpan={5}>
                        No Admin user has been uploaded on this platform
                     </td>
                  )}
               </MDBTableBody>
            </MDBTable>
         )}
         {!IsRequestingUsers && totalPages > 1 && (
            <Pagination
               rowsPerPageOptions={[]}
               colSpan={2}
               count={total}
               rowsPerPage={limit}
               page={pages}
               totalPages={totalPages}
               onChangePage={handleChangePage}
            />
         )}
      </div>
   );
};

export default AdminUsers;
