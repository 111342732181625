import {combineReducers} from 'redux';
import authRootReducer from './auth.reducer';
import blogRootReducer from './blog.reducer';
import categoryRootReducer from './category.reducer';
import customerRootReducer from './customer.reducer';
import dashboardRootReducer from './dashboard.reducer';
import forgotPasswordRootReducer from './forgotPassword.reducer';
import menuRootReducer from './menu.reducer';
import orderRootReducer from './order.reducer';
import paymentRootReducer from './payment.reducer';
import reservationRootReducer from './reservation.reducer';
import setupRootReducer from './setup.reducer';
import snackbarRootReducer from './snackbar.reducer';
import tagRootReducer from './tag.reducer';
import userRootReducer from './user.reducer';

const rootReducer = combineReducers({
   auth: authRootReducer,
   blog: blogRootReducer,
   category: categoryRootReducer,
   customer: customerRootReducer,
   dashboard: dashboardRootReducer,
   forgotPassword: forgotPasswordRootReducer,
   menu: menuRootReducer,
   order: orderRootReducer,
   payment: paymentRootReducer,
   reservation: reservationRootReducer,
   setup: setupRootReducer,
   snackbar: snackbarRootReducer,
   tag: tagRootReducer,
   user: userRootReducer,
});

export default rootReducer;
