import {combineReducers} from 'redux';
import {blogConstants, appConstants} from '../../_constants';
import {updateObject, updateItemInArray} from '../../_helpers';

const IsRequestingBlogs = (state = false, action) => {
   switch (action.type) {
      case blogConstants.REQUEST_ALL_BLOGS:
         return true;
      case blogConstants.REQUEST_ALL_BLOGS_ERROR:
         return false;
      case blogConstants.REQUEST_ALL_BLOGS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsCreatingBlog = (state = false, action) => {
   switch (action.type) {
      case blogConstants.REQUEST_CREATE_BLOG:
         return true;
      case blogConstants.CREATE_BLOG_SUCCESS:
         return false;
      case blogConstants.CREATE_BLOG_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingBlog = (state = false, action) => {
   switch (action.type) {
      case blogConstants.REQUEST_UPDATE_BLOG:
         return true;
      case blogConstants.UPDATE_BLOG_SUCCESS:
         return false;
      case blogConstants.UPDATE_BLOG_ERROR:
         return false;
      default:
         return state;
   }
};

const blogCreatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case blogConstants.REQUEST_CREATE_BLOG:
         return false;
      case blogConstants.CREATE_BLOG_SUCCESS:
         return true;
      case blogConstants.CREATE_BLOG_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const blogUpdatedSuccessfully = (state = false, action) => {
   switch (action.type) {
      case blogConstants.REQUEST_UPDATE_BLOG:
         return false;
      case blogConstants.UPDATE_BLOG_SUCCESS:
         return true;
      case blogConstants.UPDATE_BLOG_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const IsSearchingBlog = (state = false, action) => {
   switch (action.type) {
      case blogConstants.REQUEST_SEARCH_BLOG:
         return true;
      case blogConstants.SEARCH_BLOG_ERROR:
         return false;
      case blogConstants.SEARCH_BLOG_SUCCESS:
         return false;
      default:
         return state;
   }
};

const blogDetail = (state = null, {type, blog}) => {
   switch (type) {
      case blogConstants.REQUEST_A_BLOG_SUCCESS:
         return updateObject(state, blog.data);
      case blogConstants.DELETE_BLOG_COMMENT_SUCCESS:
         return updateObject(state, blog.data);
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   image_uri: '',
   number: '',
   published: '',
};

const blogsList = (state = [], action) => {
   switch (action.type) {
      case blogConstants.REQUEST_ALL_BLOGS_SUCCESS: {
         return action.blogs.data.docs;
      }
      case blogConstants.REQUEST_ALL_BLOGS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case blogConstants.REQUEST_ALL_BLOGS_WITHOUT_PAGINATION_SUCCESS: {
         return action.blogs;
      }
      case blogConstants.SEARCH_BLOG_SUCCESS: {
         return action.blog.data.docs;
      }
      case blogConstants.SEARCH_BLOG_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case blogConstants.CREATE_BLOG_SUCCESS: {
         const newList = [...state, action.blog.data];

         return newList;
      }
      case blogConstants.UPDATE_BLOG_SUCCESS: {
         const newList = updateItemInArray(state, action.blog.data._id, true, (blog) => {
            return updateObject(blog, action.blog.data);
         });

         return newList;
      }
      case blogConstants.DELETE_BLOG_SUCCESS: {
         return state.filter((blog) => blog._id !== action._id);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case blogConstants.CREATE_BLOG_ERROR:
         return updateObject(state, error);
      case blogConstants.REQUEST_CREATE_BLOG:
         return null;
      case blogConstants.UPDATE_BLOG_ERROR:
         return updateObject(state, error);
      case blogConstants.REQUEST_UPDATE_BLOG:
         return null;
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case blogConstants.REQUEST_ALL_BLOGS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} = action.blogs.data;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      // case blogConstants.CREATE_BLOG_SUCCESS: {
      //    console.log('state', state);
      //    const {hasNextPage, nextPage, totalDocs, limit, page} = state;
      //    const TotalDocs = totalDocs + 1;
      //    const result = {hasNextPage, nextPage, TotalDocs, limit, page};
      //    console.log('result', result);

      //    return updateObject(state, result);
      // }
      // case blogConstants.DELETE_BLOG_SUCCESS: {
      //    console.log('state', state);
      //    const {hasNextPage, nextPage, totalDocs, limit, page} = state;
      //    const TotalDocs = totalDocs - 1;
      //    const result = {hasNextPage, nextPage, TotalDocs, limit, page};
      //    console.log('result', result);

      //    return updateObject(state, result);
      // }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   blogsList,
   IsRequestingBlogs,
   IsCreatingBlog,
   IsUpdatingBlog,
   blogCreatedSuccessfully,
   blogUpdatedSuccessfully,
   blogDetail,
   IsSearchingBlog,
   errorMessage,
   pagination,
});

export default rootReducer;
