import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {OrderSummary, Profile} from '../../_components';
import './index.scss';

import {ReactComponent as Prev} from '../../_assets/icons/arrowLeft.svg';

const ReservationView = () => {
   const {state} = useLocation();
   const reservationDetails = state.reservationDetails;
   const navigate = useNavigate();
   const [startDelivery, setStartDelivery] = useState(false);

   const handleGoBack = () => {
      navigate(-1);
   };

   return (
      <div className="p-4">
         <div className="OrderView reservationsView">
            <div className="back mb-4 ps-2">
               <button onClick={handleGoBack} className="d-inline-flex align-items-center">
                  <Prev className="icon me-2" cursor="pointer" />
                  Back
               </button>
            </div>

            <div className="OrderDetails row m-0">
               <Profile data={reservationDetails} />
               <OrderSummary
                  data={reservationDetails}
                  startDelivery={startDelivery}
                  setStartDelivery={setStartDelivery}
               />
            </div>
         </div>
      </div>
   );
};

export default ReservationView;
