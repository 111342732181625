import {appConstants} from './app.constant';

const category = 'category';

export const categoryConstants = {
   REQUEST_CREATE_CATEGORY: 'REQUEST_CREATE_CATEGORY',
   CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
   CREATE_CATEGORY_ERROR: 'CREATE_CATEGORY_ERROR',

   REQUEST_ALL_CATEGORIES: 'REQUEST_ALL_CATEGORIES',
   REQUEST_ALL_CATEGORIES_SUCCESS: 'REQUEST_ALL_CATEGORIES_SUCCESS',
   REQUEST_ALL_CATEGORIES_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_CATEGORIES_SUCCESS_WITHOUT_DATA',
   REQUEST_ALL_CATEGORIES_ERROR: 'REQUEST_ALL_CATEGORIES_ERROR',

   REQUEST_A_CATEGORY: 'REQUEST_A_CATEGORY',
   REQUEST_A_CATEGORY_SUCCESS: 'REQUEST_A_CATEGORY_SUCCESS',
   REQUEST_A_CATEGORY_ERROR: 'REQUEST_A_CATEGORY_ERROR',

   REQUEST_SEARCH_CATEGORY: 'REQUEST_SEARCH_CATEGORY',
   SEARCH_CATEGORY_SUCCESS: 'SEARCH_CATEGORY_SUCCESS',
   SEARCH_CATEGORY_ERROR: 'SEARCH_CATEGORY_ERROR',
   SEARCH_CATEGORY_SUCCESS_WITHOUT_DATA: 'SEARCH_CATEGORY_SUCCESS_WITHOUT_DATA',

   REQUEST_DELETE_CATEGORY: 'REQUEST_DELETE_CATEGORY',
   DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
   DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',

   REQUEST_UPDATE_CATEGORY: 'REQUEST_UPDATE_CATEGORY',
   UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
   UPDATE_CATEGORY_ERROR: 'UPDATE_CATEGORY_ERROR',

   GET_ALL_CATEGORIES: 'GET_ALL_CATEGORIES',
   CREATE_CATEGORY: 'CREATE_CATEGORY',
   SEARCH_CATEGORY: 'SEARCH_CATEGORY',

   DELETE_CATEGORY: 'DELETE_CATEGORY',
   UPDATE_CATEGORY: 'UPDATE_CATEGORY',
   GET_A_CATEGORY: 'GET_A_CATEGORY',

   CATEGORY_URI: `${appConstants.BASE_URI}${category}`,
};
