import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import './index.scss';

import AdminUsers from './_components/AdminUsers';
import Payments from './_components/Payments';
import Profile from './_components/Profile';
import Security from './_components/Security';
import {AdminDetails} from '../../_components';

const settingsTabs = [
   {id: 1, label: 'Account/Profile', activeTab: 1},
   {id: 2, label: 'Security', activeTab: 2},
   // {id: 3, label: 'Payment Settings', activeTab: 3},
   {id: 4, label: 'Admin Users', activeTab: 4},
];

const Settings = () => {
   const [tab, setTab] = useState(1);
   const [adminModal, setAdminModal] = useState(false);
   const {userDetails} = useSelector((s) => s.user);

   return (
      <div className="p-4">
         <div className="settings py-4">
            <div className="px-4 d-flex align-items-start justify-content-between">
               <ul className="tabs">
                  {settingsTabs.map((item) => (
                     <li
                        key={`settings-tab-${item.id}`}
                        className={classNames('me-5 pb-1 text-nowrap', {active: tab === item.activeTab})}
                        onClick={() => setTab(item.activeTab)}>
                        {item.label}
                     </li>
                  ))}
               </ul>
               {tab === 4 && userDetails?.role === 1 && (
                  <>
                     <button onClick={() => setAdminModal(true)} className="adminUserBtn text-nowrap">
                        Add Admin User
                     </button>
                     <AdminDetails type="add" modalOpen={adminModal} setModalOpen={setAdminModal} />
                  </>
               )}
            </div>

            <div className="py-5">
               <Profile tab={tab} />
               <Security tab={tab} />
               <Payments tab={tab} />
               <AdminUsers tab={tab} />
            </div>
         </div>
      </div>
   );
};

export default Settings;
