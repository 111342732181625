import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {reservationConstants, appConstants, userConstants} from '../../_constants';
import {
   createRequest,
   getObjectFromStorage,
   checkStatus,
   clearObjectFromStorage,
   createRequestWithToken,
} from '../../_helpers';

function* getAllReservations({data}) {
   yield put({type: reservationConstants.REQUEST_ALL_RESERVATIONS});
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      let reservationUri = `${reservationConstants.RESERVATION_URI}`;
      if (data?.page) {
         reservationUri = `${reservationUri}?page=${data.page + 1}`;
      }
      // const req = yield call(createRequest, reservationUri, {method: 'GET'});
      const req = createRequestWithToken(reservationUri, {
         method: 'GET',
      })(user?.accessToken);
      const response = yield call(fetch, req);

      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: reservationConstants.REQUEST_ALL_RESERVATIONS_SUCCESS, reservations: jsonResponse});
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({type: reservationConstants.REQUEST_ALL_RESERVATIONS_ERROR});
   }
}

function* getAReservation({data}) {
   yield put({type: reservationConstants.REQUEST_A_RESERVATION});

   try {
      let reservationsUri = `${appConstants.BASE_URI}${reservationConstants.RESERVATION}/search`;

      const reservationsReq = yield call(createRequest, reservationsUri, {method: 'POST', body: JSON.stringify(data)});

      const response = yield call(fetch, reservationsReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({type: reservationConstants.REQUEST_A_RESERVATION_SUCCESS, reservation: jsonResponse});
   } catch (error) {
      yield put({type: reservationConstants.REQUEST_A_RESERVATION_ERROR});
   }
}

function* updateReservationSaga({data}) {
   try {
      yield put({type: reservationConstants.REQUEST_UPDATE_RESERVATION});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${reservationConstants.RESERVATION_URI}/${data._id}`;
      const req = createRequestWithToken(url, {method: 'PATCH', body: JSON.stringify(data)})(user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: reservationConstants.UPDATE_RESERVATION_SUCCESS,
         reservation: jsonResponse,
      });

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Reservation successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({type: reservationConstants.UPDATE_RESERVATION_ERROR, error: errorMessage});
      yield put(
         appActions.setSnackBar({
            message: errorMessage ? errorMessage?.message : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* filterReservation({data}) {
   yield put({type: reservationConstants.REQUEST_FILTER_RESERVATIONS});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      let reservationUri = `${reservationConstants.RESERVATION_URI}/filter`;
      if (data?.page) {
         reservationUri = `${reservationUri}?page=${data.page + 1}`;
      }
      const reservationReq = createRequestWithToken(reservationUri, {method: 'POST', body: JSON.stringify(data)})(
         user?.accessToken,
      );

      const response = yield call(fetch, reservationReq);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({type: reservationConstants.REQUEST_FILTER_RESERVATIONS_SUCCESS_WITHOUT_DATA});

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: reservationConstants.REQUEST_FILTER_RESERVATIONS_SUCCESS, reservation: jsonResponse});
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({type: reservationConstants.REQUEST_FILTER_RESERVATIONS_ERROR, error: errorMessage});
   }
}

function* searchReservation({data}) {
   yield put({type: reservationConstants.REQUEST_SEARCH_RESERVATION});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const reservationUri = `${reservationConstants.RESERVATION_URI}/search?query=${data.searchInput}`;

      const reservationReq = createRequestWithToken(reservationUri, {method: 'GET'})(user?.accessToken);

      const response = yield call(fetch, reservationReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({type: reservationConstants.SEARCH_RESERVATION_SUCCESS_WITHOUT_DATA});

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: reservationConstants.SEARCH_RESERVATION_SUCCESS, reservation: jsonResponse});
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({type: reservationConstants.SEARCH_RESERVATION_ERROR, error: errorMessage});
   }
}

function* deleteReservationSaga({_id}) {
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      yield put({type: reservationConstants.REQUEST_DELETE_RESERVATION});

      const url = `${reservationConstants.RESERVATION_URI}/${_id}`;
      const req = createRequestWithToken(url, {method: 'DELETE', body: JSON.stringify({_id: _id})})(user && user.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: reservationConstants.DELETE_RESERVATION_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Reservation successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({type: reservationConstants.DELETE_RESERVATION_ERROR, error: errorMessage});
      yield put(appActions.setSnackBar({message: 'Something went wrong', variant: 'error'}));
   }
}

function* getAllReservationsWatcher() {
   yield takeLatest(reservationConstants.GET_ALL_RESERVATIONS, getAllReservations);
}

function* getAReservationWatcher() {
   yield takeLatest(reservationConstants.GET_A_RESERVATION, getAReservation);
}

function* updateReservationWatcher() {
   yield takeLatest(reservationConstants.UPDATE_RESERVATION, updateReservationSaga);
}

function* filterReservationWatcher() {
   yield takeLatest(reservationConstants.FILTER_RESERVATIONS, filterReservation);
}

function* searchReservationWatcher() {
   yield takeLatest(reservationConstants.SEARCH_RESERVATION, searchReservation);
}

function* deleteReservationWatcher() {
   yield takeLatest(reservationConstants.DELETE_RESERVATION, deleteReservationSaga);
}

export default function* rootSaga() {
   yield all([
      getAllReservationsWatcher(),
      getAReservationWatcher(),
      updateReservationWatcher(),
      filterReservationWatcher(),
      searchReservationWatcher(),
      deleteReservationWatcher(),
   ]);
}
