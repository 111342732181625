import {userConstants} from '../_constants';

const login = (model) => ({
   type: userConstants.LOGIN,
   data: model,
});

const logout = () => ({
   type: userConstants.LOGOUT,
});

const verifyEmail = (model) => ({
   type: userConstants.VERIFY_EMAIL,
   data: model,
});

const setPassword = (model) => ({
   type: userConstants.SET_PASSWORD,
   data: model,
});

const changePassword = (model) => ({
   type: userConstants.CHANGE_PASSWORD,
   data: model,
});

// const getUser = (model) => ({
//    type: userConstants.GET_USER,
//    data: model,
// });

const getAllUsers = (model) => ({
   type: userConstants.GET_ALL_USERS,
   data: model,
});

const exportUsers = (model) => ({
   type: userConstants.EXPORT_USERS,
   data: model,
});

const fetchAllUsers = (model) => ({
   type: userConstants.FETCH_ALL_USERS,
   data: model,
});

const getUsers = (model) => ({
   type: userConstants.GET_USERS,
   data: model,
});

const createUser = (model) => ({
   type: userConstants.REGISTER_USER,
   data: model,
});

const updateUser = (model) => ({
   type: userConstants.UPDATE_USER,
   data: model,
});

const updateUserStatus = (model) => ({
   type: userConstants.UPDATE_USER_STATUS,
   data: model,
});

const deleteUser = (_id) => ({
   type: userConstants.DELETE_USER,
   _id,
});

const searchUser = (model) => ({
   type: userConstants.SEARCH_USER,
   data: model,
});

export const userActions = {
   login,
   logout,
   verifyEmail,
   setPassword,
   changePassword,
   // getUser,
   getAllUsers,
   exportUsers,
   fetchAllUsers,
   getUsers,
   createUser,
   updateUser,
   updateUserStatus,
   deleteUser,
   searchUser,
};
