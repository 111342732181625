import React, {useCallback, useRef} from 'react';
import {Link} from 'react-router-dom';
import {routes} from '../../_constants';
import classNames from 'classnames';
import {numToString, useLocationCode} from '../../_helpers';
import './index.scss';

import logo from '../../_assets/images/LTHLogo.png';
import {ReactComponent as SignOutIcon} from '../../_assets/icons/signOut.svg';
import ModalCenter, {useModalCenter} from '../../_controllers/ModalCenter';
import {useMediaQuery} from 'react-responsive';
import useOnClickOutside from '../../_components/onClickOutside';
import {FaTimes} from 'react-icons/fa';

const Sidebar = ({logout}) => {
   const sideRef = useRef();
   const code = useLocationCode();
   const {modalOpen} = useModalCenter();
   const view1090 = useMediaQuery({query: '(max-width: 1090px)'});

   const activeRoute = useCallback(
      (num) => {
         return code === num;
      },
      [code],
   );

   useOnClickOutside(sideRef, () => {
      if (view1090 && modalOpen) ModalCenter.closeModal();
   });

   return code !== 11 ? (
      <div
         ref={sideRef}
         className={classNames('sidebar', {
            hidden: view1090 && !modalOpen,
            'position-fixed': view1090 && modalOpen,
         })}>
         <div className="content d-flex flex-column">
            {view1090 && <FaTimes id="sidebarIcon" onClick={ModalCenter.closeModal} />}
            <div className="logo my-4 mx-auto">
               <Link to="/" onClick={ModalCenter.closeModal} className="d-flex align-items-center">
                  <img src={logo} alt="logo-artwork" className="" />
                  <span className="ms-2">LAGOS TEA HOUSE</span>
               </Link>
            </div>
            <ul className="routes py-4 ps-4 ">
               {routes.map((item) => (
                  <Link to={item.to} key={`routes-${item.id}`} className="">
                     <li
                        onClick={ModalCenter.closeModal}
                        className={classNames('text-capitalize d-flex align-items-center', {
                           active: activeRoute(item.activeCode),
                        })}>
                        <div
                           className={classNames('icon__wrapper me-3', numToString(item.id, true), {
                              active: activeRoute(item.activeCode),
                           })}>
                           {item.icon}
                        </div>
                        {item.label}
                     </li>
                  </Link>
               ))}
            </ul>
            <button className="signOut ms-4 me-auto" onClick={logout}>
               Sign Out <SignOutIcon className="icon ms-1" style={{transform: 'scale(.7)'}} />
            </button>
         </div>
      </div>
   ) : null;
};

export default Sidebar;
