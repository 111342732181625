import {appConstants, userConstants} from '../../_constants';
import {combineReducers} from 'redux';
import {updateObject, updateItemInArray} from '../../_helpers';

const IsRequestingUser = (state = false, action) => {
   switch (action.type) {
      case userConstants.GET_REQUEST:
         return true;
      case userConstants.GET_FAILURE:
         return false;
      case userConstants.GET_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingUsers = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_USERS:
         return true;
      case userConstants.REQUEST_USERS_SUCCESS:
         return false;
      case userConstants.REQUEST_USERS_ERROR:
         return false;
      default:
         return state;
   }
};

const IsRegisteringUser = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_REGISTER_USER:
         return true;
      case userConstants.REGISTER_USER_SUCCESS:
         return false;
      case userConstants.REGISTER_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const userCreated = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_REGISTER_USER:
         return false;
      case userConstants.REGISTER_USER_SUCCESS:
         return true;
      case userConstants.REGISTER_USER_ERROR:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const IsUpdatingUser = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_UPDATE_USER:
         return true;
      case userConstants.UPDATE_USER_SUCCESS:
         return false;
      case userConstants.UPDATE_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const userUpdated = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_UPDATE_USER:
         return false;
      case userConstants.UPDATE_USER_SUCCESS:
         return true;
      case userConstants.UPDATE_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const IsUpdatingUserStatus = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_UPDATE_USER_STATUS:
         return true;
      case userConstants.UPDATE_USER_STATUS_SUCCESS:
         return false;
      case userConstants.UPDATE_USER_STATUS_ERROR:
         return false;
      default:
         return state;
   }
};

const userStatusUpdated = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_UPDATE_USER_STATUS:
         return false;
      case userConstants.UPDATE_USER_STATUS_SUCCESS:
         return true;
      case userConstants.UPDATE_USER_STATUS_ERROR:
         return false;
      default:
         return state;
   }
};

const IsDeletingUser = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_DELETE_USER:
         return true;
      case userConstants.DELETE_USER_SUCCESS:
         return false;
      case userConstants.DELETE_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const IsAuthenticated = (state = false, action) => {
   switch (action.type) {
      case userConstants.LOGOUT_SUCCESS:
         return false;
      case userConstants.LOGIN_SUCCESS:
         return true;
      default:
         return state;
   }
};

const userDetails = (state = null, {type, user, verifiedUser}) => {
   switch (type) {
      case userConstants.GET_SUCCESS:
         return updateObject(state, user.user);
      // case userConstants.VERIFY_EMAIL_SUCCESS:
      //    return updateObject(state, verifiedUser.data);
      case userConstants.LOGIN_SUCCESS:
         return updateObject(state, user.user);
      // case forgotPasswordConstants.RESET_PASSWORD_SUCCESS:
      //    return updateObject(state, user.user);
      case userConstants.UPDATE_USER_SUCCESS:
         return updateObject(state, user.data);
      // case userConstants.UPDATE_USER_SUCCESS: {
      //    const updatedUser = {
      //       ...state,
      //       user: user.data,
      //    };
      //    return updateObject(state, updatedUser);
      // }
      case userConstants.LOGOUT_SUCCESS:
         return null;
      case userConstants.TOKEN_HAS_EXPIRED:
         return null;
      default:
         return state;
   }
};

const newUser = (state = null, {type, user}) => {
   switch (type) {
      case userConstants.REGISTER_USER_SUCCESS:
         return updateObject(state, user);
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

// const updatedChild = (state = null, {type, user}) => {
//    switch (type) {
//       case userConstants.UPDATE_USER_SUCCESS:
//          return updateObject(state, user);
//       default:
//          return state;
//    }
// };

const exportedUsers = (state = [], action) => {
   switch (action.type) {
      case userConstants.REQUEST_EXPORT_ALL_USERS_SUCCESS: {
         return action.userData.data;
      }
      default:
         return state;
   }
};

// const allUsersList = (state = [], action) => {
//    switch (action.type) {
//       case userConstants.REQUEST_ALL_USERS_SUCCESS:
//          return action.allUsers.data.docs;
//       case userConstants.REQUEST_ALL_USERS_SUCCESS:
//          return action.allUsers.data.docs;
//       case userConstants.REGISTER_USER_SUCCESS: {
//          const newList = [...state, action.user.user];

//          return newList;
//       }
//       case userConstants.UPDATE_USER_SUCCESS: {
//          const newList = updateItemInArray(
//             state,
//             action.user._id,
//             true,
//             (User) => {
//                return updateObject(User, action.user);
//             },
//          );

//          return newList;
//       }
//       default:
//          return state;
//    }
// };

const usersList = (state = [], action) => {
   switch (action.type) {
      case userConstants.REQUEST_USERS_SUCCESS:
         return action.adminUsers.data.docs;
      case userConstants.REGISTER_USER_SUCCESS: {
         const newList = [...state, action.user.data];

         return newList;
      }
      // case userConstants.SEARCH_USER_SUCCESS:
      //    return action.User.data.docs;
      // case userConstants.SEARCH_USER_SUCCESS_WITHOUT_DATA:
      //    return updateObject(state, emptyState);
      case userConstants.UPDATE_USER_STATUS_SUCCESS: {
         const newList = updateItemInArray(state, action.user._id, true, (user) => {
            return updateObject(user, action.user);
         });

         return newList;
      }
      case userConstants.DELETE_USER_SUCCESS: {
         return state.filter((user) => user._id !== action._id);
      }
      default:
         return state;
   }
};

const IsSearchingUser = (state = false, action) => {
   switch (action.type) {
      case userConstants.REQUEST_SEARCH_USER:
         return true;
      case userConstants.SEARCH_USER_SUCCESS:
         return false;
      case userConstants.SEARCH_USER_ERROR:
         return false;
      case userConstants.SEARCH_USER_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case userConstants.REQUEST_USERS_SUCCESS: {
         const {totalDocs, limit, page, totalPages} = action.adminUsers.data;
         const result = {totalDocs, limit, page, totalPages};

         return updateObject(state, result);
      }
      case userConstants.SEARCH_USER_SUCCESS: {
         const {totalDocs, limit, page} = action.User.data;
         const result = {totalDocs, limit, page};

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case userConstants.UPDATE_USER_ERROR:
         return updateObject(state, error.errors);
      case userConstants.UPDATE_USER_STATUS_ERROR:
         return updateObject(state, error);
      case userConstants.RESET_PASSWORD_FAILURE:
         return updateObject(state, error);
      case userConstants.REGISTER_USER_ERROR:
         return updateObject(state, error);
      case userConstants.REQUEST_REGISTER_USER:
         return null;
      case userConstants.REQUEST_UPDATE_USER:
         return null;
      case userConstants.REQUEST_UPDATE_USER_STATUS:
         return null;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const createErrorMessage = (state = [], {type, error}) => {
   switch (type) {
      case userConstants.REGISTER_USER_ERROR:
         return updateObject(state, error);
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingUser,
   userDetails,
   newUser,
   // newUserPassword,
   IsAuthenticated,
   //allUsersList,
   exportedUsers,
   // updatedChild,
   usersList,
   IsSearchingUser,
   IsRequestingUsers,
   // IsResettingPassword,
   pagination,
   // passwordChangeSuccess,
   IsRegisteringUser,
   userCreated,
   errorMessage,
   IsUpdatingUser,
   IsUpdatingUserStatus,
   IsDeletingUser,
   userUpdated,
   userStatusUpdated,
   createErrorMessage,
});

export default rootReducer;
