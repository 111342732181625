import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import Formsy from 'formsy-react';
import {TextInput} from '../../../_components';
import {userActions} from '../../../_actions';

// import logo from '../../../_assets/images/LTHLogo.png';
// import profilePhoto from '../../../_assets/images/profilePhoto.png';

const Profile = ({tab}) => {
   const dispatch = useDispatch();
   const {userDetails, IsUpdatingUser} = useSelector((s) => s.user);
   const [canSubmit, setCanSubmit] = useState(false);

   const handleSubmit = (data) => {
      data['role'] = userDetails?.role;
      data['is_active'] = userDetails?.is_active;
      data['_id'] = userDetails?._id;

      dispatch(userActions.updateUser(data));
   };

   return (
      <div className={classNames('profile px-4 flex-column', {'d-flex': tab === 1, 'd-none': tab !== 1})}>
         <p className="info">Personal Information</p>
         {/* <div className="profilePic my-4 d-flex align-items-center">
            <div className="img me-2">
               <img src={profilePhoto} alt="" className="h-100 w-100" />
            </div>
            <div className="">
               <button className="uploadBtn">Upload photo</button>
               <p className="imgSize mt-2 small">
                  Image size not more than 5MB <br /> JPG or PNG
               </p>
            </div>
         </div> */}
         <Formsy
            className="mt-4"
            onValidSubmit={handleSubmit}
            onValid={() => setCanSubmit(true)}
            onInvalid={() => setCanSubmit(false)}>
            <div className="mb-4 row m-0 d-flex align-items-enter justify-content-between">
               <TextInput
                  id="first_name"
                  type="text"
                  label="First Name"
                  name="firstname"
                  className="mb-md-2 mb-4 col-md-6 col-12"
                  inputClassName="dateTime"
                  value={userDetails?.firstname}
               />
               <TextInput
                  id="last_name"
                  type="text"
                  label="Last Name"
                  name="lastname"
                  className="mb-2 col-md-6 col-12"
                  inputClassName="dateTime"
                  value={userDetails?.lastname}
               />
            </div>
            <div className="mb-4 row m-0 d-flex align-items-enter justify-content-between">
               <TextInput
                  type="text"
                  id="email"
                  name="email"
                  label="Email Address"
                  className="mb-2 col-md-6 col-12"
                  inputClassName="dateTime"
                  value={userDetails?.email}
                  disabled
               />
               {/* <TextInput
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  label="Mobile Number"
                  className="mb-2 col-md-6 col-12"
                  inputClassName="dateTime"
                  placeholder=""
                  value="+234 810 738 8028"
               /> */}
            </div>
            <button className="submit" type="submit" disabled={!canSubmit}>
               {IsUpdatingUser ? <CircularProgress color="inherit" thickness={1} /> : 'Save'}
            </button>
         </Formsy>

         {/* <hr className="my-5" />

         <p className="info">Restaurant Information</p>
         <div className="profilePic my-4 d-flex align-items-center">
            <div className="img me-2">
               <img src={logo} alt="" className="" style={{transform: 'scale(.7)'}} />
            </div>
            <div className="">
               <button className="uploadBtn">Upload photo</button>
               <p className="imgSize mt-2 small">
                  Image size not more than 5MB <br /> JPG or PNG
               </p>
            </div>
         </div>
         <Formsy className="mt-4">
            <div className="mb-4 row m-0 d-flex align-items-enter justify-content-between">
               <TextInput
                  id="restaurant_name"
                  type="text"
                  label="Restaurant Name"
                  name="restaurant_name"
                  className="mb-2 col-md-6 col-12"
                  inputClassName="dateTime"
                  placeholder=""
                  value="Lagos Tea House"
               />
               <TextInput
                  type="text"
                  id="email"
                  name="email"
                  label="Email Address"
                  className="mb-2 col-md-6 col-12"
                  inputClassName="dateTime"
                  placeholder=""
                  value="lagosteahouse@gmail.com"
               />
            </div>
            <div className="mb-4 row m-0 d-flex align-items-enter justify-content-between">
               <TextInput
                  type="text"
                  id="website"
                  name="website"
                  label="Website"
                  className="mb-2 col-md-6 col-12"
                  inputClassName="dateTime"
                  placeholder=""
                  value="toyinanifowose@gmail.com"
               />
               <TextInput
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  label="Mobile Number"
                  className="mb-2 col-md-6 col-12"
                  inputClassName="dateTime"
                  placeholder=""
                  value="+234 810 738 8028"
               />
            </div>
            <button className="submit">Save</button>
         </Formsy> */}
      </div>
   );
};

export default Profile;
