import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {menuConstants, userConstants} from '../../_constants';
import {
   createRequest,
   getObjectFromStorage,
   clearObjectFromStorage,
   checkStatus,
   createRequestWithToken,
   createMultiPartRequestWithToken,
} from '../../_helpers';

function* getAllMenus({data}) {
   yield put({type: menuConstants.REQUEST_ALL_MENUS});
   try {
      let menuUri = `${menuConstants.MENU_URI}?paginate=true`;
      if (data?.page) {
         menuUri = `${menuUri}&page=${data.page + 1}`;
      }
      const req = yield call(createRequest, menuUri, {method: 'GET'});
      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: menuConstants.REQUEST_ALL_MENUS_SUCCESS, menus: jsonResponse});
   } catch (error) {
      yield put({type: menuConstants.REQUEST_ALL_MENUS_ERROR});
   }
}

function* getAllMenusWithoutPagination() {
   yield put({type: menuConstants.REQUEST_ALL_MENUS_WITHOUT_PAGINATION});
   try {
      const menuUri = `${menuConstants.MENU_URI}`;

      const req = yield call(createRequest, menuUri, {method: 'GET'});
      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: menuConstants.REQUEST_ALL_MENUS_WITHOUT_PAGINATION_SUCCESS, allMenus: jsonResponse});
   } catch (error) {
      yield put({type: menuConstants.REQUEST_ALL_MENUS_WITHOUT_PAGINATION_ERROR});
   }
}

function* getAMenu({data}) {
   yield put({type: menuConstants.REQUEST_A_MENU});

   try {
      let menuUri = `${menuConstants.MENU_URI}/${data._id}`;

      const menuReq = yield call(createRequest, menuUri, {method: 'GET'});

      const response = yield call(fetch, menuReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({type: menuConstants.REQUEST_A_MENU_SUCCESS, menu: jsonResponse});
   } catch (error) {
      yield put({type: menuConstants.REQUEST_A_MENU_ERROR});
   }
}

function* getCategoryMenus({data}) {
   yield put({type: menuConstants.REQUEST_CATEGORY_MENUS});
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);
      const categoryMenuUri = `${menuConstants.MENU_URI}/category/${data._id}`;
      const req = createRequestWithToken(categoryMenuUri, {method: 'GET'})(user.accessToken);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: menuConstants.REQUEST_CATEGORY_MENUS_SUCCESS,
         categoryMenus: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({type: menuConstants.REQUEST_CATEGORY_MENUS_ERROR});
   }
}

function* createMenuSaga({data}) {
   try {
      yield put({type: menuConstants.REQUEST_CREATE_MENU});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${menuConstants.MENU_URI}`;
      const curriedReq = yield call(createMultiPartRequestWithToken, url, {
         method: 'POST',
         body: data,
      });
      const req = yield call(curriedReq, user.accessToken);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: menuConstants.CREATE_MENU_SUCCESS, menu: jsonResponse});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Menu successfully posted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({type: menuConstants.CREATE_MENU_ERROR, error: errorMessage});
      yield put(appActions.setSnackBar({message: 'Something went wrong', variant: 'error'}));
   }
}

function* updateMenuSaga({data}) {
   try {
      yield put({type: menuConstants.REQUEST_UPDATE_MENU});
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      const url = `${menuConstants.MENU_URI}/${data._id}`;
      // const req = createRequestWithToken(url, {method: 'PATCH', body: JSON.stringify(data)})(user?.accessToken);
      const curriedReq = yield call(createMultiPartRequestWithToken, url, {
         method: 'PATCH',
         body: data.fields,
      });
      const req = yield call(curriedReq, user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));
      yield put({type: menuConstants.UPDATE_MENU_SUCCESS, menu: jsonResponse?.data});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Menu successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({type: menuConstants.UPDATE_MENU_ERROR, error: errorMessage});
      yield put(appActions.setSnackBar({message: 'Something went wrong', variant: 'error'}));
   }
}

function* searchMenu({data}) {
   yield put({type: menuConstants.REQUEST_SEARCH_MENU});

   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      let menuUri = `${menuConstants.MENU_URI}/advanced_search`;

      const menuReq = createRequestWithToken(menuUri, {method: 'POST', body: JSON.stringify(data)})(user?.token);

      const response = yield call(fetch, menuReq);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({type: menuConstants.SEARCH_MENU_SUCCESS_WITHOUT_DATA});

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: menuConstants.SEARCH_MENU_SUCCESS, menu: jsonResponse});
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({type: menuConstants.SEARCH_MENU_ERROR, error: errorMessage});
   }
}

function* deleteMenuSaga({_id}) {
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      yield put({type: menuConstants.REQUEST_DELETE_MENU});

      const url = `${menuConstants.MENU_URI}/${_id}`;
      const req = createRequestWithToken(url, {method: 'DELETE'})(user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: menuConstants.DELETE_MENU_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Menu successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }

      yield put({type: menuConstants.DELETE_MENU_ERROR, error: errorMessage});
      yield put(appActions.setSnackBar({message: 'Something went wrong', variant: 'error'}));
   }
}

function* deleteMenuCommentSaga({data}) {
   try {
      const user = yield call(getObjectFromStorage, userConstants.ADMIN_USER_KEY);

      yield put({type: menuConstants.REQUEST_DELETE_MENU_COMMENT});

      const url = `${menuConstants.MENU_URI}/${data.mealId}/comment/${data._id}`;
      const req = createRequestWithToken(url, {method: 'PATCH'})(user?.accessToken);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({type: menuConstants.DELETE_MENU_COMMENT_SUCCESS, menu: jsonResponse});

      yield put(
         appActions.setSnackBar({
            message: jsonResponse.message ? jsonResponse.message : 'Menu comment successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      if (errorMessage?.message === 'invalid bearer token') {
         // console.log('response', response)
         yield call(clearObjectFromStorage, userConstants.ADMIN_USER_KEY);

         yield put({type: userConstants.LOGOUT_SUCCESS});
         return;
      }
      yield put({type: menuConstants.DELETE_MENU_COMMENT_ERROR, error: errorMessage});
      yield put(appActions.setSnackBar({message: 'Something went wrong', variant: 'error'}));
   }
}

function* getAllMenusWatcher() {
   yield takeLatest(menuConstants.GET_ALL_MENUS, getAllMenus);
}

function* getAllMenusWithoutPaginationWatcher() {
   yield takeLatest(menuConstants.GET_ALL_MENUS_WITHOUT_PAGINATION, getAllMenusWithoutPagination);
}
function* getAMenuWatcher() {
   yield takeLatest(menuConstants.GET_A_MENU, getAMenu);
}

function* getCategoryMenusWatcher() {
   yield takeLatest(menuConstants.GET_CATEGORY_MENUS, getCategoryMenus);
}

function* createMenuWatcher() {
   yield takeLatest(menuConstants.CREATE_MENU, createMenuSaga);
}

function* updateMenuWatcher() {
   yield takeLatest(menuConstants.UPDATE_MENU, updateMenuSaga);
}

function* searchMenuWatcher() {
   yield takeLatest(menuConstants.SEARCH_MENU, searchMenu);
}

function* deleteMenuWatcher() {
   yield takeLatest(menuConstants.DELETE_MENU, deleteMenuSaga);
}

function* deleteMenuCommentWatcher() {
   yield takeLatest(menuConstants.DELETE_MENU_COMMENT, deleteMenuCommentSaga);
}

export default function* rootSaga() {
   yield all([
      getAllMenusWatcher(),
      getAllMenusWithoutPaginationWatcher(),
      getAMenuWatcher(),
      getCategoryMenusWatcher(),
      createMenuWatcher(),
      updateMenuWatcher(),
      searchMenuWatcher(),
      deleteMenuWatcher(),
      deleteMenuCommentWatcher(),
   ]);
}
