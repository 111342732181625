import {tagConstants} from '../_constants';

const getAllTags = (model) => ({
   type: tagConstants.GET_ALL_TAGS,
   data: model,
});

const searchTag = (model) => ({
   type: tagConstants.SEARCH_TAG,
   data: model,
});

const createTag = (model) => ({
   type: tagConstants.CREATE_TAG,
   data: model,
});

const updateTag = (model) => ({
   type: tagConstants.UPDATE_TAG,
   data: model,
});

const deleteTag = (_id) => ({
   type: tagConstants.DELETE_TAG,
   _id,
});

export const tagActions = {
   getAllTags,
   searchTag,
   createTag,
   updateTag,
   deleteTag,
};
